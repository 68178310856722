import React, { useState, ChangeEvent, useEffect } from "react";

//------------------css import -------------------
import "../../styles/alliance-manager/licenseRequestDetails.css";
//--------------------bootstrap------------------
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
//--------------------api handler------------------
import AllianceManagerServices from "../../services/API/AllianceManager";

//------------------Third Party library-----------------------
import { ToastContainer, toast, useToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { htmlToText } from "html-to-text";
import validator from "validator";
import { Button, Modal } from "react-bootstrap";

// import DatePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";

import {
  BtnBold,
  BtnItalic,
  createButton,
  EditorProvider,
} from "react-simple-wysiwyg";
import Editor from "react-simple-wysiwyg";
import moment from "moment";

//------------------Components-----------------------
import AdminLayout from "../../components/layouts/adminLayout";
import { Link, useParams } from "react-router-dom";
import SrnActivityLogs from "../../components/AllianceManager/LicenseRequestDetails/SrnActivityLogs";
import DocumentList from "../../components/AllianceManager/LicenseRequestDetails/DocumentList";
import UpdateStatus from "../../components/AllianceManager/LicenseRequestDetails/UpdateStatus";
import CourierDetails from "../../components/AllianceManager/LicenseRequestDetails/CourierDetails";
import PaymentDetails from "../../components/AllianceManager/LicenseRequestDetails/PaymentDetails";
import VendorLayout from "../../components/layouts/VendorLayout";
import useAuth from "../../hooks/useAuth";

const VendorLicenseRequestDetails = () => {
  let { license_id } = useParams();
  const auth = useAuth();
  const initialLicenseStatus = {
    srn_id: license_id,
    status: "",
    sub_status: "",
    renewal_date: null,
    is_valid: 0,
    comment: "",
    document: "",
    documentType: "",
  };
  const initialCourierDetails = {
    srn_id: license_id,
    courier_name: "",
    consignment_number: "",
    comment: "",
    document: "",
    documentType:""
  };
  const initialPaymentDetails = {
    srn_id: license_id,
    amount: "",
    payment_note: "",
    document: "",
    documentType:""

  };
  const [key, setKey] = useState(1);
  const [documentStatus, setDocumentStatus] = useState({
    certificateOfIncorporate: true,
    PAN: true,
    articlesOfAssociation: true,
    memorandum: true,
  });

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [details, setDetails] = useState();
  const [vendorList, setVendorList] = useState([]);
  const [ExecutiveList, setExecutiveList] = useState([]);
  const [FieldExecutiveList, setFieldExecutiveList] = useState([]);
  const [srnStatues, setSrnStatues] = useState({ status: "", sub_status: "" });
  const [srnActivity, setSrnActivity] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);

  const [licenseStatus, setLicenseStatus] = useState(initialLicenseStatus);
  const [flag, setFlag] = useState(false);
  const [licenseStatusErrors, setLicenseStatusErrors] = useState({});
  const [courierDetails, setCourierDetails] = useState(initialCourierDetails);
  const [courierDetailsErrors, setCourierDetailsErrors] = useState({});

  const [paymentDetails, setPaymentDetails] = useState(initialPaymentDetails);
  const [paymentDetailsErrors, setPaymentDetailsErrors] = useState({});

  // const [getFormData, setGetFormData] = useState({});

  useEffect(() => {
    licenseRequestDetails();
  }, []);

  const licenseRequestDetails = async () => {
    setInitialLoading(true);
    try {
      const results = await Promise.all([
        AllianceManagerServices.licenseRequestDetails(license_id),
        AllianceManagerServices.srnActivityLogs(license_id),
        AllianceManagerServices.getVendorList(),
        AllianceManagerServices.getExecutiveList(),
        AllianceManagerServices.getFieldExecutiveList(),
      ]);

      if (results[0].status === "success") setDetails(results[0].data[0]);
      if (results[1].status === "success") {
        setSrnActivity(results[1].data);
      }
      if (results[2].status === "success") setVendorList(results[2].data);
      if (results[3].status === "Success")
        setExecutiveList(
          results[3]?.data?.data?.filter((item) => item.is_enabled === 1)
        );

      if (results[4].status === "Success") {
        const obj = {};
        results[4]?.data?.data.forEach((item) => {
          if (item.is_enabled == 1) {
            if (!obj[item.executive_name]) {
              obj[item.executive_name] = [
                {
                  executive_id: item.executive_id,
                  executive_name: item.executive_name,
                  field_executive_name: item.name,
                  field_executive_id: item.id,
                },
              ];
            } else {
              obj[item.executive_name] = [...obj[item.executive_name]];
              obj[item.executive_name].push({
                executive_id: item.executive_id,
                executive_name: item.executive_name,
                field_executive_name: item.name,
                field_executive_id: item.id,
              });
            }
          }
        });
        // setFieldExecutiveList(results[4]?.data?.data?.filter((item)=>item.is_enabled === 1));
        setFieldExecutiveList(obj);
      }

      setInitialLoading(false);
      // const statusListResponse = await AllianceManagerServices.getStatusList()
      // const subStatusListResponse = await AllianceManagerServices.getSubStatusList()
      // if(statusList.status === "success")setStatusList()
      // if(subStatusList.status === "success")setSubStatusList()
    } catch (error) {
      toast.error("No data found.");
      setInitialLoading(false);
    }
  };
  const updateStatus = async () => {
    const fieldsError = validateLicenseStatusFields(licenseStatus);
    if (Object.keys(fieldsError).length > 0) {
      setLicenseStatusErrors(fieldsError);
    } else {
      setLoading(true);
      setLicenseStatusErrors({});
      const formData = new FormData();
      formData.append("document", licenseStatus.document);
      if (
        licenseStatus?.is_valid !== 1 &&
        (licenseStatus?.renewal_date ||
          details?.get_srn_statustes?.renewal_date)
      ) {
        formData.append(
          "renewal_date",
          moment(
            details?.get_srn_statustes?.renewal_date ||
              licenseStatus.renewal_date
          ).format("YYYY-MM-DD HH:mm:ss")
        );
      }
      for (let i in licenseStatus) {
        if (i !== "document" && i !== "renewal_date")
          formData.append(i, licenseStatus[i]);
      }

      try {
        const res = await AllianceManagerServices.updateSrnStatus(formData);
        if (res?.status === "success") {
          setLicenseStatus(initialLicenseStatus);
          setFlag(false);
          setLoading(false);
          toast.success("Status Updated.");
          licenseRequestDetails();
          const srnActivityResponse =
            await AllianceManagerServices.srnActivityLogs(license_id);
          if (srnActivityResponse?.status === "success") {
            setSrnActivity(srnActivityResponse.data);
          }
        }
        setLoading(false);
      } catch (e) {
        toast.error(e.message);
        setLoading(false);
      }
    }
  };
  const validateLicenseStatusFields = (values) => {
    const errors = {};
    if (!values.status) {
      errors.status = "Status Required!";
    }
    // if (!values.sub_status) {
    //   errors.sub_status = "Sub-Status Required!";
    // }
    // if (!values.renewal_date && values.is_valid !== 1) {
    //   errors.renewal_date = "Renewal Date Required!";
    // }

    if (
      !values.renewal_date &&
      (values?.status === "Closed" || values?.status === "Completed") &&
      values.is_valid !== 1 &&
      !details?.get_srn_statustes?.renewal_date
    ) {
      errors.renewal_date = "Renewal Date Required!";
    }

    if (
      (!values.document && licenseStatus?.status === "Closed") ||
      (!values.document && licenseStatus?.status === "Completed")
    ) {
      errors.document = "Document Required!";
    }

    if (values.renewal_date) {
      let date = moment(values.renewal_date)
        .format("YYYY-MM-DD HH:mm:ss")
        .split(" ")[0];
      let time = moment(values.renewal_date)
        .format("YYYY-MM-DD HH:mm:ss")
        .split(" ")[1];
      if (
        validator.isDate(date) &&
        date <= moment(new Date()).format("YYYY-MM-DD")
      ) {
        errors.renewal_date =
          "Renewal Date and Time Should Be Valid, Please Select It From Calendar";
      } else {
        if (time === "date") {
          errors.renewal_date =
            "Renewal Date and Time Should Be Valid, Please Select It From Calendar";
        }
      }
    }
    if (values.comment) {
      // errors.comment = "Comment Required!";
      let toCheckCommentLength = htmlToText(values.comment, {
        wordwrap: 130,
      });
      if (toCheckCommentLength.length >= 500) {
        errors.comment = "Comment should be less than 500 character.";
      }
    }

    // if (!values.document) {
    //   errors.document = "Document Required!";
    // }

    return errors;
  };
  const saveCourierDetails = async () => {
    const fieldsError = validateCourierDetailsFields(courierDetails);
    if (Object.keys(fieldsError).length > 0) {
      setCourierDetailsErrors(fieldsError);
    } else {
      setLoading(true);

      setCourierDetailsErrors({});
      const formData = new FormData();
      formData.append("document", courierDetails.document);
      for (let i in courierDetails) {
        if (i !== "document" && i !== "documentType") formData.append(i, courierDetails[i]);
      }
    
      try {
        const res = await AllianceManagerServices.updateSrnCourier(formData);

        if (res?.status === "success") {
          setCourierDetails(initialCourierDetails);
          toast.success("Courier details saved successfully.");
          const srnActivityResponse =
            await AllianceManagerServices.srnActivityLogs(license_id);
          if (srnActivityResponse?.status === "success") {
            setSrnActivity(srnActivityResponse.data);
          }
        } else {
          toast.error(res.message);
        }
        setLoading(false);
      } catch (e) {
        toast.error(e.message);
        setLoading(false);
      }
    }
  };
  const validateCourierDetailsFields = (values) => {
    const errors = {};
    if (!values.courier_name) {
      errors.courier_name = "Courier Name Required!";
    } else {
      if (values.courier_name.length >= 500) {
        errors.comment = "Courier name should be less than 500 character.";
      }
    }

    if (!values.consignment_number) {
      errors.consignment_number = "Consignment Number Required!";
    } else {
      if (toString(values.consignment_number).length >= 500) {
        errors.comment =
          "Consignment number should be less than 500 character.";
      }
    }
    if (!values.comment) {
      errors.comment = "Comment Required!";
    }

    if (!values.document) {
      errors.document = "Document Required!";
    }

    if (!["jpeg","jpg","pdf","png"].includes(values?.documentType.toLowerCase())) {
      setCourierDetails({...courierDetails,document:""})
      errors.document = "The document must be a file of type jpeg, jpg, pdf, png";
      
    }


    return errors;
  };

  const savePaymentDetails = async () => {
    const fieldsError = validatePaymentDetailsFields(paymentDetails);
    if (Object.keys(fieldsError).length > 0) {
      setPaymentDetailsErrors(fieldsError);
    } else {
      setLoading(true);
      setPaymentDetailsErrors({});
      const formData = new FormData();
      formData.append("document", paymentDetails.document);
      for (let i in paymentDetails) {
        if (i !== "document"  && i !== "documentType") formData.append(i, paymentDetails[i]);
      }

      try {
        const res = await AllianceManagerServices.updateSrnPaymentDetails(
          formData
        );
        if (res.status === "success") {
          setPaymentDetails(initialPaymentDetails);
          toast.success("Payment details saved successfully.");
          const srnActivityResponse =
            await AllianceManagerServices.srnActivityLogs(license_id);
          if (srnActivityResponse?.status === "success") {
            setSrnActivity(srnActivityResponse.data);
          }
        }
        setLoading(false);
      } catch (e) {
        toast.error(e.message);
        setLoading(false);
      }
    }
  };
  const validatePaymentDetailsFields = (values) => {
    const errors = {};

    if (!values.amount) {
      errors.amount = "Amount Required!";
    }
    if (values.amount && values.amount.toString().length > 10) {
      errors.amount = "Amount should be less than 10 digit.";
    }

    if (values.payment_note) {
      if (values.payment_note.length >= 500) {
        errors.comment = "Note should be less than 500 character.";
      }
    }

    if (!values.document) {
      errors.document = "Document Required!";
    }
    if (!["jpeg","jpg","pdf","png"].includes(values?.documentType.toLowerCase())) {
      setPaymentDetails({...paymentDetails,document:""})
      errors.document = "The document must be a file of type jpeg, jpg, pdf, png";
      
    }
    return errors;
  };

  const reassignToVendor = async (id, role, name) => {
    setLoading(true);
    try {
      const response = await AllianceManagerServices.srnReassignToExecutive({
        license_id,
        vendorId: id,
        user_role_id: role,
      });
      if (response?.status === "success") {
        const details = await AllianceManagerServices.licenseRequestDetails(
          license_id
        );
        setDetails(details.data[0]);
        toast.success(`Reassigned to ${name} Successfully`);
      }
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <VendorLayout>
      {/* <!-- MAIN PANEL --> */}
      <div id="main" role="main">
        {/* <!-- MAIN CONTENT --> */}
        <div id="content">
          <div>
            <ol className="breadcrumb margin-left-5">
              <li>
                <a href="/">Home</a>
              </li>
              &nbsp;&nbsp;<span>/</span>&nbsp;&nbsp;
              <li>
                <Link to={"/vendor/overall-license-requests"}>
                  License Requests
                </Link>
              </li>
              &nbsp;&nbsp;<span>/</span>&nbsp;&nbsp;
              <li> Details</li>
            </ol>
          </div>

          <div className="row">
            {/* <td dangerouslySetInnerHTML={{__html: html}} /> */}

            <div>
              <div className="card card-sm card-shadow mb-3">
                {loading && (
                  <div
                    style={{
                      paddingLeft: "0px",
                      width: "100%",
                      height: "100%",
                      background: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      zIndex: "999",
                    }}
                  >
                    <div className="text-center" style={{ position: "fixed" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                )}
                {initialLoading ? (
                  <div
                    style={{
                      paddingLeft: "0px",
                      width: "100%",
                      height: "100%",
                      background: "red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      zIndex: "999",
                    }}
                  >
                    <div className="text-center" style={{ position: "fixed" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-body">
                    {/* <ToastContainer /> */}

                    <span
                      className="pull-right mb-3"
                      style={{ marginRight: "5px" }}
                    >
                      <div className="dropdown mt-4">
                        <button
                          // className="button_new "
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            border: "1px solid #fec02c",
                            color: "#270640",
                            borderRadius: "10px",
                            background: "white",
                            fontSize: "12px",
                            padding: "2px 10px",
                          }}
                        >
                          Assign to Executives&nbsp;&nbsp;
                          <i
                            className="fa-solid fa-sort-down"
                            style={{ fontSize: "15px", marginBottom: "5px" }}
                          ></i>
                        </button>
                        <ul
                          className="dropdown-menu button_new dropdown-lisst"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {ExecutiveList.length > 0 &&
                            ExecutiveList?.map((executive, key) => (
                              <li
                                className="dropdown-item"
                                key={key}
                                onClick={() =>
                                  reassignToVendor(
                                    executive.id,
                                    2,
                                    executive?.name
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {executive.name}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </span>
                    <span
                      className="pull-right mb-3"
                      style={{ marginRight: "5px" }}
                    >
                      <div className="dropdown mt-4">
                        <button
                          // className="button_new "
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            border: "1px solid #fec02c",
                            color: "#270640",
                            borderRadius: "10px",
                            background: "white",
                            fontSize: "12px",
                            padding: "2px 10px",
                          }}
                        >
                          Assign to Field Executives&nbsp;&nbsp;
                          <i
                            className="fa-solid fa-sort-down"
                            style={{ fontSize: "15px", marginBottom: "4px" }}
                          ></i>
                        </button>
                        {/* <select  name="cars" id="cars" className="dropdown-menu button_new dropdown-lisst"
                          aria-labelledby="dropdownMenuButton1">
  <optgroup label="Swedish Cars" className="dropdown-item">
    <option value="volvo">Volvo</option>
    <option value="saab">Saab</option>
  </optgroup>
  <optgroup label="German Cars" className="dropdown-item">
    <option value="mercedes">Mercedes</option>
    <option value="audi">Audi</option>
  </optgroup>
</select> */}
                        <ul
                          className="dropdown-menu button_new dropdown-lisst"
                          aria-labelledby="dropdownMenuButton1"
                          style={{ width: "100%" }}
                        >
                          {Object.keys(FieldExecutiveList).length > 0 &&
                            Object.keys(FieldExecutiveList)?.map(
                              (item, key) => (
                                <div>
                                  <h5 key={key}>{item}</h5>
                                  {FieldExecutiveList[item].map(
                                    (fieldExec, fieldExecKey) => (
                                      <div
                                        className="dropdown-item"
                                        key={fieldExecKey}
                                        onClick={() =>
                                          reassignToVendor(
                                            fieldExec.field_executive_id,
                                            3
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          whiteSpace: "wrap",
                                          // fontSize: ".875rem"
                                        }}
                                      >
                                        {fieldExec.field_executive_name}
                                      </div>
                                    )
                                  )}
                                </div>
                              )
                            )}
                        </ul>
                      </div>
                    </span>
                    <span
                      className="pull-right mb-3"
                      style={{ marginRight: "5px" }}
                    >
                      <div className="dropdown mt-4">
                        <button
                          // className="btn margin-top-10 "
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            border: "1px solid #fec02c",
                            color: "#270640",
                            borderRadius: "10px",
                            background: "white",
                            fontSize: "12px",
                            padding: "2px 10px",
                          }}
                        >
                          Request for Reassignment&nbsp;&nbsp;
                          <i
                            className="fa-solid fa-sort-down"
                            style={{ fontSize: "15px", marginBottom: "5px" }}
                          ></i>
                        </button>
                      </div>
                    </span>

                    <h3>License Request Details</h3>
                    <div className="mb-3">
                      <span className=" font-md srn_btn ">
                        Service Request Number (SRN):
                        <span style={{ color: "#fec02c" }}>
                          <strong>{details?.srn_name || "--"}</strong>
                        </span>
                      </span>
                    </div>

                    <div className="row">
                      <article className="col-lg-6  mb-3">
                        <div className="card card-sm card-shadow mb	-3">
                          <div className="card-body">
                            {details?.srn_fasttrack === "1" ? (
                              <i
                                className="fa fa-circle fa-fw"
                                aria-hidden="true"
                                style={{ color: "red" }}
                                title="Online"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-circle fa-fw"
                                aria-hidden="true"
                                style={{ color: "#61b9ff" }}
                                title="Online"
                              ></i>
                            )}

                            {details?.get_license?.name || "--"}
                            <div className="padding-top-10">
                              {details?.get_license_type?.name || "--"} |{" "}
                              {/*S&ERenew |*/} {details?.get_city?.name || "--"}{" "}
                              | {details?.get_state?.name || "--"}
                            </div>
                            <div className=" padding-top-10">
                              <span className="note">Requested On: </span>
                              {details?.created_at
                                ? moment(details.created_at).format("DD-MM-YY")
                                : "--"}
                            </div>
                            <div className=" padding-top-10">
                              <span className="note">Current Status: </span>
                              <span style={{ color: "#429fe9" }}>
                                {details?.get_srn_statustes?.status || "--"}
                              </span>
                            </div>
                            <div className=" padding-top-10">
                              <span className="note">Assigned To: </span>
                              <i
                                className="fa fa-envelope-square font-md"
                                style={{ color: "#fec02c" }}
                                title="View Email"
                              ></i>{" "}
                              {"  "}
                              <i
                                className="fa fa-phone-square font-md"
                                style={{ color: "#fec02c" }}
                                title="View Phone Number"
                              ></i>
                              <span
                                style={{ color: "#000", paddingLeft: "5px" }}
                              >
                                {!details?.get_field_executive &&
                                  !details?.get_executive &&
                                  "--"}
                                {details?.get_executive?.name}{" "}
                                {details?.get_executive?.name &&
                                  details?.get_field_executive?.name &&
                                  ","}{" "}
                                {details?.get_field_executive?.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </article>
                      <article className="col-lg-6  mb-3">
                        <div className="card card-sm card-shadow">
                          <div className="card-body">
                            <i
                              className="fa fa-building fa-fw"
                              aria-hidden="true"
                              style={{ color: "#64308e" }}
                              title="Company Name"
                            ></i>
                            {details?.company_name || "--"}
                            <div className="padding-top-10">
                              <i
                                className="fa fa-map-marker fa-fw"
                                aria-hidden="true"
                                style={{ color: "#64308e" }}
                                title="Company Address"
                              ></i>{" "}
                              {details?.company_address || "--"}
                            </div>
                            <div className=" padding-top-10">
                              <i
                                className="fa fa-user fa-fw"
                                aria-hidden="true"
                                style={{ color: "#64308e" }}
                                title="Requested By"
                              ></i>
                              <span className="note">Requested By: </span>

                              {details?.get_user?.name || "--"}
                            </div>
                            <div className=" padding-top-10">
                              <i
                                className="fa fa-envelope fa-fw"
                                aria-hidden="true"
                                style={{ color: "#64308e" }}
                                title="Email"
                              ></i>
                              <span className="note">Email: </span>
                              {details?.get_user?.email || "--"}
                            </div>
                            <div className=" padding-top-10">
                              <i
                                className="fa fa-phone fa-fw"
                                aria-hidden="true"
                                style={{ color: "#64308e" }}
                                title="Mobile Number"
                              ></i>
                              <span className="note">Contact: </span>
                              {details?.get_user?.mobile_no || "--"}
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>

                    <DocumentList
                      documentStatus={documentStatus}
                      setDocumentStatus={setDocumentStatus}
                    />

                    <div className="">
                      <div className="card card-sm card-shadow">
                        <div className="card-body">
                          <h3 className="heading_light">
                            <i className="fa-regular fa-comments"></i>&nbsp;
                            Activity Logs
                          </h3>
                          <div className="row">
                            {srnActivity?.length > 0 ? (
                              <SrnActivityLogs srnActivity={srnActivity} />
                            ) : (
                              <article
                                className="col-lg-6  mb-3"
                                style={{
                                  maxHeight: "520px",
                                  overflowY: "scroll",
                                }}
                              >
                                <div className="card card-sm card-shadow mb	-3">
                                  <div
                                    className="card-body"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <text>Currently no logs available...</text>
                                  </div>
                                </div>
                              </article>
                            )}

                            <article className="col-lg-6  mb-3">
                              <div className="card card-sm card-shadow">
                                <div className="card-body smart-form">
                                  <ul
                                    id="myTab1"
                                    className="nav nav-tabs bordered"
                                  >
                                    <li
                                      className={key == 1 ? "active" : ""}
                                      style={{
                                        width: "33.3%",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setKey(1)}
                                    >
                                      <a data-toggle="tab">
                                        <i className="fa fa-fw fa-lg fa-gear"></i>{" "}
                                        Update Status
                                      </a>
                                    </li>
                                    <li
                                      className={key == 2 ? "active" : ""}
                                      style={{
                                        width: "33.3%",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setKey(2)}
                                    >
                                      <a data-toggle="tab">
                                        <i className="fa fa-fw fa-lg fa-envelope"></i>{" "}
                                        Courier Details
                                      </a>
                                    </li>
                                    <li
                                      className={key == 3 ? "active" : ""}
                                      style={{
                                        width: "33.4%",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setKey(3)}
                                    >
                                      <a data-toggle="tab">
                                        <i className="fa fa-fw fa-lg fa-database"></i>{" "}
                                        Payment Details
                                      </a>
                                    </li>
                                  </ul>
                                  <div
                                    id="myTabContent1"
                                    className="tab-content padding-10"
                                  >
                                    {key == 1 ? (
                                      <UpdateStatus
                                        licenseStatus={licenseStatus}
                                        setLicenseStatus={setLicenseStatus}
                                        licenseStatusErrors={
                                          licenseStatusErrors
                                        }
                                        srnStatues={srnStatues}
                                        updateStatus={updateStatus}
                                        setFlag={setFlag}
                                        flag={flag}
                                        details={details}
                                        setCourierDetails={setCourierDetails}
                                        setPaymentDetails={setPaymentDetails}
                                        setCourierDetailsErrors={setCourierDetailsErrors}
                                        setPaymentDetailsErrors={setPaymentDetailsErrors}
                                        initialCourierDetails={initialCourierDetails}
                                        initialPaymentDetails={initialPaymentDetails}
                                      />
                                    ) : key == 2 ? (
                                      <CourierDetails
                                        courierDetails={courierDetails}
                                        setCourierDetails={setCourierDetails}
                                        courierDetailsErrors={
                                          courierDetailsErrors
                                        }
                                        saveCourierDetails={saveCourierDetails}
                                        setPaymentDetails={setPaymentDetails}
                                        setPaymentDetailsErrors={setPaymentDetailsErrors}
                                        initialPaymentDetails={initialPaymentDetails}
                                        initialLicenseStatus={initialLicenseStatus}
                                        setLicenseStatus={setLicenseStatus}
                                        setLicenseStatusErrors={setLicenseStatusErrors}
                                      />
                                    ) : (
                                      <PaymentDetails
                                        paymentDetails={paymentDetails}
                                        setPaymentDetails={setPaymentDetails}
                                        paymentDetailsErrors={
                                          paymentDetailsErrors
                                        }
                                        savePaymentDetails={savePaymentDetails}
                                        initialLicenseStatus={initialLicenseStatus}
                                      setLicenseStatus={setLicenseStatus}
                                      setLicenseStatusErrors={setLicenseStatusErrors}
                                      setCourierDetails={setCourierDetails}
                                      setCourierDetailsErrors={setCourierDetailsErrors}
                                      initialCourierDetails={initialCourierDetails}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </VendorLayout>
  );
};

export default VendorLicenseRequestDetails;

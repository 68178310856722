import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { LocationSettingsContext } from "..";
import LocationSettingsServices from "../../../../services/API/LocationSettings";
import { toast } from "react-toastify";
import { validateZone } from "../locationTabs/ZonesRegions";
import { useEffect } from "react";
export default function AddZone({ refetch, editData, setEdit, clearFilter,searchkey,handleSearch}) {
  const { show, setShow } = useContext(LocationSettingsContext);
  const [name, setName] = useState(null);

  useEffect(() => {
    if (editData) {
      setName(editData?.name);
    }
  }, [editData]);

  const addZone = async () => {
    const payload = { zone_name: name };
    const error = validateZone(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LocationSettingsServices.storeZone(payload);
      if (response) {
        clearFilter();
        handleHide();
        toast.success("Zone created successfully");
        refetch();
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const updateZone = async () => {
    const payload = { zone_name: name };
    const error = validateZone(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LocationSettingsServices.updateZone(
        editData?.id,
        payload
      );
      if (response) {
        toast.success("Zone updated successfully");
        // clearFilter();
        if(searchkey){
          handleSearch({target:{value:searchkey}},2)
        }else{
          refetch();
        }
        setEdit(null);
        handleHide();
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const handleSubmit = () => {
    if (editData) {
      updateZone();
    } else {
      addZone();
    }
  };
  const handleHide = () => {
    setShow(null);
    setName(null);
    setEdit(null);
  };
  return (
    <Modal show={show === "zone"} animation={false} onHide={handleHide}>
      <Modal.Header closeButton>
        <h3 className="modal-title">{editData ? " Edit Zone" : "Add Zone"}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group padding-top-10">
          <label htmlFor="tags">
            {" "}
            Zone Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Zone Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={handleSubmit}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

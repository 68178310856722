import moment from "moment";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import DatePicker from "react-datepicker";

export default function FilterExecutiveOOO({
  filterStatus,
  setFilterStatus,
  filterData,
  setFilterData,
  handleChange,
  handleFilter,
}) {
  const handleHide = () => {
    setFilterStatus("search");
  };

  return (
    <Offcanvas
      show={filterStatus === "filter"}
      onHide={handleHide}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {" "}
          <h3>Filter </h3>{" "}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modall_body">
        <div className="container-fluid filter-box pt-3 ">
          <div className="smart-form">
            <section>
              <label className="label">Name</label>
              <label className="input">
                <input
                  type="text"
                  name="name"
                  value={filterData?.name}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter Name"
                />
              </label>
            </section>
            <section>
              <label className="label">Out of Office From</label>
              <DatePicker
                // minDate={moment().toDate()}
                selected={filterData?.start_date}
                onChange={(e) =>
                  setFilterData({ ...filterData, start_date: e })
                }
                type="date"
                value={filterData?.start_date}
                dateFormat="dd-MM-yyyy"
                className="py-2 px-2"
                placeholderText="Select start date"
              />
            </section>

            <section>
              <label className="label">Out of Office Till</label>
              <DatePicker
                // minDate={moment().toDate()}
                selected={filterData?.end_date}
                onChange={(e) => setFilterData({ ...filterData, end_date: e })}
                type="date"
                value={filterData?.end_date}
                dateFormat="dd-MM-yyyy"
                className="py-2 px-2"
                placeholderText="Select end date"
              />
            </section>
          </div>
        </div>
        <section className="pull-right m-4" onClick={handleFilter}>
          <a className="btn button_new_success p-3">Apply</a>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

import React,{useState} from "react";
import { Offcanvas } from "react-bootstrap";
import format from "date-fns/format";
import DateRangeComponent from "../../../components/AllianceManager/LicenseRequestDetails/DateRangeComponent";

export default function FilterExecutive({
  filterStatus,
  setFilterStatus,
  filterData,
  setFilterData,
  handleChange,
  handleFilter,
  showDuedateRange,
  setShowDuedateRange,
}) {
  const [openDueDateCalendar, setOpenDueDateCalendar] = useState(false);

  const handleHide = () => {
    setFilterStatus("search");
  };

  return (
    <Offcanvas
      show={filterStatus === "filter"}
      onHide={handleHide}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h3>Filter </h3>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body 
       className="modall_body scrollBody"
      >
        <div className=" filter-box pt-3  filterScroll"  >
         <div className="smart-form container-fluid">
          <section className="my-2">
            <label className="label">Name</label>
            <label className="input">
              <input
                type="text"
                name="name"
                value={filterData?.name}
                onChange={handleChange}
                className="form-control"
                placeholder="Enter Name"
              />
            </label>
          </section>
          <section className="my-2">
            <label className="label">Mobile Number</label>
            <label className="input">
              <input
                type="text"
                className="form-control"
                maxLength={10}
                name="mobile_no"
                value={filterData?.mobile_no}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  setFilterData((prevState) => {
                    return {
                      ...prevState,
                      mobile_no: value,
                    };
                  });
                }}
                placeholder="Enter Mobile number"
              />
            </label>
          </section>
          <section className="my-2">
            <label className="label">Email ID</label>
            <label className="input">
              <input
                type="text"
                className="form-control"
                name="email"
                placeholder="Enter Email"
                value={filterData?.email}
                onChange={handleChange}
              />
            </label>
          </section>
          <section className="my-2">
          <label className="label">Created On</label>
          <input
           value={showDuedateRange ? `${format(
            filterData.dueDateRange[0].startDate,
            "dd-MM-yy"
          )} to ${format(filterData.dueDateRange[0].endDate, "dd-MM-yy")}` : ""}
          readOnly
          placeholder="Select Created On Date Range"
          onClick={() => setOpenDueDateCalendar((open) => !open)}         
          style={{
            width: "97%",
            height: "33px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            outline: "none",
            paddingLeft:'10px'
          }}
          />
        {openDueDateCalendar && (
           <DateRangeComponent
           setOpenCalendar={setOpenDueDateCalendar}
           openCalendar={openDueDateCalendar}
           isDueDateComponent={true}
           toBeFiltered={filterData}
           setToBeFiltered={setFilterData}
           setShowDuedateRange={setShowDuedateRange}
         />
        )}
          </section>  
          <section className="my-2">
            <label className=""></label>
            <label className="toggle label">
              <input
                type="checkbox"
                name="checkbox-toggle"
                checked={filterData?.is_enabled}
                onChange={() =>
                  setFilterData({
                    ...filterData,
                    is_enabled: !filterData.is_enabled,
                  })
                }
                // value={isValidLifeTime}
              />
              <i
                data-swchon-text="YES"
                data-swchoff-text="NO"
                // style={{ right: "unset" }}
              ></i>
              Is Active
            </label>
          </section> 
           
          </div>        
        </div>
        <section className="pull-right m-4" onClick={handleFilter}>
            <a className="btn button_new_success p-3">Apply</a>
          </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

import React from "react";
import { HeadProvider, Link } from "react-head";
import AdminHeader from "../../Headers/AdminHeader";
import ExecutiveSideMenu from "../../SideMenus/ExecutiveSideMenu";
import AdminLayout from "../adminLayout";

const ExecutiveLayout = ({ children, sideMenu = true }) => {
  return <AdminLayout sideMenu={sideMenu}>{children}</AdminLayout>;
};

export default ExecutiveLayout;

import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OOOchart from "./OOOchart";
import moment from "moment";
import VendorExecutiveServices from "../../services/API/VendorExecutive";
import { useEffect } from "react";

export default function Enlarged({ navigation }) {
  const data1 = JSON.parse(localStorage.getItem("graphData"));
  const [graphData, setGraphData] = useState([]);
  const [today, setToday] = useState(new Date());
  const [currentData, setCurrentData] = useState([]);
  const navigate = useNavigate();
  const handleGraph = async (year) => {
    const payload = { id: data1, date: year };
    const response = await VendorExecutiveServices.graphOOO(payload);
    if (response) {
      setGraphData(response?.data);
    }
    return response?.data;
  };

  const monthNames = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];
  const ChangeData = async (day) => {
    let f_year;
    if (moment(day).year() === moment(today).year()) {
      if (moment(today).format("MM") <= 3) {
        f_year = moment(today).subtract(1, "years").format("YYYY");
      } else {
        f_year = moment(day).format("YYYY");
      }
    } else {
      f_year = moment(day).format("YYYY");
    }
    const filtered_data = await handleGraph(f_year);
    const newData = monthNames.map((month, index) => {
      const existingMonth = filtered_data?.find(
        (item) => item?.month === month
      );
      if (existingMonth) {
        return existingMonth;
      } else {
        return {
          leave_count: 0,
          month: month,
          numeric_month: index + 1,
        };
      }
    });
    setCurrentData(newData);
  };

  useEffect(() => {
    if (data1) {
      ChangeData(today);
    }
  }, [data1]);

  const scrollerGraph = () => {
    const day = moment(today).subtract(1, "years");
    ChangeData(day);
    setToday(day);
  };
  const scrollerGraph1 = () => {
    const day = moment(today).add(1, "years");
    ChangeData(day);
    setToday(day);
  };

  return (
    <div id="main" role="main">
      {/* <h3 className="container-fluid pt-4">Out of Office Trend</h3> */}
      <h4 className="d-flex justify-content-center">
        FY-{moment(today).year()}-{moment(today).add(1, "years").format("YY")}
      </h4>
      <div id="content" className="mt-5">
        <OOOchart
          height={500}
          data={currentData}
          scrollerGraph={scrollerGraph}
          scrollerGraph1={scrollerGraph1}
        />
        <div>
          <Button
            variant="primary"
            className="float-end"
            onClick={() => {
              localStorage.removeItem("graphData");
              navigate(navigation);
            }}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { LocationSettingsContext } from "..";
import { useQuery } from "react-query";
import LocationSettingsServices from "../../../../services/API/LocationSettings";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import AddZone from "../Modals/AddZone";

export const spl_format = /^[A-Za-z0-9\s]*$/;

export const validateZone = (data) => {
  if (!data.zone_name) return "Please enter zone name";
  if (data.zone_name.length < 3 || data.zone_name.length > 500)
    return "Please enter valid input with 3-500 characters";
  if (!spl_format.test(data.zone_name))
    return "Please enter only alphabets and numeric characters in this field";
};
export default function ZonesRegions() {
  const value = useContext(LocationSettingsContext);
  const queryKey = "api/get_zone";
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [page, setPage] = useState(1);
  const [del, setDel] = useState(false);
  const [myData, setMyData] = useState([]);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const postsPerPage = 20;
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const {
    data: zoneLists,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery([queryKey], LocationSettingsServices.getZone, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : zoneLists?.data?.slice(indexOfFirstPost, indexOfLastPost);
  useEffect(() => {
    if (zoneLists?.data) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setMyData(zoneLists?.data);
    }
  }, [zoneLists]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);
  const handleDelete = async (zone) => {
    try {
      const status = zone.status === "Active" ? "Inactive" : "Active";
      await LocationSettingsServices.deactivateZone(zone?.id, {
        status: status,
      });
      const message =
        zone?.status === "Active"
          ? "Zone Deactivated Successfully"
          : "Zone Activated Successfully";
      toast.success(message);
      setDel(null);
      if(searchkey){
        handleSearch({target:{value:searchkey}},page)
      }else{
        refetch();
      }
    } catch (er) {
      toast.error(er);
    }
  };
  const handleEdit = async (zone) => {
    try {
      const zoneData = await LocationSettingsServices.editZone(zone?.id);
      // setName(zoneData?.data?.zone_name);
      setId(zone?.id);
      setEdit(zoneData?.data);
      value.setShow("zone");
     
    } catch (er) {
      toast.error(er);
    }
  };

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setMyData(zoneLists?.data);
    setPage(1)
  };
  const handleSearch = async (e,page) => {
    const value = e.target.value;
    setSearchkey(value);

    const { data } = await LocationSettingsServices.searchZone(value);
    if(!page){
      setPage(1);
    }
    setMyData(data);
  };

  return (
    <div style={{ borderTop: "0px", paddingTop: "30px" }}>
      <div
        style={{
          paddingTop: "30px",
        }}
        className="d-flex justify-content-end align-items-center gap-2 p-2"
      >
        {searchkey && (
          <button
            type="button"
            className="button_new pull-right"
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        )}
        {displaySearchField ? (
          <input
            type="text"
            className="button_new pull-right "
            value={searchkey}
            onChange={handleSearch}
            onBlur={() => (!searchkey ? setDisplaySearchField(false) : null)}
            // disabled={workflow === "expert"}
            placeholder="Search"
          />
        ) : (
          <a
            className=" button_new pull-right"
            onClick={() => {
              setDisplaySearchField(true);
            }}
          >
            <i
              class="fa fa-search requests-icons"
              aria-hidden="true"
              title="Search"
            ></i>
          </a>
        )}
        <a
          className=" button_new pull-right"
          onClick={() => value.setShow("zone")}
        >
          <i
            className="fa fa-plus fa-fw requests-icons"
            title="Add Zone/Region"
          ></i>
        </a>
      </div>

      <div className="table-responsive ">
        <table
          className="table table-bordered table-hover dataTable no-footer"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr role="row" style={{ fontWeight: 700 }}>
              <th className="text-center" width="34px">
                S. No.
              </th>
              <th className="text-center">Zone</th>
              {/* <th className="text-center">Status</th> */}
              <th className="text-center" width="50px">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentLists?.length !== 0 ? (
              currentLists?.map((zone, index) => (
                <tr key={zone?.id}>
                  <td className="text-start align-middle">
                    {postsPerPage * (page - 1) + index + 1}
                  </td>
                  <td className="text-start align-middle">{zone.zone_name}</td>
                  {/* {zone?.status === "Active" ? (
                    <td className="text-start align-middle">
                      <i className="fa fa-circle fa-fw txt-success"></i>Active
                    </td>
                  ) : (
                    <td className="text-start align-middle">
                      <i className="fa fa-circle fa-fw txt-fail"></i>Inactive
                    </td>
                  )} */}
                  <td className="text-start align-middle">
                    <a title="Edit" onClick={() => handleEdit(zone)}>
                      <i className="fa fa-edit fa-fw"></i>
                    </a>
                    {zone?.status === "Active" ? (
                      <i
                        className="fa fa-circle fa-fw txt-success pe-auto"
                        style={{
                          cursor: "pointer",
                        }}
                        title="Deactivate"
                        onClick={() => setDel(zone)}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-circle fa-fw txt-fail"
                        title="Activate"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => setDel(zone)}
                      ></i>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="p-2 text-center">
                  {loading || isFetching ? "Loading..." : "No records found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {myData.length > postsPerPage && (
          <Pagination
            activePage={page}
            itemsCountPerPage={postsPerPage}
            totalItemsCount={myData.length}
            pageRangeDisplayed={postsPerPage}
            onChange={handlePageChange}
          />
        )}
      </div>

      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to{" "}
            {del?.status === "Active" ? "deactivate " : "activate "}
            this zone?
          </p>
          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <AddZone
        refetch={refetch}
        editData={edit}
        setEdit={setEdit}
        clearFilter={clearFilter}
        searchkey={searchkey}
        handleSearch={handleSearch}
      />
    </div>
  );
}

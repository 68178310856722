import React, { useState } from "react";
import VendorExecutiveServices from "../../services/API/VendorExecutive";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";
export default function ViewExecutiveDetails({ exec_id }) {
  const [executive, setExecutive] = useState({});
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const getExecutiveById = async () => {
    setLoading(true);
    try {
      const response = await VendorExecutiveServices.getExecutiveById(exec_id);
      if (response) {
        setExecutive(response?.data[0]);
      }
    } catch (e) {
    }

    setLoading(false);
  };
  useEffect(() => {
    getExecutiveById();
  }, []);
  return (
    <div className="row">
      <div className="card card-sm card-shadow mb-3">
        {!loading ? (
          <div className="card-body">
            <h2>View Executive Details</h2>
            <a className=" button_new pull-right">Show License Details</a>
            <div className="row mt-3">
              <article className="col-lg-6  mb-3">
                <div className="card card-sm card-shadow mb	-3">
                  <div className="card-body">
                    <span
                      className="font-sm badge pull-right bg-color-blue"
                      title="User Id"
                    >
                      #{executive?.id}
                    </span>
                    <span className="font-xl ">{executive?.name}</span>
                    <br />
                    <div className="padding-top-15 d-flex align-items-center">
                      <i
                        className="fa fa-map-marker fa-fw"
                        aria-hidden="true"
                        style={{ color: "#64308e" }}
                        title="Executive Address"
                      ></i>
                      {/* {executive?.cities?.length ? (
                        <>
                          {executive?.address} | &nbsp;
                          {executive?.cities[0]?.city_name} | &nbsp;
                          {executive?.states[0]?.state_name}
                        </>
                      ) : (
                        "--"
                      )} */}
                      <span style={{ width: "100%" }}>
                        {executive?.address ? <>{executive?.address}</> : ""}
                      </span>
                    </div>
                    <div className=" padding-top-15">
                      <i
                        className="fa fa-envelope fa-fw requests-icons"
                        aria-hidden="true"
                        style={{ color: "#64308e" }}
                        title="Email Id"
                      ></i>
                      <span className="note">Email Id: </span>
                      {executive?.email}
                    </div>
                    <div className=" padding-top-15">
                      <i
                        className="fa fa-phone fa-fw requests-icons"
                        aria-hidden="true"
                        style={{ color: "#64308e" }}
                        title="Mobile Number"
                      ></i>
                      <span className="note">Mobile Number: </span>
                      {executive?.mobile_no}
                    </div>

                    <div className=" padding-top-15">
                      {executive?.is_enabled ? (
                        <>
                          <i className="fa fa-circle fa-fw txt-success"></i>
                          <span className="note">Status: </span>Active{" "}
                        </>
                      ) : (
                        <>
                          <i className="fa fa-circle fa-fw txt-fail"></i>
                          <span className="note">Status: </span>Inactive
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </article>
            </div>
            <div className="mt-3">
              <div className="card card-sm card-shadow mb	-3">
                <div className="card-body">
                  <h3>Regions Operated & Areas of Expertise</h3>
                  <div
                    className="d-flex justify-content-between flex-wrap mt-5 gap-3"
                    style={{ listStyle: "none" }}
                  >
                    <section>
                      <h4>Regions</h4>
                      {executive?.zones?.length
                        ? executive?.zones.map((zone) => (
                            <li
                              title={
                                zone?.zone_name.length > 15 && zone?.zone_name
                              }
                            >
                              {zone?.zone_name.length > 15
                                ? zone?.zone_name.substring(0, 15).concat("...")
                                : zone?.zone_name}
                            </li>
                          ))
                        : ""}
                    </section>

                    <section>
                      <h4>States</h4>
                      {executive?.states?.length
                        ? executive?.states.map((state) => (
                            <li
                              title={
                                state?.state_name.length > 15 &&
                                state?.state_name
                              }
                            >
                              {state.state_name.length > 15
                                ? state?.state_name
                                    .substring(0, 15)
                                    .concat("...")
                                : state?.state_name}
                            </li>
                          ))
                        : ""}
                    </section>
                    <section>
                      <h4>Cities</h4>
                      {executive?.cities?.length
                        ? executive?.cities.map((city) => (
                            <li
                              title={
                                city?.city_name.length > 15 && city?.city_name
                              }
                            >
                              {city.city_name.length > 15
                                ? city?.city_name.substring(0, 15).concat("...")
                                : city?.city_name}
                            </li>
                          ))
                        : ""}
                    </section>
                    <section>
                      <h4>Municipal Corporations</h4>
                      {executive?.municipals?.length
                        ? executive?.municipals.map((municipal) => (
                            <li
                              title={
                                municipal?.municipal_name.length > 20 &&
                                municipal?.municipal_name
                              }
                            >
                              {municipal.municipal_name.length > 20
                                ? municipal?.municipal_name
                                    .substring(0, 20)
                                    .concat("...")
                                : municipal?.municipal_name}
                            </li>
                          ))
                        : ""}
                    </section>
                    <section>
                      <h4>Industry Types</h4>
                      {executive?.industry_types?.length
                        ? executive?.industry_types.map((industry) => (
                            <li
                              title={
                                industry?.name.length > 15 && industry?.name
                              }
                            >
                              {industry.name.length > 15
                                ? industry?.name.substring(0, 15).concat("...")
                                : industry?.name}
                            </li>
                          ))
                        : ""}
                    </section>

                    <section>
                      <h4>License Types</h4>
                      {executive?.license_types?.length
                        ? executive?.license_types.map((license) => (
                            <li
                              title={license?.name.length > 15 && license?.name}
                            >
                              {license.name.length > 15
                                ? license?.name.substring(0, 15).concat("...")
                                : license?.name}
                            </li>
                          ))
                        : ""}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>Loading....</p>
        )}
      </div>
    </div>
  );
}

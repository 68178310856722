import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { LocationSettingsContext } from "..";
import LocationSettingsServices from "../../../../services/API/LocationSettings";
import { toast } from "react-toastify";
import { validateState } from "../locationTabs/States";
export default function AddState({ refetch, editData, setEdit, clearFilter,handleSearch,searchkey }) {
  const { show, setShow } = useContext(LocationSettingsContext);
  const [name, setName] = useState(null);
  const [zoneId, setZoneId] = useState("");
  const [zones, setZones] = useState(null);
  const [state_code, setState_code] = useState("");
  const getZones = async () => {
    const { data } = await LocationSettingsServices.getActiveZone();
    setZones(data);
  };
  useEffect(() => {
    getZones();
  }, []);

  useEffect(() => {
    if (editData) {
      setName(editData?.name);
      setZoneId(editData?.zone_id);
      setState_code(editData?.state_code);
    }
  }, [editData]);

  const updateState = async () => {
    const payload = { state_name: name, zone_id: zoneId, state_code };
    const error = validateState(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LocationSettingsServices.updateState(
        editData?.id,
        payload
      );
      if (response) {
        // clearFilter();
        handleHide();
        if(searchkey){
          handleSearch({target:{value:searchkey}},2)
        }else{
          refetch();
        }
        toast.success("State updated successfully");
        setEdit(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const addState = async () => {
    const payload = { state_name: name, zone_id: zoneId, state_code };
    const error = validateState(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const resp = await LocationSettingsServices.storeState(payload);
      if (resp?.status_code === 200) {
        toast.success("State created successfully");
        clearFilter();
        refetch();
        handleHide();
      }
      if (resp?.status_code === 403) {
        toast.error(resp?.message);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const handleSubmit = () => {
    if (editData) {
      updateState();
    } else {
      addState();
    }
  };
  const handleHide = () => {
    setShow(null);
    setName(null);
    setZoneId("");
    setEdit(null);
    setState_code("");
  };
  return (
    <Modal show={show === "state"} animation={false} onHide={handleHide}>
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {editData ? " Edit State" : "Add State"}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group ">
          <label htmlFor="tags">Zone</label>
          <select
            value={zoneId}
            onChange={(e) => setZoneId(e.target.value)}
            className="form-control full-width"
          >
            <option value="">Select Zone</option>
            {zones?.map((zd) => (
              <option key={zd?.id} value={zd?.id}>
                {zd.zone_name}
              </option>
            ))}
          </select>{" "}
          <i></i>{" "}
        </div>
        <div className="form-group padding-top-10">
          <label htmlFor="tags">
            {" "}
            State Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter State Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="form-group padding-top-10">
          <label htmlFor="tags">
            {" "}
            State Code<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            maxLength={2}
            className="form-control"
            placeholder="Enter State Code"
            value={state_code}
            onChange={(e) => {
              const value = e.target.value.replace(/^((?![A-Za-z]).)*$/g, "");
              setState_code(value.toUpperCase());
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={handleSubmit}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

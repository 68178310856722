import React from "react";
import axiosInstance from "../axiosConfig";

class PaymentServices extends React.Component {
  getOrderId = async (data) => {
    return await axiosInstance.post(`/api/user/orderPayment`, data);
  };

  updateTransactionStatus = async (id, data) => {
    return await axiosInstance.post(
      `/api/user/update-payment-status/${id}`,
      data
    );
  };

  updateTransaction = async (id, data) => {
    return await axiosInstance.post(`/api/user/update-payment/${id}`, data);
  };

  updateSRNFastrack = async (id, data) => {
    return await axiosInstance.post(`/api/update-fastrack/${id}`, data);
  };
}

const instance = new PaymentServices();

export default instance;

import React from "react";

const AdminPageCard = ({ children }) => {
  return (
    <div className="card card-sm card-shadow ">
      <div className="card-body">{children}</div>
    </div>
  );
};

export default AdminPageCard;

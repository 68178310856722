export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhone = (phone) => {
  return phone.match(/^[0-9]{10}$/);
};

export const onlyLetter = (string) => {
  return String(string)
    .toLowerCase()
    .match(/^[A-Za-z]+$/);
};

export function get_url_extension(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim();
}

export const AlphaNumWithSpace = (testString) => {
  return String(testString)
    .toLowerCase()
    .match(/^[a-zA-Z0-9 ]*$/);
};
export function validateAlphaNumeric(name) {
  return name.match(/^[a-z0-9\s]+$/i);
}
export function validateName(name) {
  // return name.match(/^[a-z0-9\s]+$/i);
  const spl_format = /^[a-zA-Z./s ]*$/;
  return spl_format.test(name);
}

export function checkPassword(str) {
  var re =
    /^(?=.*\d)(?=.*[*.!@#$%^&(){}/[\]:;<>,.?~_+-=|\])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}

export function truncate(str) {
  if (str.length > 25) return str.slice(0, 25) + "...";
  else return str;
}

export const validateChangePasswordFormData = (
  passwordFormData,
  resetFields
) => {
  const { newPassword, confirmPassword } = passwordFormData;
  if (!newPassword) {
    resetFields();
    return "Please enter your new password";
  }
  if (newPassword.length < 8 || newPassword.length > 16) {
    resetFields();
    return "Please enter valid password with at least 8 characters with one lower case, one upper case, one number and one special character";
  }
  if (!checkPassword(newPassword)) {
    resetFields();
    return "Please enter valid password with at least 8 characters with one lower case, one upper case, one number and one special character";
  }
  if (!confirmPassword) {
    resetFields();
    return "Please re-enter your new password";
  }
  if (newPassword !== confirmPassword) {
    resetFields();
    return "Entered passwords do not match";
  }
};
export const validateAccountFormData = (accountFormData) => {
  const { email, fullName, phone } = accountFormData;
  const spl_format = /^[a-zA-Z./s ]*$/;
  const email_format =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!fullName) return "Please enter your full name";
  if (fullName.length < 3 || fullName.length > 500) {
    return "Please enter valid input with 3-500 characters";
  }
  if (!spl_format.test(fullName))
    return "Special characters and numbers are not allowed in full name";
  if (!email) return "Please enter Email";
  if (email.split("@")[0].length < 3 || email.split("@")[0].length > 500) {
    return "Please enter valid input with 3-500 characters";
  }
  if (!email_format.test(email)) return "Enter valid email";
  if (!phone) return "Please enter valid 10 digit mobile number";
  if (phone.length !== 10) return "Please enter valid 10 digit mobile number";
};


export const getDocName = (ids,allDocs)=>{

  const docNames = []
  ids.forEach((id)=>{
    allDocs.forEach((doc)=>doc.id == id && docNames.push(doc.document.name))
  })
  
  return docNames
}

import React from "react";
import { coyRightText, footerDescription } from "../../../constant/static";
import { Link } from "react-router-dom";
import "./footer.css";

function EndUserFooter() {
  return (
    <div>
      <footer className="apj_footer">
        <div style={{ backgroundColor: "#454747" }}>
          <div className="container py-4">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-3">
                <p className="m-0 text-white fst-normal">{footerDescription}</p>
              </div>

              <div className="col-2">
                <h5 className="text-white">Quick Links</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <Link href="#" className="nav-link p-0">
                      Partner With Us
                    </Link>
                  </li>
                </ul>
              </div>
              {/*
              <div className="col-2">
                <h5>Section</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-muted">
                      Home
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-muted">
                      Features
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-muted">
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-muted">
                      FAQs
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <a href="#" className="nav-link p-0 text-muted">
                      About
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "#EF9900" }}>
          <div className="container d-flex justify-content-between py-3">
            <p className="m-0 text-white">{coyRightText}</p>
            {/* <ul className="list-unstyled d-flex">
              <li className="ms-3">
                <a className="link-dark" href="#"></a>
              </li>
              <li className="ms-3">
                <a className="link-dark" href="#"></a>
              </li>
              <li className="ms-3">
                <a className="link-dark" href="#"></a>
              </li>
            </ul> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default EndUserFooter;

import React from "react";

const AdminPageContainer = ({ children }) => {
  return (
    <div className="py-2 px-1" style={{ backgroundColor: "#f9f9f9" }}>
      {children}
    </div>
  );
};

export default AdminPageContainer;

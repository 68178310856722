import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { LicenseConfigureContext } from "..";
import LicenseConfigurationServices from "../../../../services/API/LicenseConfiguration";
import { toast } from "react-toastify";
import { validateLicense } from "../licenceTabs/Licenses";
export default function AddLicenses({
  refetch,
  editData,
  setEdit,
  clearFilter,
  applyFilter,
  filtersAvailable,
  searchkey,
  handleSearch
}) {
  const { show, setShow } = useContext(LicenseConfigureContext);
  const [name, setName] = useState(null);
  const [short, setShort] = useState(null);
  const [categories, setCategories] = useState([]);
  const [catId, setCatId] = useState(null);
  const [shortlist, setShortlist] = useState("");
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const getCategories = async () => {
      const { data } =
        await LicenseConfigurationServices.getActiveCategoryList();
      setCategories(data);
    };
    getCategories();
  }, []);

  useEffect(() => {
    if (editData) {
      setName(editData?.name);
      setShort(editData?.short_name);
      setCatId(editData?.license_category_id);
      setShortlist(editData?.shortlist);
    }
  }, [editData]);

  const createShortName = async (e) => {
    const { value } = e.target;
    setName(value);
    if (value.length === 0) {
      toast.error("Please enter license");
      clearTimeout(timer);
      setShort("");
      return;
    }

    clearTimeout(timer);

    const newTimer = setTimeout(async () => {
      try {
        const response = await LicenseConfigurationServices.createShortName({
          short_name: value,
          model_name: "License",
        });
        setShort(response?.data);
      } catch (e) {
        toast.error(e.message);
      }
    }, 500);
    setTimer(newTimer);
  };

  const addLicense = async () => {
    const payload = {
      name,
      short_name: short,
      license_category_id: catId,
      shortlist,
    };
    const error = validateLicense(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.addLicense(payload);
      if (response) {
        toast.success("License created successfully");
        clearFilter();
        refetch();
        handleHide();
      }
    } catch (e) {
    }
  };
  const updateLicence = async () => {
    const payload = {
      name,
      short_name: short,
      license_category_id: catId,
      shortlist,
    };
    const error = validateLicense(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.updateLicense(
        editData?.id,
        payload
      );
      if (response) {
        toast.success("License updated successfully");
        // clearFilter();
        handleHide();
        if(filtersAvailable){
          applyFilter(1)
        }else if(searchkey){
          handleSearch({target:{value:searchkey}},1)
        }else{
          refetch();
        }
        setImmediate(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };
  const submitForm = () => {
    if (editData) {
      updateLicence();
    } else {
      addLicense();
    }
  };
  const handleHide = () => {
    setShow(null);
    setName(null);
    setShort(null);
    setCatId(null);
    setShortlist(null);
    setEdit(null);
  };

  return (
    <Modal show={show === "licenses"} animation={false} onHide={handleHide}>
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {editData ? "Edit License" : "Add License"}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            License Name <span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Enter License Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Short Name <span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Enter Short Name"
            value={short}
            maxLength={3}
            onChange={(e) => setShort(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Menu <span style={{ color: "red" }}> *</span>
          </label>

          <select
            className="form-control mb-3"
            id="Shortlist"
            value={catId}
            onChange={(e) => setCatId(e.target.value)}
          >
            <option>Choose Menu</option>
            {categories?.map((ct) => (
              <option key={ct?.id} value={ct?.id}>
                {ct.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Shortlist <span style={{ color: "red" }}> *</span>
          </label>

          <select
            className="form-control"
            id="Shortlist"
            value={shortlist}
            onChange={(e) => setShortlist(e.target.value)}
          >
            <option value="">Select Value</option>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
          <small>
            If marked as YES, the item will display on website home page
          </small>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={submitForm}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

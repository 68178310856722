import React from "react";
import axiosInstance from "../axiosConfig";

class HomeServices extends React.Component {
  getsrnList = async (data) => {
    return await axiosInstance.post(`/api/get_srn_status_byname`, data);
  };

  getCategoryMenuList = async () => {
    return await axiosInstance.get(`/api/license-category`);
  };

  getLicenseClassification = async () => {
    return await axiosInstance.get(`/api/license/license-type`);
  };
}

const instance = new HomeServices();

export default instance;

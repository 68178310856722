import React, { useContext, useState, useEffect } from "react";
import { LocationSettingsContext, spl_format } from "..";
import { useQuery } from "react-query";
import LocationSettingsServices from "../../../../services/API/LocationSettings";
import { toast } from "react-toastify";
import { Modal, Button, Offcanvas } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import AddCity from "../Modals/AddCity";

export const validateCity = (data) => {
  // if (!data.zone_id) return "Please select zone";
  if (!data.state_id) return "please select state";
  if (!data.city_name) return "Please enter city name";
  if (data.city_name.length < 3 || data.city_name.length > 500)
    return "Please enter valid input with 3-500 characters";
  if (!spl_format.test(data.city_name))
    return "Please enter only alphabets in this field";
};
export default function Cities() {
  const value = useContext(LocationSettingsContext);
  const queryKey = "api/get_city";
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(null);
  const [id, setId] = useState(null);
  const [states, setStates] = useState([]);
  const [zones, setZones] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [zoneId, setZoneId] = useState(null);
  const [page, setPage] = useState(1);
  const [del, setDel] = useState(false);
  const [myData, setMyData] = useState([]);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const [filter, setFilter] = useState(false);
  const [exported, setExported] = useState("");
  const postsPerPage = 20;
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const getStates = async () => {
    const response = await LocationSettingsServices.getActiveState();
    if (response) {
      setStates(response?.data);
    }
  };
  const getZones = async () => {
    const response = await LocationSettingsServices.getActiveZone();
    if (response) {
      setZones(response?.data);
    }
  };

  // const res = await LocationSettingsServices.editState(stateId);

  // if (data.filter((e) => e.id === stateId).length > 0) {
  // } else {
  //   setStates([...new Set([res?.data, ...data])]);
  // }

  // const res = await LocationSettingsServices.editZone(zoneId);

  // if (data.filter((e) => e.id === zoneId).length > 0) {
  // } else {
  //   setZones([...new Set([res?.data, ...data])]);
  // }

  const getExported = async () => {
    let response = await LocationSettingsServices.exportCity();
    response?.forEach((element, index) => {
      response[index]["S. No"] = index + 1;
    });
    setExported(response ? response : []);
  };
  useEffect(() => {
    setFilter(false);
    getStates();
    getZones();
  }, []);

  // useEffect(() => {
  //   if (edit === "edit" || edit === "filter") {
  //     getStates();
  //     getZones();
  //   }
  // }, [edit]);

  const {
    data: cityLists,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery([queryKey], LocationSettingsServices.getCity, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : cityLists?.data?.slice(indexOfFirstPost, indexOfLastPost);
  useEffect(() => {
    if (cityLists?.data) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setMyData(cityLists?.data);
    }
  }, [cityLists]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);
  useEffect(() => {
    getExported();
  }, [cityLists]);

  const handleDelete = async (city) => {
    const status = city.status === "Active" ? "Inactive" : "Active";
    try {
      await LocationSettingsServices.deactivateCity(city?.id, {
        status,
      });
      const message =
        city?.status === "Active"
          ? "City Deactivated Successfully"
          : "City Activated Successfully";
      toast.success(message);
      setDel(null);
      if(name || zoneId || stateId){
        applyFilter(page)
      }else if(searchkey){
        handleSearch({target:{value:searchkey}},page)
      }else{
        refetch();
      }
    } catch (er) {
      toast.error(er);
    }
  };
  const handleEdit = async (city) => {
    try {
      const cityData = await LocationSettingsServices.editCity(city?.id);
      value.setShow("city");
      setId(city?.id);
      setEdit(cityData?.data);
    } catch (er) {
      toast.error(er);
    }
  };

  const applyFilter = async (page) => {
    const payload = { city_name: name, zone_id: zoneId, state_id: stateId };
    // const error = validateCity(payload);
    // if (error) {
    //   toast.error(error);
    //   return;
    // }
    const error = Object.values(payload).filter((dt) => {
      if (dt !== null) {
        return dt;
      }
    });
    if (!error.length) {
      toast.error("Please fill out the fields");
      return;
    }
    try {
      const response = await LocationSettingsServices.filterCity(payload);
      if (response) {
        setFilter(false);
        if(!page){
          setPage(1);
        }
        setMyData(response?.data);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setMyData(cityLists?.data);
    setName(null);
    setStateId(null);
    setZoneId(null);
    setPage(1)
  };
  const handleSearch = async (e,page) => {
    const value = e.target.value;
    setSearchkey(value);

    const { data } = await LocationSettingsServices.searchCity(value);
    if(!page){
      setPage(1);
    }
    setMyData(data);
  };

  const handleHide = () => {
    setFilter(false);
  };

  return (
    <div style={{ borderTop: "0px", paddingTop: "30px" }}>
      <div
        style={{
          paddingTop: "30px",
        }}
        className="d-flex justify-content-end align-items-center gap-2 p-2"
      >
        {cityLists?.data.length !== myData?.length && (
          <button
            type="button"
            className="button_new pull-right"
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        )}
        {displaySearchField ? (
          <input
            type="text"
            className="button_new pull-right "
            value={searchkey}
            onChange={handleSearch}
            onBlur={() => (!searchkey ? setDisplaySearchField(false) : null)}
            // disabled={workflow === "expert"}
            placeholder="Search"
          />
        ) : (
          <a
            className=" button_new pull-right"
            onClick={() => {
              setDisplaySearchField(true);
            }}
          >
            <i
              class="fa fa-search requests-icons"
              aria-hidden="true"
              title="Search"
            ></i>
          </a>
        )}
        <button
          className=" button_new pull-right"
          title="Show Filters"
          onClick={() => setFilter(true)}
        >
          <i className="fa fa-filter fa-fw requests-icons"></i>
        </button>
        <CSVLink
          className=" button_new pull-right"
          title="Download"
          filename={"Cities.csv"}
          data={exported}
          onClick={() => {
            if (exported) toast.success("Cities Downloaded Successfully");
          }}
        >
          <i className="fa fa-download fa-fw requests-icons"></i>
        </CSVLink>

        <a
          className=" button_new pull-right"
          onClick={() => value.setShow("city")}
        >
          <i className="fa fa-plus fa-fw requests-icons" title="Add City"></i>
        </a>
      </div>

      <div className="table-responsive ">
        <table
          className="table table-bordered table-hover dataTable no-footer"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr role="row" style={{ fontWeight: 700 }}>
              <th className="text-center" width="34px">
                S. No.
              </th>
              <th className="text-center">City</th>
              <th className="text-center">Zone</th>
              <th className="text-center">State</th>

              {/* <th className="text-center">Status</th> */}
              <th className="text-center" width="50px">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentLists?.length !== 0 ? (
              currentLists?.map((city, index) => (
                <tr key={city?.id}>
                  <td className="text-start align-middle">
                    {postsPerPage * (page - 1) + index + 1}
                  </td>
                  <td className="text-start align-middle">{city?.name}</td>
                  <td className="text-start align-middle">{city?.zone_name}</td>
                  <td className="text-start align-middle">
                    {city?.state_name}
                  </td>
                  {/* {city?.status === "Active" ? (
                    <td className="text-start align-middle">
                      <i className="fa fa-circle fa-fw txt-success"></i>Active
                    </td>
                  ) : (
                    <td className="text-start align-middle">
                      <i className="fa fa-circle fa-fw txt-fail"></i>Inactive
                    </td>
                  )} */}
                  <td className="text-start align-middle">
                    <a title="Edit" onClick={() => handleEdit(city)}>
                      <i className="fa fa-edit fa-fw"></i>
                    </a>

                    {city?.status === "Active" ? (
                      <i
                        className="fa fa-circle fa-fw txt-success pe-auto"
                        style={{
                          cursor: "pointer",
                        }}
                        title="Deactivate"
                        onClick={() => setDel(city)}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-circle fa-fw txt-fail"
                        title="Activate"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => setDel(city)}
                      ></i>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="p-2 text-center">
                  {loading || isFetching ? "Loading..." : "No records found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {myData.length > postsPerPage && (
          <Pagination
            activePage={page}
            itemsCountPerPage={postsPerPage}
            totalItemsCount={myData.length}
            pageRangeDisplayed={postsPerPage}
            onChange={handlePageChange}
          />
        )}
      </div>

      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to{" "}
            {del?.status === "Active" ? "deactivate " : "activate "}
            this city?
          </p>
          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Offcanvas show={filter} onHide={handleHide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>Filter </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modall_body">
          <div className="container-fluid filter-box pt-3 filterScroll">
            <div className="form-group">
              <label htmlFor="tags"> City </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter city"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group ">
              <label htmlFor="tags">Zone</label>
              <select
                value={zoneId}
                className="form-control full-width"
                onChange={(e) => setZoneId(e.target.value)}
              >
                <option>Select Zone</option>
                {zones?.map((zd) => (
                  <option
                    disabled={zd?.status === "Inactive"}
                    key={zd?.id}
                    value={zd?.id}
                  >
                    {zd.zone_name}
                  </option>
                ))}
              </select>{" "}
              <i></i>{" "}
            </div>
            <div className="form-group ">
              <label htmlFor="tags">State</label>
              <select
                className="form-control full-width"
                value={stateId}
                onChange={(e) => setStateId(e.target.value)}
              >
                <option>Select State</option>
                {states?.map((st) => (
                  <option
                    disabled={st?.status === "Inactive"}
                    key={st?.id}
                    value={st?.id}
                  >
                    {st.state_name}
                  </option>
                ))}
              </select>{" "}
              <i></i>{" "}
            </div>
          </div>

          <section className="pull-right m-4" onClick={()=>applyFilter()}>
            <a className="btn button_new_success p-3">Apply</a>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <AddCity
        refetch={refetch}
        editData={edit}
        setEdit={setEdit}
        clearFilter={clearFilter}
        filtersAvailable={name || zoneId || stateId}
        applyFilter={applyFilter}
        searchkey={searchkey}
        handleSearch={handleSearch}
      />
    </div>
  );
}

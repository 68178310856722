import React from "react";
import AdminPageCard from "../../../components/common/AdminPageCard/AdminPageCard";
import AdminPageHeader from "../../../components/AdminPageHeader";
import Breadcrumb from "../../../components/common/Breadcrumb";
import FieldExecutiveServices from "../../../services/API/FieldExecutive";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { truncate } from "../../../utils/utils";
import { Link } from "react-router-dom";
const FEDetails = () => {
  const { id } = useParams();
  const breadcrumbs = [
    {
      title: "Home",
      link: "vendor/dashboard",
      isActive: true,
    },
    {
      title: "Field Executives Lists" ,
      link: "/vendor/field-executives",
      isActive: true,
    },
    {
      title: "View Details",
      // link: "/vendor/field-executives",
      isActive: false,
    },
  ];

  const { data: FEData } = useQuery(
    [`/api/field_executive/edit/${id}`, id],
    FieldExecutiveServices.FEDetails,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return (
    <div id="main" role="main">
      <div id="content">
        <Breadcrumb items={breadcrumbs} />
        <AdminPageCard>
          <AdminPageHeader title="View Field Executive Details" />
          {/* <a
            href="vendor_license_requests.html"
            target="_blank"
            className=" button_new pull-right"
          >
            Show License Details
          </a> */}
          <div className="row mt-3">
            <article className="col-lg-6  mb-3">
              <div className="card card-sm card-shadow mb	-3">
                <div className="card-body">
                  <span
                    className="font-sm badge pull-right bg-color-blue"
                    title="User Id"
                  >
                    #{FEData?.data[0]?.id}
                  </span>
                  <span className="font-xl">{FEData?.data[0]?.name}</span>
                  <br />

                  <div className="padding-top-15 d-flex align-items-center" >
                    <i
                      className="fa fa-map-marker fa-fw"
                      aria-hidden="true"
                      style={{ color: "#64308e", marginRight:'2px' }}
                      title="Field Executive Address"
                    ></i>{" "}
                    <span style={{width:'100%'}}>
                    {FEData?.data[0]?.address ? FEData?.data[0]?.address : " "}
                    </span>
                    {/* {FEData?.data[0]?.cities?.map(
                      (item, index) =>
                        `${item?.name} ${
                          index !== FEData?.data[0]?.cities?.length - 1
                            ? "|"
                            : ""
                        }`
                    )}{" "}
                    |{" "}
                    {FEData?.data[0]?.states?.map(
                      (item, index) =>
                        `${item?.name} ${
                          index !== FEData?.data[0]?.states?.length - 1
                            ? "|"
                            : ""
                        }`
                    )} */}
                  </div>
                  <div className=" padding-top-15">
                    <i
                      className="fa fa-envelope fa-fw"
                      aria-hidden="true"
                      style={{ color: "#64308e" }}
                      title="Email ID"
                    ></i>
                    <span className="note">Email Id : </span>
                    {FEData?.data[0]?.email}
                  </div>
                  <div className=" padding-top-15">
                    <i
                      className="fa fa-phone fa-fw"
                      aria-hidden="true"
                      style={{ color: "#64308e" }}
                      title="Mobile Number"
                    ></i>
                    <span className="note">Mobile Number : </span>{" "}
                    {FEData?.data[0]?.mobile_no}
                  </div>
                  <div className=" padding-top-15">
                    <i
                      className="fa fa-user fa-fw"
                      aria-hidden="true"
                      style={{ color: "#64308e" }}
                      title="Executive"
                    ></i>
                    <span className="note">
                      Executive : {" "}
                      {FEData?.data[0]?.executives?.map((item, index) => (
                        <Link
                          to={`/vendor/executive/${item?.id}`}
                          className="font-md"
                        >
                          {item?.name}
                          {/* {` ${item?.name} ${
                            index !== FEData?.data[0]?.states?.length - 1
                              ? "|"
                              : ""
                          }
                          `} */}
                        </Link>
                      ))}
                    </span>
                  </div>
                  <div className=" padding-top-15">
                    <i
                      className={`fa fa-circle fa-fw ${
                        FEData?.data[0]?.is_enabled ? "txt-success" : "txt-fail"
                      }`}
                      title="Status"

                    ></i>
                    <span className="note">Status : </span>{" "}
                    {FEData?.data[0]?.is_enabled ? "Active" : "Inactive"}
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div className="mt-3">
            <div className="card card-sm card-shadow mb	-3">
              <div className="card-body">

                <h3>Regions Operated & Areas of Expertise</h3>
                <div className="d-flex justify-content-between flex-wrap mt-5 gap-3">
                  <section>
                    <h4>Regions</h4>
                    {FEData?.data[0]?.zones?.map((zone) => {
                      return (
                        <li key={zone?.id} style={{ whiteSpace: "nowrap" }}
                        title={zone?.name.length > 15 &&  zone?.name}

                        >
                                {zone?.name.length > 15 ? zone?.name.substring(0,15).concat('...') : zone?.name}
                        </li>
                      );
                    })}
                  </section>

                  <section>
                    <h4>States</h4>
                    {FEData?.data[0]?.states?.map((state) => {
                      return (
                        <li key={state?.id} style={{ whiteSpace: "nowrap" }}
                        title={state?.name.length > 15 &&  state?.name}

                        >
                                {state?.name.length > 15 ? state?.name.substring(0,15).concat('...') : state?.name}
                        </li>
                      );
                    })}
                  </section>
                  <section>
                    <h4>Cities</h4>
                    {FEData?.data[0]?.cities?.map((city) => {
                      return (
                        <li key={city?.id} style={{ whiteSpace: "nowrap" }}
                        title={city?.name.length > 15 &&  city?.name}

                        >
                                {city?.name.length > 15 ? city?.name.substring(0,15).concat('...') : city?.name }
                        </li>
                      );
                    })}
                  </section>
                  <section>
                    <h4>Municipal Corporations</h4>
                    {FEData?.data[0]?.municipals?.map((municipal) => {
                      return (
                        <li
                          key={municipal?.id}
                          style={{ whiteSpace: "nowrap" }}
                          title={municipal?.name.length > 20 &&  municipal?.name}

                        >
                                {municipal?.name.length > 20 ? municipal?.name.substring(0,20).concat('...') : municipal?.name  }
                        </li>
                      );
                    })}
                  </section>
                  <section>
                    <h4>Industry Types</h4>
                    {FEData?.data[0]?.industry_types?.map((industry) => {
                      return (
                        <li key={industry?.id} style={{ whiteSpace: "nowrap" }}
                        title={industry?.name.length > 15 && industry?.name }

                        >
                                 {industry?.name.length>15 ? industry?.name.substring(0,15).concat('...') : industry?.name }
                        </li>
                      );
                    })}
                  </section>

                  <section>
                    <h4>License Types</h4>
                    {FEData?.data[0]?.license_types?.map((license) => {
                      return (
                        <li key={license?.id} style={{ whiteSpace: "nowrap" }}
                        title={license?.name.length > 15 && license?.name}

                        >
                                {license?.name.length>15 ? license?.name.substring(0,15).concat('...') :license?.name }
                        </li>
                      );
                    })}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </AdminPageCard>
      </div>
    </div>
  );
};

export default FEDetails;

import React from "react";
import axiosInstance from "../axiosConfig";

class LocationSettingServices extends React.Component {
  getMc = async () => {
    return await axiosInstance.get(`/api/get_municipal_details`);
  };
  storeMC = async (mc) => {
    return await axiosInstance.post(`/api/store_municipal`, mc);
  };

  editMC = async (id) => {
    return await axiosInstance.get(`/api/municipalId/${id}`);
  };

  updateMC = async (id, mc) => {
    return await axiosInstance.post(`/api/update_municipal/${id}`, mc);
  };

  deactivateMC = async (id, mc) => {
    return await axiosInstance.post(`/api/update_status_municipal/${id}`, mc);
  };

  getZone = async () => {
    return await axiosInstance.get(`/api/get_zone`);
  };

  getActiveZone = async () => {
    return await axiosInstance.get(`/api/get_zone`, {
      params: { active: 1 },
    });
  };

  storeZone = async (zone) => {
    return await axiosInstance.post(`/api/store_zone`, zone);
  };

  editZone = async (id) => {
    return await axiosInstance.get(`/api/zonesId/${id}`);
  };

  updateZone = async (id, zone) => {
    return await axiosInstance.post(`/api/update_zones/${id}`, zone);
  };

  deactivateZone = async (id, zone) => {
    return await axiosInstance.post(`/api/update_status_zones/${id}`, zone);
  };

  getState = async () => {
    return await axiosInstance.get(`/api/get_states`);
  };
  getActiveState = async () => {
    return await axiosInstance.get(`/api/get_states`, {
      params: { active: 1 },
    });
  };

  storeState = async (state) => {
    return await axiosInstance.post(`/api/store_state`, state);
  };

  editState = async (id) => {
    return await axiosInstance.get(`/api/statesId/${id}`);
  };

  updateState = async (id, state) => {
    return await axiosInstance.post(`/api/update_states/${id}`, state);
  };

  deactivateState = async (id, state) => {
    return await axiosInstance.post(`/api/update_status/${id}`, state);
  };

  getCity = async () => {
    return await axiosInstance.get(`/api/get_city`);
  };

  getActiveCity = async (payload) => {
    return await axiosInstance.post(`/api/city_filter?active=1`, payload);
  };

  storeCity = async (city) => {
    return await axiosInstance.post(`/api/store_city`, city);
  };

  editCity = async (id) => {
    return await axiosInstance.get(`/api/citiesId/${id}`);
  };

  updateCity = async (id, city) => {
    return await axiosInstance.post(`/api/update_cities/${id}`, city);
  };

  deactivateCity = async (id, city) => {
    return await axiosInstance.post(`/api/update_status_cities/${id}`, city);
  };

  searchState = async (key) => {
    return await axiosInstance.get(`/api/search_state`, {
      params: { name: key },
    });
  };
  searchCity = async (key) => {
    return await axiosInstance.get(`/api/search_city`, {
      params: { name: key },
    });
  };
  searchMC = async (key) => {
    return await axiosInstance.get(`/api/search_municipal`, {
      params: { name: key },
    });
  };
  searchZone = async (key) => {
    return await axiosInstance.get(`/api/search`, { params: { name: key } });
  };
  filterMC = async (payload) => {
    return await axiosInstance.post(`/api/municipal_filter`, payload);
  };
  filterCity = async (payload) => {
    return await axiosInstance.post(`/api/city_filter`, payload);
  };
  exportMC = async () => {
    return await axiosInstance.get(`/api/export_municipal`);
  };
  exportCity = async () => {
    return await axiosInstance.get(`/api/export_city`);
  };
  exportState = async () => {
    return await axiosInstance.get(`/api/export_state`);
  };
}

const instance = new LocationSettingServices();

export default instance;

import React from "react";
import { Offcanvas } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { availableFormats } from "./AddDoc";
import { useState } from "react";
import { multiSelectStyle } from "../../../components/CreateExecutive/MultiSelectStyle";

const Filter = ({ filter, handleHide, name, setName, format, setFormat,setPage }) => {
  const [NameInput, setNameInput] = useState(name);
  const [formats, setFormats] = useState(format);

  const submit = () => {
    setName(NameInput);
    setFormat(formats);
    setPage(1)
    handleHide();
  };
  return (
    <Offcanvas show={filter} onHide={handleHide} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h3>Filter </h3>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modall_body">
        <div className="pt-3 mb-10">
          <div className="form-group">
            <label htmlFor="tags"> Dcoument Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Document name"
              value={NameInput}
              onChange={(e) => setNameInput(e.target.value)}
            />
          </div>
          <div className="form-group ">
            <label htmlFor="tags"> Accepted File Format</label>
            <label className="select select-multiple" />
            <Multiselect
              options={availableFormats}
              isObject={false}
              selectedValues={formats}
              placeholder={formats.length > 0 ? "" : "Select file format"}
              onSelect={(selectedList, selectedItem) => {
                setFormats(selectedList);
              }}
              onRemove={(selectedList, selectedItem) => {
                setFormats(selectedList);
              }}
              displayValue="name"
              className="dropdown"
              style={multiSelectStyle}
            />
          </div>
        </div>
        <section className="pull-right m-4" onClick={()=>submit()}>
          <a className="btn button_new_success p-3">Apply</a>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Filter;

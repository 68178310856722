import React, { useState } from "react";
import "./myAccount.css";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import { useNavigate } from "react-router";
import Profile from "../../../components/MyAccount";
import { toast } from "react-toastify";
import {
  validateAccountFormData,
  validateChangePasswordFormData,
} from "../../../utils/utils";
import useAuth from "../../../hooks/useAuth";
import MyAccountService from "../../../services/API/MyAccountManager";
import AuthServices from "../../../services/authServices";

import { localStorageKeys } from "../../../constant/keys";

export default function MyAccountAdmin() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [passwordFormData, setPasswordFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const handleChangePassword = (e) => {
    const { value, name } = e.target;
    setPasswordFormData({ ...passwordFormData, [name]: value.trim() });
  };

  const handleSubmitChangePassword = async () => {
    const error = validateChangePasswordFormData(passwordFormData, () =>
      setPasswordFormData({
        newPassword: "",
        confirmPassword: "",
      })
    );
    if (error) {
      toast.error(error);
    } else {
      try {
        const res = await MyAccountService.changePasswordService({
          userId: auth?.userId,
          ...passwordFormData,
        });
        if (res?.status_code === 200) {
          toast.success("Your password updated successfully.");
          const response = await AuthServices.logout();
          if (response) {
            localStorage.removeItem(localStorageKeys.aparajithaData);
            auth.setIsAuthenticated(false);
            navigate("/admin/login");
          }
        }
      } catch (error) {
        toast.message(error.message);
        setPasswordFormData({
          newPassword: "",
          confirmPassword: "",
        });
      }
    }
  };
  // Edit Details
  const localStore = JSON.parse(localStorage.getItem("aparajithaData"));
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    fullName: auth?.name,
    email: auth?.email,
    phone: auth?.phone ? auth?.phone.replace("+91", "") : "",
    whatsapp_status: localStore?.data?.whatsapp_status,
    user_mob: localStore?.data?.mobile_no ? localStore?.data?.mobile_no.replace("+91","") :"" ,
    user_email: localStore?.data?.email,
  });

  const handleChangeDetails = (e) => {
    const { value, name } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "phone"
          ? value.replace(/\D/g, "").trim()
          : name === "email"
          ? value.trim()
          : value,
    });
  };

  const handleCancel = () => {
    setFormData({
      fullName: auth?.name,
      email: auth?.email,
      phone: auth?.phone ? auth?.phone.replace("+91", "") : "",
      whatsapp_status: localStore?.data?.whatsapp_status,
      user_email: localStore?.data?.email || "",
      user_mob: localStore?.data?.mobile_no ? localStore?.data?.mobile_no.replace("+91","") :"",
    });
    setIsEdit(false);
  };

  const handleSubmit = async () => {
    if (isEdit) {
      const error = validateAccountFormData(formData);
      if (error) {
        toast.error(error);
      } else {
        try {
          const res = await MyAccountService.updateDetailsMyAccount({
            userId: auth?.userId,
            ...formData,
          });
          if (res?.status_code === 200) {
            auth?.setRoleId(res?.data.user_role_id);
            auth?.setName(res?.data.name);
            auth?.setUserId(res?.data.id);
            auth?.setEmail(res?.data.email);
            auth?.setPhone(res?.data.mobile_no);
            const newData = {
              ...JSON.parse(localStorage.getItem("aparajithaData")),
              data: res.data,
              user_email: res?.data?.email,
              user_mob:res?.data?.mobile_no,
            };
            toast.success("Your account details updated successfully");
            setIsEdit(false);
            localStorage.setItem("aparajithaData", JSON.stringify(newData));
            setFormData({
              ...formData,
              user_email: res?.data?.email,
              user_mob: res?.data?.mobile_no ? res?.data?.mobile_no.replace("+91","") : "",
            });
          }
        } catch (error) {
          toast.message(error.message);
        }
      }
    } else {
      setIsEdit(true);
    }
  };
  return (
    <AdminLayout test="active">
      <AdminPageContainer>
        <div id="main" role="main">
          <div id="content">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item ">
                  <a
                    style={{
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/system-admin/license-master")}
                  >
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active">My Account</li>
              </ol>
            </div>
            <Profile
              auth={auth}
              passwordFormData={passwordFormData}
              handleChangePassword={handleChangePassword}
              handleSubmitChangePassword={handleSubmitChangePassword}
              // edit Details
              handleChangeDetails={handleChangeDetails}
              handleSubmit={handleSubmit}
              isEdit={isEdit}
              formData={formData}
              handleCancel={handleCancel}
            />
          </div>
        </div>
      </AdminPageContainer>
    </AdminLayout>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import AuthServices from "../../services/authServices";
import { validateEmail } from "../../utils/utils";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router";
import AdminLayout from "../../components/layouts/adminLayout";
import ClientCaptcha from "react-client-captcha";
import { ROLES } from "../../constant/roles";

const initial = {
  email: "",
  password: "",
  captcha: "",
};
const Login = () => {
  const [loginData, setLoginData] = useState(initial);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const auth = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState({});
  const navigate = useNavigate();
  const captchaRef = useRef();
  const reloadCaptcha = () => {
    captchaRef?.current.generateCaptcha();
  };
  const handleChange = (event) => {
    setLoginData((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const error = {};
    if (!loginData?.email) error.email = "Please enter your e-mail ID";
    else if (!validateEmail(loginData?.email)) {
      error.email = "Please enter valid e-mail ID";
    }
    if (!loginData?.password) {
      error.password = "Please enter your password";
    }
    if (!loginData?.captcha) {
      error.captcha = "Please enter the captcha";
    } else if (loginData?.captcha && loginData?.captcha !== captcha) {
      error.captcha = "Captcha doesn't match";
    }

    return error;
  };

  useEffect(() => {
    if (AuthServices.handleAuthentication())
      navigate("/system-admin/license-master");
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      reloadCaptcha();
      setLoginData({ ...loginData, captcha: "" });
      return;
    }
    setErrors({});
    const formData = new FormData();
    formData.append("email", loginData.email);
    formData.append("password", loginData.password);
    setLoading("login");
    const response = await AuthServices.login({ data: formData });
    if (response?.access_token) {
      setLoading(false);
      window.onbeforeunload = function (e) {
        return "Are you leaving the site?";
      };
      window.onunload = function (e) {
        localStorage.clear();
      };
      localStorage.setItem("aparajithaData", JSON.stringify(response));
      auth.setUserId(response?.data?.id);
      auth.setRoleId(response?.data?.user_role_id);
      auth.setName(response?.data?.name);
      auth.setEmail(response?.data.email);
      auth.setPhone(response?.data.mobile_no);
      auth.setIsAuthenticated(true);
      toast.success("Logged in successfully");

      switch (response?.data?.user_role_id) {
        case ROLES.systemAdmin:
          navigate("/system-admin/license-master");
          break;
        case ROLES.allianceManager:
          navigate("/alliance-manager/overall-license-requests");
          break;
        case ROLES.vendor:
          navigate("/vendor/my-account");
          break;
        case ROLES.executive:
          navigate("/executive/my-account");
          break;
        case ROLES.fieldExecutive:
          navigate("/field-executive/my-account");
          break;
        default:
          toast.error("Invalid credentials");
          break;
      }
      // if (response?.data?.user_role_id == 1) {
      //   navigate("/system-admin/license-master");
      // } else {
      //   navigate("/alliance-manager/overall-license-requests");
      // }
    } else {
      reloadCaptcha();
      setLoginData(initial);
    }
    setLoading(false);
    // else {
    //   setLoading(false);
    //   toast.error("Something went wrong");
    // }
  };

  return (
    <AdminLayout sideMenu={false}>
      <div
        style={{
          background: "url(/img/screen-background.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="d-flex justify-content-center align-items-center h-100 "
      >
        <div className="col-lg-4 col-md-6 col-sm-10">
          <div className="card card-lg">
            <div className="card-body">
              <h2 className="mb-4">Login</h2>
              <form
                onKeyDown={(e) => {
                  if (
                    (e.key === "Enter" || e.key === "NumpadEnter") &&
                    !loading
                  ) {
                    submitForm(e);
                  }
                }}
              >
                <div className="row">
                  <div className="col-sm-12 mb-4 mb-sm-0">
                    <div className="mb-4">
                      <label
                        className="form-label font-md"
                        htmlFor="contactsFormCompany"
                      >
                        Email ID<span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        name="email"
                        id="email"
                        placeholder="Enter email ID"
                        value={loginData.email}
                        onChange={handleChange}
                      />
                      {errors?.email && (
                        <div className="text-danger  mt-2 ml-1">
                          {errors?.email}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="position-relative mb-5">
                  <label
                    className="form-label font-md"
                    htmlFor="contactsFormWorkEmail"
                  >
                    Password<span style={{ color: "red" }}> *</span>
                  </label>
                  <i
                    className={`position-absolute hover:cursor-pointer fa ${
                      isPasswordShow ? "fa-eye" : "fa-eye-slash"
                    }`}
                    style={{
                      right: "10px",
                      top: "60%",
                    }}
                    // onClick={() => setIsPasswordShow(!isPasswordShow)}
                    onMouseOver={() => setIsPasswordShow(true)}
                    onMouseOut={() => setIsPasswordShow(false)}
                  ></i>
                  <input
                    type={isPasswordShow ? "text" : "password"}
                    className="form-control eyeOne"
                    name="password"
                    placeholder="Enter password"
                    id="contactsFormWorkEmail"
                    aria-label="email@site.com"
                    value={loginData.password}
                    onChange={handleChange}
                  />
                  {errors?.password && (
                    <div className="text-danger position-absolute mb-3">
                      {errors?.password}
                    </div>
                  )}
                </div>

                <div className="position-relative mb-5">
                  <ClientCaptcha
                    captchaCode={(code) => {
                      setCaptcha(code);
                      setLoginData({ ...loginData, captcha: "" });
                    }}
                    backgroundColor="#eee"
                    fontColor="red"
                    fontFamily="Latin script"
                    width={120}
                    charsCount={6}
                    ref={captchaRef}
                  />
                  <input
                    type="text"
                    className="form-control mt-3"
                    name="captcha"
                    id="captcha"
                    maxLength={6}
                    value={loginData?.captcha}
                    placeholder="Enter captcha code"
                    onChange={handleChange}
                  />
                  {errors?.captcha && (
                    <div className="text-danger  mt-2 ml-1">
                      {errors?.captcha}
                    </div>
                  )}
                </div>

                <div className="d-grid gap-4">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    onClick={!loading ? submitForm : () => {}}
                  >
                    {loading ? "Loading..." : "Submit"}
                  </button>
                </div>
                <div className="text-end font-md">
                  <a
                    href="#"
                    style={{ bottom: 0, right: 0 }}
                    onClick={() => navigate("/forgot")}
                  >
                    Forgot Password
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Login;

import React, { useEffect } from "react";
import ExecutiveLayout from "../../../components/layouts/ExecutiveLayout";
import { Link, useNavigate } from "react-router-dom";
// import { Button } from 'react-bootstrap';
import { useState } from "react";
import VendorExecutive from "../../../services/API/VendorExecutive";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
// import Modal from "react-bootstrap/Modal";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import LeaveCalendar from "./LeaveCalendar";
import OOOchart from "../../../components/GraphModal/OOOchart";
import OOOGraph from "../../../components/GraphModal/OOOModal";
import "./OOO.css";
import Loader from "../../../components/Loader";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";
export default function CreateOOO() {
  const [isOutOfOffice, setIsOtOfOffice] = useState("true");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [subject, setSubject] = useState(null);
  const [reason, setReason] = useState(null);
  const [disableFlag, setDisableflag] = useState(false);
  const auth = useAuth();
  const [myLeaveList, setMyLeaveList] = useState(null);
  const [myLeaveList1, setMyLeaveList1] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [radioFlag, setRadioFlag] = useState();
  const [radioFlag1, setRadioFlag1] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];

  const handleRadioChange = (e) => {
    setIsOtOfOffice(e.target.value);

  };

  // const radioButtonInitialMapping = async () => {
  //   const response = await VendorExecutive.isOutOfOffice(payload);
  // };

  useEffect(() => {
    if (isOutOfOffice == "true") {
      setStartDate(new Date());
      setEndDate(new Date());
      setSubject("");
      setReason("");
    }
  }, [isOutOfOffice]);

  const clearValues = (e) => {
    setIsOtOfOffice(e.target.value);
    setStartDate();
    setEndDate();
  };

  const getLeavesToday = async () => {
    const payload = {
      user_id: auth?.userId,
    };
    const result = await VendorExecutive.isOutOfOffice(payload);
    if (result) {
      if (result?.data?.length) {
        setIsOtOfOffice("false");
        setStartDate(moment(result.data[0].start_date).toDate());
        setSubject(result.data[0].subject);
        setReason(result.data[0].reason);
        setEndDate(moment(result.data[0].end_date).toDate());
      }
    }
  };

  useEffect(() => {
    getLeavesToday();
  }, []);

  const handleClick = async () => {
    setMyLeaveList(auth?.userId);
    setShowModal(true);
  };

  const handleClick1 = async () => {
    // const currentDate = moment().format("DD-MM-YYYY");
    // const dateTemplate = await moment(currentDate).format("DD-MM-YYYY");
    // const payload = {
    //   id: auth?.userId,
    //   date: currentDate,
    // };
    // const response = await VendorExecutive.getMyLeaveHistory(payload);
    // const res = response.data.leave_history.map((el) => {
    //   return {
    //     start: new Date(`${el.start_date}T10:00:00`),
    //     end: new Date(`${el.end_date}T12:00:00`),
    //     title: "-",
    //   };
    // });
    // setMyLeaveList1(res);
    setShowModal1(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setMyLeaveList(null);
  };
  const handleClose1 = () => {
    setShowModal1(false);
    setMyLeaveList(null);
  };
  const validate = () => {
    if (isOutOfOffice == null)
      return "Please select Out of Office using the button";
    if (!startDate) return "Please select start date";
    if (!endDate) return "Please select end date";
    if (!subject) return "Please enter subject";
    if (subject.length < 5 || subject.length > 500)
      return "Please enter valid input with 5-500 characters";
    if (!reason) return "Please enter reason";
    if (reason.length < 5 || reason.length > 500)
      return "Please enter valid input with 5-500 characters";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      out_of_office: isOutOfOffice,
      subject,
      reason,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      created_for: auth?.userId,
      created_by: auth?.userId,
      is_vendor: 0,
    };
    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }
    setLoading("create");
    const response = await VendorExecutive.createOOO(payload);
    if (response) {
      toast.success("Executive Out of Office status updated successfully");
      setStartDate(null);
      setEndDate(null);
      setSubject("");
      setReason("");
    }
    setLoading(false);
  };

  const compareDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start > end) {
      toast.error("End date Should be greater than or equals to start date");
      setEndDate("");
    }
  };

  const changeYear = async (data) => {
    const yearPayload = `1-1-${data}`;
    const payload = {
      id: auth?.userId,
      date: yearPayload,
    };

    const result = await VendorExecutive.totalLeavesPerMonth(payload);
    setMyLeaveList(result.data);
  };

  return (
    <ExecutiveLayout>
      <div id="main" role="main">
        <div id="content">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item ">
                <Link to={"#"}>Home</Link>
              </li>
              <li className="breadcrumb-item active">Out of Office Settings</li>
            </ol>
          </div>
          <div>
            <div className="row">
              <div className="">
                <div className="card card-sm card-shadow mb-3">
                  <div className="card-body">
                    <form className="smart-form">
                      <div className="d-flex align-items-center justify-content-between">
                        <h2 style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                          Out of Office Settings{" "}
                        </h2>

                        <div
                          className=" d-flex align-items-center "
                          style={{ gap: "10px" }}
                        >
                          <button
                            className=" button_new pull-right mt-0"
                            data-toggle="modal"
                            data-target="#showOOOTrend"
                            type="button"
                            onClick={handleClick1}
                          >
                            History
                            {/* <i
                          className="fa fa-calendar fa-fw"
                          title="Out of Office History"
                        ></i> */}
                          </button>

                          <button
                            className=" button_new pull-right mt-0"
                            data-toggle="modal"
                            data-target="#showOOOTrend"
                            type="button"
                            onClick={handleClick}
                          >
                            <i
                              className="fa fa-calendar fa-fw"
                              title="Out of Office Trend"
                            ></i>
                          </button>
                        </div>
                      </div>

                      <fieldset>
                        <div className="row padding-top-10">
                          <section className="col col-6">
                            <div className="inline-group">
                              <label className="radio">
                                <input
                                  type="radio"
                                  name="isOutOfOffice"
                                  style={{ top: "3.5px" }}
                                  value="true"
                                  checked={isOutOfOffice == "true"}
                                  onChange={handleRadioChange}
                                  // onChange={(e) => {
                                  //   setDisableflag(true)
                                  //   setIsOtOfOffice(e.target.value);
                                  //   // setRadioFlag1(false);
                                  //   // setRadioFlag(true);
                                  // }}
                                  // onChange={(e) =>{
                                  //   setDisableflag(true)
                                  //   setIsOtOfOffice(e.target.value)
                                  //   //handleRadioChange
                                  // }}
                                  //checked
                                />
                                <i></i>Out of Office Off
                              </label>
                              <label className="radio">
                                <input
                                  type="radio"
                                  name="isOutOfOffice"
                                  style={{ top: "3.5px" }}
                                  value="false"
                                  checked={isOutOfOffice == "false"}
                                  onChange={clearValues}
                                  // onChange={(e) => {
                                  //   setIsOtOfOffice(e.target.value);
                                  //   setDisableflag(false)
                                  //   // setRadioFlag1(true);
                                  //   // setRadioFlag(false);
                                  // }}
                                  // onChange={(e)=>{
                                  //   setDisableflag(true)
                                  //   setIsOtOfOffice(e.target.value)
                                  //   //handleRadioChange()
                                  // }}
                                />
                                <i></i>Out of Office On
                              </label>
                            </div>
                          </section>
                        </div>
                        <div className="row">
                          <div className="col col-3">
                            <div className="">
                              <label className="label">
                                Expected Start Date
                                <span style={{ color: "red" }}> *</span>
                              </label>
                              <div className="input-sm">
                                <DatePicker
                                  minDate={moment().toDate()}
                                  selected={startDate}
                                  onChange={(e) => setStartDate(e)}
                                  type="date"
                                  value={startDate}
                                  dateFormat="dd-MM-yyyy"
                                  className="py-2 col-3"
                                  placeholderText="DD/MM/YYYY"
                                  disabled={
                                    isOutOfOffice == "true" ? true : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col col-3">
                            <div>
                              <label className="label">
                                Expected End Date
                                <span style={{ color: "red" }}> *</span>
                              </label>
                              <div className="input-sm ">
                                <DatePicker
                                  minDate={moment().toDate()}
                                  selected={endDate}
                                  onChange={(e) => setEndDate(e)}
                                  type="date"
                                  value={endDate}
                                  dateFormat="dd-MM-yyyy"
                                  className="py-2 col-3"
                                  placeholderText="DD/MM/YYYY"
                                  disabled={
                                    isOutOfOffice == "true" ? true : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row padding-top-10">
                          <Form.Group className="col col-6">
                            <label className="label">
                              Subject<span style={{ color: "red" }}> *</span>
                            </label>
                            <label className="input">
                              <input
                                type="text"
                                style={
                                  isOutOfOffice == "true"
                                    ? {
                                        backgroundColor:
                                          "rgba(239, 239, 239, 0.8)",
                                      }
                                    : {}
                                }
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                disabled={
                                  isOutOfOffice == "true" ? true : false
                                }
                              />
                            </label>
                          </Form.Group>
                        </div>
                        <div className="row padding-top-10 pb-3">
                          <Form.Group className="col col-6">
                            <label className="label">
                              Reason for Out of Office{" "}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <label className="textarea textarea-resizable">
                              <textarea
                                rows={3}
                                style={
                                  isOutOfOffice == "true"
                                    ? {
                                        backgroundColor:
                                          "rgba(239, 239, 239, 0.8)",
                                      }
                                    : {}
                                }
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                disabled={
                                  isOutOfOffice == "true" ? true : false
                                }
                              />
                            </label>
                          </Form.Group>
                        </div>
                      </fieldset>
                      <footer>
                        <button
                          type="submit"
                          className="btn button_new button_new_success"
                          onClick={handleSubmit}
                          disabled={isOutOfOffice == "true" ? true : false}
                        >
                          {loading == "create" ? <Loader /> : "Save"}
                        </button>
                      </footer>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LeaveCalendar
        showModal1={showModal1}
        handleClose={handleClose1}
        myLeaveList1={myLeaveList1}
      />
      <OOOGraph
        show={showModal}
        handleClose={handleClose}
        data={myLeaveList}
        flag={"executive"}
        // changeYear={changeYear}
      />
    </ExecutiveLayout>
  );
}

import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import FieldExecutiveServices from "../../../services/API/FieldExecutive";
import { Link } from "react-router-dom";
import moment from "moment";
import SideFilter from "../../Vendor/FieldExecutive/SideFilter";
import Breadcrumb from "../../../components/common/Breadcrumb";
import AdminPageCard from "../../../components/common/AdminPageCard/AdminPageCard";
import AdminPageHeader from "../../../components/AdminPageHeader";
import AdminTable from "../../../components/common/AdminTable/AdminTable";
import Pagination from "react-js-pagination";
import ADModal from "../ADModal/ADModal";
import { useContext } from "react";
import { AuthContext } from "../../../context/authContext";

const FEListing = () => {
  const [adItem, setADItem] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const auth = useContext(AuthContext);
  const limit = 20;

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["/api/field_executive", { ...filters, limit, page }],
    FieldExecutiveServices.FEByExecutive,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const breadcrumbs = [
    {
      title: "Home",
      link: "/executive/dashboard",
      isActive: true,
    },
    {
      title: "Field Executives",
      isActive: false,
    },
  ];

  const tableRows = [
    {
      title: "S. No.",
      value: (column, index) => limit * (page - 1) + index + 1,
      thProps: { width: "5%" },
    },
    {
      title: "Name",
      value: (column) => (
        <Link to={`/vendor/field-executives/details/${column?.id}`}>
          {column?.name}
        </Link>
      ),
    },
    {
      title: "Contact",
      value: (column) => column?.mobile_no,
    },
    {
      title: "Email",
      value: (column) => column?.email,
    },
    {
      title: "Created On",
      value: (column) =>
        moment(column?.created_on).format("DD-MM-YYYY, hh:mm A"),
    },
    {
      title: "Actions",
      value: (column) => (
        <>
          <i
            className="fa fa-edit fa-fw"
            onClick={() => navigate(`edit/${column?.id}`)}
          ></i>
          <i
            className={`fa fa-circle fa-fw ${
              column?.is_enabled == 0 ? "txt-fail" : "txt-success"
            } pe-auto`}
            style={{
              cursor: "pointer",
            }}
            title="Deactivate"
            onClick={() => setADItem(column)}
          ></i>
        </>
      ),
      thProps: { width: "80px" },
    },
  ];

  const downloadReport = async () => {
    setLoader("download");
    const resp = await FieldExecutiveServices.ExecFEdownloadReport(
      auth?.userId
    );
    setLoader("");
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Field-executive-list.csv");
      link.click();
    }
  };

  const Header = (
    <div className="d-flex gap-2 align-items-center pb-2">
      {Object.keys(filters)?.length > 0 ? (
        <div
          type="button"
          className="button_new mt-0"
          onClick={() => {
            setDisplaySearchField(false);
            setFilters("");
          }}
          disabled={!!loader}
        >
          Clear Filters
        </div>
      ) : (
        ""
      )}

      {displaySearchField ? (
        <input
          type="text"
          className="button_new mt-0"
          value={filters?.q || ""}
          onChange={(e) => {
            setPage(1);
            setFilters((prevState) => {
              if (e.target.value)
                return {
                  ...prevState,
                  q: e.target.value,
                };
              else {
                let newState = { ...prevState };
                delete newState.q;
                return newState;
              }
            });
          }}
          placeholder="Search"
          // onBlur={() => setDisplaySearchField(false)}
        />
      ) : (
        <div
          className=" button_new mt-0"
          onClick={() => {
            setDisplaySearchField(true);
          }}
          title="Search"
        >
          <i className="fa fa-search" aria-hidden="true"></i>
        </div>
      )}
      <div
        onClick={() => setShowFilter(true)}
        target="_blank"
        className=" button_new mt-0"
      >
        <i className="fa fa-filter fa-fw" title="Show Filter"></i>
      </div>
      <div onClick={() => downloadReport()} className=" button_new mt-0">
        <i
          className="fa fa-download"
          aria-hidden="true"
          title="Export to Excel"
        ></i>
      </div>

      <div
        target="_blank"
        className=" button_new mt-0"
        onClick={() => {
          navigate("create");
        }}
      >
        <i className="fa fa-plus fa-fw" title="Add Field Executive"></i>
      </div>
    </div>
  );

  return (
    <div className="main" id="main">
      <div id="content" className="content">
        <ADModal adItem={adItem} refetch={refetch} setADItem={setADItem} />

        {showFilter && (
          <SideFilter
            show={showFilter}
            filters={filters}
            setFilters={setFilters}
            onHide={() => setShowFilter(false)}
            setPage={setPage}
          />
        )}
        <Breadcrumb items={breadcrumbs} />

        <AdminPageCard>
          <div className="d-flex justify-content-between align-items-center">
            <AdminPageHeader title="Field Executives" />
            {Header}
          </div>
          <AdminTable
            columns={tableRows}
            data={data?.data?.data || []}
            isDataLoading={isLoading}
          />
          {data?.data?.total > limit && (
            <Pagination
              activePage={data?.data?.current_page}
              itemsCountPerPage={limit}
              totalItemsCount={data?.data?.total}
              pageRangeDisplayed={5}
              onChange={(number) => setPage(number)}
            />
          )}
        </AdminPageCard>
      </div>
    </div>
  );
};

export default FEListing;

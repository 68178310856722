import React from "react";
import AliianceManagerLayout from "../../../components/layouts/AliianceManagerLayout";
import ViewExecutiveDetails from "../../../components/ViewExecutiveDetails";
import { Link } from "react-head";
import { useNavigate, useParams } from "react-router";

export default function ExecutiveDetailsPage() {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <AliianceManagerLayout>
      <div id="main" role="main">
        <div id="content">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                href="#"
                onClick={() => navigate("/alliance-manager/dashboard")}
              >
                Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#" onClick={() => navigate("/alliance-manager/vendors")}>
                Vendors
              </a>
            </li>
            <li className="breadcrumb-item active">Executive Details</li>
          </ol>
          <ViewExecutiveDetails exec_id={params?.id} />
        </div>
      </div>
    </AliianceManagerLayout>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { AuthContext } from "../../context/authContext";
import { Outlet } from "react-router";
import AuthServices from "../../services/authServices";
import FullScreenLoader from "../../components/FullScreenLoader";

function IdleTimerContainer() {
  const auth = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const onIdle = async () => {
    if (auth?.isAuthenticated && isLastActiveTab()) {
      setLoader(true);
      const resp = await AuthServices.logout();
      localStorage.clear();
      window.onbeforeunload = function (e) {};
      window.location = "/admin/login";
    }
  };
  const handleTabFocus = () => {
    reset();
  };

  useEffect(() => {
    window.addEventListener("focus", handleTabFocus);
    return () => {
      window.removeEventListener("focus", handleTabFocus);
    };
  }, []);
  const { start, reset, isLastActiveTab } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * process.env.REACT_APP_INACTIVE_TIME_MIN,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });
  return (
    <>
      {loader && <FullScreenLoader title="Your session has expired!" show={loader} />}
      <Outlet />;
    </>
  );
}

export default IdleTimerContainer;

import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
  ResponsiveContainer,
} from "recharts";

import moment from "moment";
import { useState } from "react";
import "./OOOchart.css";
export default function OOOchart({
  data,
  width,
  height,
  flag,
  scrollerGraph,
  scrollerGraph1,
}) {
  function CustomTooltip({ payload, label, active }) {
    if (payload) {
      return (
        <div className="custom-tooltip" style={{ textAlign: "center" }}>
          <p className="intro" style={{ color: "black" }}>
            <b>{`${label}`}</b>
          </p>
          <p className="intro">{`${payload[0]?.value}`}</p>
        </div>
      );
    }

    return null;
  }

  return (
    <>
      <div className="map_btn_wrapper">
        <button onClick={scrollerGraph}>{`Prev`}</button>

        <button onClick={scrollerGraph1}>{`Next`}</button>
      </div>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          data={data}
          margin={{ top: 5, left: 5, right: 5, bottom: 15 }}
        >
          <XAxis
            dataKey="month"
            stroke="#8884d8"
            label={{
              value: "Months of the year",
              angle: 0,
              offset: -8,
              position: "insideBottom",
            }}
          />
          <YAxis
            type="number"
            domain={[0, 31]}
            label={{
              value: "Number of days",
              angle: -90,
              offset: -8,
              position: "center",
            }}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            // labelStyle={{color:"#7fffd4"}}
            // contentStyle={{ backgroundColor: 'grey',color:"red !important" }}
            content={<CustomTooltip />}
          />
          <CartesianGrid stroke="#cccc" strokeDasharray="5 5" />
          <Bar dataKey="leave_count" fill="#4acfee" barSize={30} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

import { Modal } from "react-bootstrap";
import React, { useContext, useState, useEffect } from "react";
import { LicenseConfigureContext, spl_format } from "..";
import LicenseConfigurationServices from "../../../../services/API/LicenseConfiguration";
import { toast } from "react-toastify";
import { validate } from "../licenceTabs/CompanyTypes";

export default function AddCompanyType({
  refetch,
  editData,
  setEdit,
  clearFilter,
  filtersAvailable,
  applyFilter,searchkey,handleSearch
}) {
  const { show, setShow } = useContext(LicenseConfigureContext);
  const [name, setName] = useState(null);
  const [short, setShort] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (editData) {
      setName(editData?.name);
      setShort(editData?.short_name);
    }
  }, [editData]);
  const createShortName = async (e) => {
    const { value } = e.target;
    setName(value);
    if (value.length === 0) {
      toast.error("Please enter company type");
      clearTimeout(timer);
      setShort("");
      return;
    }

    clearTimeout(timer);

    const newTimer = setTimeout(async () => {
      try {
        const response = await LicenseConfigurationServices.createShortName({
          short_name: value,
          model_name: "CompanyType",
        });
        setShort(response?.data);
      } catch (e) {
        toast.error(e.message);
      }
    }, 500);
    setTimer(newTimer);
  };
  const addCompany = async () => {
    const payload = { name, short_name: short };
    const error = validate(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const resp = await LicenseConfigurationServices.addCompanyType(payload);
      if (resp) {
        toast.success("Company Type created successfully");
        clearFilter();
        refetch();
        handleHide();
      }
    } catch (e) {
      toast.error(e);
    }
  };
  const updateCompany = async () => {
    const payload = { name, short_name: short };
    const error = validate(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.updateCompanyType(
        editData?.id,
        payload
      );
      if (response) {
        toast.success("Company Type updated successfully");
        // clearFilter();
        handleHide();
        if(filtersAvailable){
          applyFilter(1)
        }else if(searchkey){
          handleSearch({target:{value:searchkey}},1)
        }else{
          refetch();
        }
        setEdit(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const submitForm = () => {
    if (editData) {
      updateCompany();
    } else {
      addCompany();
    }
  };
  const handleHide = () => {
    setShow(null);
    setName(null);
    setShort(null);
    setEdit(null);
  };
  return (
    <Modal show={show === "companyType"} animation={false} onHide={handleHide}>
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {editData ? "Edit Company Type" : "Add Company Type"}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="tags">
            Company Type Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Enter Company Type Name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Short Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Short Name"
            value={short}
            maxLength={3}
            onChange={(e) => setShort(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={submitForm}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

import React from "react";
import axiosInstance from "../axiosConfig";

class StaticPageService extends React.Component {
  getAboutUsContent = async (payload) => {
    return await axiosInstance.get(`/api/get_content/about_us`);
  };

  submitAboutUsContent = async (payload) => {
    return await axiosInstance.post(`/api/store_content/about_us`, payload);
  };


  getContactUsContent = async () => {
    return await axiosInstance.get(`/api/get_content/contact_us`);
  };
  submitContactUsContent = async (payload) => {
    return await axiosInstance.post(`/api/store_content/contact_us`,payload);
  };

  getFAQContent = async (payload) => {
    return await axiosInstance.get(`/api/get_content/FAQ`);
  };

  submitFAQContent = async (payload) => {
    return await axiosInstance.post(`/api/store_content/FAQ`, payload);
  };

  getServiceRequestContent = async (payload) => {
    return await axiosInstance.get(`/api/get_content/service_request`);
  };

  submitServiceRequestContent = async (payload) => {
    return await axiosInstance.post(`/api/store_content/service_request`, payload);
  };

  getTermsOfUse = async (payload) => {
    return await axiosInstance.get(`/api/get_content/terms_of_use`);
  };

  submitTermsOfUse = async (payload) => {
    return await axiosInstance.post(`/api/store_content/terms_of_use`, payload);
  };

  getDisclaimerContent = async (payload) => {
    return await axiosInstance.get(`/api/get_content/disclaimer`);
  };

  submitDisclaimerContent = async (payload) => {
    return await axiosInstance.post(`/api/store_content/disclaimer`, payload);
  };


  getTermsAndConditionsContent = async (payload) => {
    return await axiosInstance.get(`/api/get_content/terms_and_conditions`);
  };

  submitTermsAndConditionsContent = async (payload) => {
    return await axiosInstance.post(`/api/store_content/terms_and_conditions`, payload);
  };

  getPrivacyPolicyContent = async (payload) => {
    return await axiosInstance.get(`/api/get_content/privacy_policy`);
  };

  submitPrivacyPolicyContent = async (payload) => {
    return await axiosInstance.post(`/api/store_content/privacy_policy`, payload);
  };


}

const instance = new StaticPageService();

export default instance;
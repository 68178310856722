import React from "react";
import AliianceManagerLayout from "../../../components/layouts/AliianceManagerLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import VendorDetails from "../../../Containers/VendorDetails/VendorDetails";

const VendorDetailsPage = () => {
  return (
    <AliianceManagerLayout>
      <AdminPageContainer>
        <VendorDetails />
      </AdminPageContainer>
    </AliianceManagerLayout>
  );
};

export default VendorDetailsPage;

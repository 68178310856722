import React, { useContext, useEffect, useState } from "react";
import { DocumentMasterContext, spl_format } from ".";
import { useQuery } from "react-query";
import DocumentMasterServices from "../../../services/API/DocumentMaster";
import LicenseConfigureServices from "../../../services/API/LicenseConfiguration";
import LicenseMasterServices from "../../../services/API/LicenseMaster";
import { toast } from "react-toastify";
import { Modal, Form, Button, Offcanvas } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import Multiselect from "multiselect-react-dropdown";
import AdminPageHeader from "../../../components/AdminPageHeader";
import AddDoc from "./AddDoc";
import FullScreenLoader from "../../../components/FullScreenLoader";
import Dropzone from "react-dropzone";
import Filter from "./Filter";

export const validateDocument = (data) => {
  if (!data.name) return "Please enter document name";
  if (data.name.length <= 1 || data.name.length > 500) {
    return "Please enter valid input with 3-500 characters";
  }
  if (data?.sample_format?.size > 2097152)
    return "Please ensure that the image size does not exceed 2MB";
  // if (!spl_format.test(data.name))
  //   return "Special characters are not allowed in name";

  if (data.accepted_format.length === 0)
    return "Please select accepted document formats";
};
export default function DocumentPanel() {
  const value = useContext(DocumentMasterContext);
  const queryKey = "api/document-list";
  const [format, setFormat] = useState([]);
  const [name, setName] = useState(null);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [page, setPage] = useState(1);
  const [del, setDel] = useState(false);
  const [file, setFile] = useState(null);
  const [myData, setMyData] = useState([]);
  const [exported, setExported] = useState("");
  const [filter, setFilter] = useState(false);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const [loader, setLoader] = useState("");
  const postsPerPage = 20;

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const {
    data: documentLists,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery(
    [queryKey, { name, format, search: searchkey }],
    DocumentMasterServices.getDocumentList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const handleDelete = async (document) => {
    const status = document.status === 1 ? 0 : 1;
    try {
      await DocumentMasterServices.deactivateDocument(document?.id, {
        status,
      });
      const message =
        document?.status === 1
          ? "Document Deactivated Successfully"
          : "Document Activated Successfully";
      toast.success(message);
      setDel(null);
      refetch();
    } catch (er) {
      toast.error(er);
    }
  };

  const handleExport = async () => {
    try {
      const resp = await DocumentMasterServices.exportDocument();

      if (resp) {
        const url = window.URL.createObjectURL(new Blob([resp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Document-Masters.csv");
        toast.success("Document Masters Downloaded Successfully");
        link.click();
      }
    } catch (e) {
    }
  };

  const handleSampleExport = async () => {
    try {
      const resp = await LicenseMasterServices.downloadSampleReport({bulk_sample_file:"document-master"});

      if (resp) {
        const url = window.URL.createObjectURL(new Blob([resp]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Sample-Document-Masters.csv");
        toast.success("Document Masters Bulk Upload Template Downloaded Successfully");
        link.click();
      }
    } catch (e) {
    }
  };


  const handleEdit = async (document) => {
    try {
      const documentData = await DocumentMasterServices.editDocument(
        document?.id
      );
      value.setShow("document");
      setEdit(documentData?.data);
    } catch (er) {
      toast.error(er);
    }
  };

  const handleBulkUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    setLoader("Bulk Upload");
    const response = await DocumentMasterServices.bulkUpload(formData);
    setLoader("");
    refetch();
  };

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setName(null);
    setFormat([]);
    setMyData(documentLists?.data);
    setPage(1)
  };

  const handleHide = () => {
    setFilter(false);
  };
  const matchWithUnderscore = (text) => {
    const pattern = /_(.*)$/g;
    const match = pattern.exec(text);
    if (match) {
      return match[1];
    } else {
      return text;
    }
  };

  const DocumentRow = ({ document, index }) => {
    return (
      <tr key={document?.id}>
        <td className="text-start align-middle">
          {postsPerPage * (page - 1) + index + 1}
        </td>
        <td className="text-start align-middle">{document?.name}</td>
        <td className="text-start align-middle">
          {document?.original_filename &&
          document?.original_filename !== "NA" ? (
            <a
              href={document?.image_url}
              style={{ color: "blue" }}
              target="_blank"
            >
              <i className="fa fa-fw fa-paperclip"></i>

              {document?.original_filename}
            </a>
          ) : (
            <span>NA</span>
          )}
        </td>

        <td className="text-start align-middle">{document.accepted_format}</td>
        <td className="text-start align-middle">
          <a title="Edit" onClick={() => handleEdit(document)}>
            <i className="fa fa-edit fa-fw"></i>
          </a>

          {document?.status === 1 ? (
            <i
              className="fa fa-circle fa-fw txt-success pe-auto"
              style={{
                cursor: "pointer",
              }}
              title="Deactivate"
              onClick={() => setDel(document)}
            ></i>
          ) : (
            <i
              className="fa fa-circle fa-fw txt-fail"
              title="Activate"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setDel(document)}
            ></i>
          )}
        </td>
      </tr>
    );
  };
  return (
    <div className="row">
      {loader === "Bulk Upload" ? (
        <FullScreenLoader
          show={loader === "Bulk Upload"}
          title="Uploading File"
        />
      ) : (
        ""
      )}
      <div className="">
        <div className="card card-sm card-shadow mb-3">
          <div className="card-body">
            <div className=" d-flex justify-content-between align-items-center">
              <AdminPageHeader title="Document Masters" />
              <div className="d-flex justify-content-end align-items-center gap-2 pt-4">
              <button
                  className=" button_new pull-right"
                  title="Download Sample"
                  onClick={handleSampleExport}
                >
                  Download Sample
                </button>

                {searchkey || format?.length > 0 || name ? (
                  <button
                    type="button"
                    className="button_new pull-right"
                    onClick={clearFilter}
                  >
                    Clear Filters
                  </button>
                ) : (
                  ""
                )}
                {displaySearchField ? (
                  <input
                    type="text"
                    className="button_new pull-right "
                    value={searchkey}
                    onChange={(e) => {
                      setSearchkey(e.target.value)
                      setPage(1)
                    }}
                    // disabled={workflow === "expert"}
                    placeholder="Search"
                  />
                ) : (
                  <a
                    className=" button_new pull-right"
                    onClick={() => {
                      setDisplaySearchField(true);
                    }}
                    title="Search"
                  >
                    <i
                      class="fa fa-search requests-icons"
                      aria-hidden="true"
                    ></i>
                  </a>
                )}

                <button
                  className=" button_new pull-right"
                  title="Show Filters"
                  onClick={() => setFilter(true)}
                >
                  <i className="fa fa-filter fa-fw requests-icons"></i>
                </button>
                <button
                  className=" button_new pull-right"
                  title="Download"
                  onClick={handleExport}
                >
                  <i className="fa fa-download fa-fw requests-icons"></i>
                </button>

                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleBulkUpload(acceptedFiles[0]);
                  }}
                  acceptedFile={[".csv"]}
                  maxFiles={1}
                  // maxSize={10}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      // className="card card-bordered col-sm-5 cursor-pointer"
                      {...getRootProps()}
                      style={{ cursor: "pointer" }}
                    >
                      <input {...getInputProps()} />
                      <label
                        style={{ marginTop: "-25px" }}
                        className=" button_new pull-right"
                        title="Bulk Upload"
                        htmlFor="file"
                      >
                        <i className="fa fa-upload fa-fw requests-icons"></i>{" "}
                      </label>
                    </div>
                  )}
                </Dropzone>

                <a
                  className=" button_new pull-right"
                  onClick={() => value.setShow("document")}
                  title="Add Document"
                >
                  <i className="fa fa-plus fa-fw requests-icons"></i>
                </a>
              </div>
            </div>
            {filter ? (
              <Filter
                filter={filter}
                handleHide={handleHide}
                name={name}
                setName={setName}
                format={format}
                setFormat={setFormat}
                setPage={setPage}
              />
            ) : (
              ""
            )}

            <div className="table-responsive">
              <table className="table table-bordered table-hover dataTable no-footer">
                <thead>
                  <tr role="row" style={{ fontWeight: 700 }}>
                    <th className="text-center" width="34px">
                      S. No.
                    </th>
                    <th className="text-center">Name</th>
                    <th className="text-center">Sample Format</th>
                    {/* <th className="text-center" width="12%">
                      Status
                    </th> */}
                    <th className="text-center">Accepted File Format</th>
                    <th className="text-center" width="50px">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {documentLists?.data?.length > 0 ? (
                    documentLists?.data
                      ?.slice(indexOfFirstPost, indexOfLastPost)
                      ?.map((document, index) => (
                        <DocumentRow document={document} index={index} />
                      ))
                  ) : (
                    <tr>
                      <td colSpan={5} className="p-2 text-center">
                        {loading || isFetching
                          ? "Loading..."
                          : "No records found"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {documentLists?.data?.length > postsPerPage && (
                <Pagination
                  activePage={page}
                  itemsCountPerPage={postsPerPage}
                  totalItemsCount={documentLists?.data?.length}
                  pageRangeDisplayed={postsPerPage}
                  onChange={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to {del?.status === 1 ? "deactivate " : "activate "}
            this document?
          </p>
          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <AddDoc
        editData={edit}
        setEdit={setEdit}
        filter={filter}
        refetch={refetch}
        clearFilter={clearFilter}
       
        
      />
    </div>
  );
}

import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { AuthContext } from "../../../context/authContext";
import { useNavigate } from "react-router";
import Breadcrumb from "../../../components/common/Breadcrumb";
import AdminPageCard from "../../../components/common/AdminPageCard/AdminPageCard";
import AdminPageHeader from "../../../components/AdminPageHeader";
import Multiselect from "../../../components/common/MultiSelect";
import { useQuery } from "react-query";
import VendorExecutiveServices from "../../../services/API/VendorExecutive";
import VendorManagerServices from "../../../services/API/VendorManager";
import Loader from "../../../components/Loader";
import FieldExecutiveServices from "../../../services/API/FieldExecutive";
import { toast } from "react-toastify";
import { validateEmail } from "../../../utils/utils";

const AddFE = () => {
  const [FEData, setFEData] = useState({
    executives: [],
    name: "",
    email: "",
    contact: "",
    is_active: true,
    address: "",
    regions: [],
    states: [],
    cities: [],
    municipals: [],
    industryTypes: [],
    licenseTypes: [],
  });
  const [editMode, setEditmode] = useState(false);
  const user = useContext(AuthContext);
  const navigate = useNavigate();
  const [loader, setLoader] = useState("");
  const auth = useContext(AuthContext);
  const breadcrumbs = [
    {
      title: "Home",
      link: "executive/dashboard",
      isActive: true,
    },
    {
      title: "Field Executives",
      link: "/executive/field-executives",
      isActive: true,
    },
    {
      title: "Add New",
      // link: "/vendor/field-executives",
      isActive: false,
    },
  ];

  const queryKeys = [
    "/api/get_zone",
    "/api/get_states",
    "/api/city_filter",
    "/api/get_municipal_details",
    "/api/license/license-industry",
    "/api/license/license-type",
  ];

  const { data: executiveList } = useQuery(
    ["/api/executive"],
    VendorExecutiveServices.getExecutives,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { data: regionList } = useQuery(
    [queryKeys[0]],
    VendorManagerServices.getRegions,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: statesList } = useQuery(
    [queryKeys[1], {}],
    VendorManagerServices.getAllStates,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: cityList } = useQuery(
    [queryKeys[2], { state_id: FEData?.states?.map((item) => item?.id) }],
    VendorManagerServices.filterCity,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: municipalList } = useQuery(
    [queryKeys[3], { city_id: FEData?.cities?.map((item) => item?.id) }],
    VendorManagerServices.getAllMunicipal,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: industryList } = useQuery(
    [queryKeys[4]],
    VendorManagerServices.getAllIndustry,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: licenseTypeList } = useQuery(
    [queryKeys[5]],
    VendorManagerServices.getLicenseClassification,
    {
      refetchOnWindowFocus: false,
    }
  );

  const validate = () => {
    if (!FEData?.name) return "Please Enter name";
    if (FEData?.name?.length > 500)
      return "Name cannot be longer than 500 characters";
    if (!FEData?.email) return "Please enter email address";
    if (!validateEmail(FEData?.email))
      return "Please eneter valid email address";
    if (String(FEData?.contact)?.length !== 10)
      return "Please enter valid contact No";
    if (!FEData?.address) return "Please enter address";
    if (FEData?.address?.length > 500)
      return "Address cannot be longer than 500 characters";
    if (!FEData?.regions?.length) return "Please Select at least one region";
    if (!FEData?.states?.length) return "Please Select at least one state";
    if (!FEData?.cities?.length) return "Please Select at least one city";
    if (!FEData?.municipals?.length)
      return "Please Select at least one municipal corporation";
    if (!FEData?.industryTypes?.length)
      return "Please Select at least one  Industry Type";
    if (!FEData?.licenseTypes?.length)
      return "Please Select at least one  license Type";
  };

  const addFE = async () => {
    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }
    let payload = {
      executives: [auth?.userId],
      name: FEData?.name,
      mobile_no: FEData?.contact,
      address: FEData?.address,
      is_enabled: FEData?.is_active,
      regions: FEData?.regions?.map((item) => item?.id),
      states: FEData?.states?.map((item) => item?.id),
      cities: FEData?.cities?.map((item) => item?.id),
      municipals: FEData?.municipals?.map((item) => item?.id),
      licenseTypes: FEData?.licenseTypes?.map((item) => item?.id),
      industryTypes: FEData?.industryTypes?.map((item) => item?.id),
      created_by: user?.userId,
    };
    // if (editMode && FEData?.email !== FEDetails?.email) {
    //   payload.email = FEData?.email;
    // } else {
    if (!editMode) payload.email = FEData?.email;
    // }
    if (editMode) {
      //   setLoader("edit");
      //   const resp = await FieldExecutiveServices.UpdateFE(
      //     FEDetails?.id,
      //     payload
      //   );
      //   setLoader("");
      //   if (resp) {
      //     toast.success("Field executive updated successfully");
      //     navigate("/vendor/field-executives");
      //   }
    } else {
      setLoader("add");
      const resp = await FieldExecutiveServices.AddFEByExecutive(payload);
      setLoader("");
      if (resp) {
        toast.success("Field executive added successfully");
        navigate("/vendor/field-executives");
      }
    }
  };

  return (
    <div>
      <div className="main" id="main">
        <div id="content" className="content">
          <Breadcrumb items={breadcrumbs} />
          <AdminPageCard>
            <AdminPageHeader
              title={
                editMode ? "Update Field Executive" : "Add Field Executive"
              }
            />
            <form className="smart-form">
              <fieldset>
                <div className="row">
                  <section className="col col-3">
                    <label className="label">
                      Enter Name<span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="input">
                      <input
                        type="text"
                        className="input-sm"
                        placeholder="Name"
                        value={FEData?.name}
                        onChange={(e) =>
                          setFEData((prevState) => {
                            return {
                              ...prevState,
                              name: e.target.value,
                            };
                          })
                        }
                      />
                    </label>
                  </section>
                  <section className="col col-3">
                    <label className="label">
                      Email ID<span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="input">
                      <input
                        type="text"
                        className="input-sm"
                        placeholder="Email"
                        value={FEData?.email}
                        onChange={(e) =>
                          setFEData((prevState) => {
                            return {
                              ...prevState,
                              email: e.target.value,
                            };
                          })
                        }
                      />
                    </label>
                  </section>
                  <section className="col col-3">
                    <label className="label">
                      Mobile Number<span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="input">
                      <input
                        type="number"
                        className="input-sm"
                        placeholder="Mobile Number"
                        value={FEData?.contact}
                        onChange={(e) =>
                          setFEData((prevState) => {
                            return {
                              ...prevState,
                              contact: e.target.value,
                            };
                          })
                        }
                      />
                    </label>
                  </section>
                  <section className="col col-3 pb-5">
                    <label className="label">Is Active?</label>
                    <label className="toggle">
                      <input
                        type="checkbox"
                        name="checkbox-toggle"
                        checked={FEData?.is_active}
                        onChange={(e) =>
                          setFEData((prevState) => {
                            return {
                              ...prevState,
                              is_active: !FEData?.is_active,
                            };
                          })
                        }
                      />
                      <i
                        data-swchon-text="YES"
                        data-swchoff-text="NO"
                        style={{ right: "unset" }}
                      ></i>
                    </label>
                  </section>
                </div>
                <div className="row">
                  <section className="col col-9">
                    <label className="label">
                      Address<span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="input">
                      <input
                        type="text"
                        className="input-sm"
                        value={FEData?.address}
                        onChange={(e) =>
                          setFEData((prevState) => {
                            return {
                              ...prevState,
                              address: e.target.value,
                            };
                          })
                        }
                      />
                    </label>
                  </section>
                </div>
                <div className="row">
                  <section className="col col-xxl-3 col-md-6 col-sm-12">
                    <label className="label">Select Region</label>
                    <label className="select">
                      <Multiselect
                        options={regionList?.data}
                        value={FEData?.regions}
                        onChange={(selectedOption) =>
                          setFEData((prevState) => {
                            return {
                              ...prevState,
                              regions: selectedOption,
                            };
                          })
                        }
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        placeholder="Regions"
                        isOptionDisabled={(option) =>
                          option?.status === "Inactive"
                        }
                      />
                    </label>
                  </section>
                  <section className="col col-xxl-3 col-md-6 col-sm-12">
                    <label className="label">
                      Select State<span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="select">
                      <Multiselect
                        options={statesList?.data}
                        value={FEData?.states}
                        onChange={(selectedOption) =>
                          setFEData((prevState) => {
                            return {
                              ...prevState,
                              states: selectedOption,
                              cities: [],
                              municipals: [],
                            };
                          })
                        }
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        placeholder="States"
                        isOptionDisabled={(option) =>
                          option?.status === "Inactive"
                        }
                      />
                    </label>
                  </section>
                  <section className="col col-xxl-3 col-md-6 col-sm-12">
                    <label className="label">
                      Select City<span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="select">
                      <Multiselect
                        options={cityList?.data}
                        value={FEData?.cities}
                        onChange={(selectedOption) =>
                          setFEData((prevState) => {
                            return {
                              ...prevState,
                              cities: selectedOption,
                              municipals: [],
                            };
                          })
                        }
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        placeholder="Cities"
                        isOptionDisabled={(option) =>
                          option?.status === "Inactive"
                        }
                      />
                    </label>
                  </section>

                  <section className="col col-xxl-3 col-md-6 col-sm-12">
                    <label className="label">
                      Select Muncipal Corporation
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <label className="select">
                      <Multiselect
                        options={municipalList?.data}
                        value={FEData?.municipals}
                        onChange={(selectedOption) =>
                          setFEData((prevState) => {
                            return {
                              ...prevState,
                              municipals: selectedOption,
                            };
                          })
                        }
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        placeholder="Muncipals"
                        isOptionDisabled={(option) =>
                          option?.status === "Inactive"
                        }
                      />
                    </label>
                  </section>
                </div>
              </fieldset>
              <fieldset>
                <h3>Area of Expertise</h3>
                <br />
                <div className="row">
                  <section className="col col-4">
                    <label className="label">
                      Select Industry Type
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Multiselect
                      options={industryList?.data}
                      value={FEData?.industryTypes}
                      onChange={(selectedOption) =>
                        setFEData((prevState) => {
                          return {
                            ...prevState,
                            industryTypes: selectedOption,
                          };
                        })
                      }
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      placeholder="Industry Types"
                      isOptionDisabled={(option) =>
                        option?.status === "Inactive"
                      }
                    />
                  </section>

                  <section className="col col-4">
                    <label className="label">
                      Select License Type
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Multiselect
                      options={licenseTypeList?.data}
                      value={FEData?.licenseTypes}
                      onChange={(selectedOption) =>
                        setFEData((prevState) => {
                          return {
                            ...prevState,
                            licenseTypes: selectedOption,
                          };
                        })
                      }
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      placeholder="Industry Types"
                      isOptionDisabled={(option) =>
                        option?.status === "Inactive"
                      }
                    />
                  </section>
                </div>
              </fieldset>
              <footer>
                <button
                  type="button"
                  className="btn button_new button_new_success"
                  onClick={() => addFE()}
                >
                  {loader ? <Loader /> : editMode ? "Update" : "Save"}
                </button>
              </footer>
            </form>
          </AdminPageCard>
        </div>
      </div>
    </div>
  );
};

export default AddFE;

import React from "react";
import AliianceManagerLayout from "../../../components/layouts/AliianceManagerLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import EditVendor from "../../../Containers/EditVendor";

const EditVendorPage = () => {
  return (
    <AliianceManagerLayout>
      <AdminPageContainer>
        <EditVendor />
      </AdminPageContainer>
    </AliianceManagerLayout>
  );
};

export default EditVendorPage;

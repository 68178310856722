import React from "react";
import Cities from "./Cities";
import MuncipalCorporation from "./MuncipalCorporation";
import States from "./States";
import ZonesRegions from "./ZonesRegions";
import { Tabs, Tab } from "react-bootstrap";
import AdminPageHeader from "../../../../components/AdminPageHeader";

export default function LocationTabs() {
  return (
    <div className="row">
      <div className="">
        <div className="card card-sm card-shadow mb-3 ">
          <div className="card-body">
            <div>
              <AdminPageHeader title="Location Settings" />
              <Tabs
                defaultActiveKey="zone"
                transition={false}
                unmountOnExit={true}
                mountOnEnter={true}
                className=" bordered p-0 m-0"
              >
                <Tab
                  eventKey="zone"
                  title="Zones/Regions"
                  tabClassName="rounded-0 text-decoration-none"
                >
                  <ZonesRegions />
                </Tab>
                <Tab
                  eventKey="state"
                  title="States"
                  tabClassName="rounded-0 text-decoration-none"
                >
                  <States />
                </Tab>
                <Tab
                  eventKey="city"
                  title="Cities"
                  tabClassName="rounded-0 text-decoration-none"
                >
                  <Cities />
                </Tab>
                <Tab
                  eventKey="municipal"
                  title="Municipal Corporations"
                  tabClassName="rounded-0 text-decoration-none"
                >
                  <MuncipalCorporation />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

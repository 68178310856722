import React, { useContext } from "react";
import AdminPageHeader from "../../../components/AdminPageHeader";
import AdminPageCard from "../../../components/common/AdminPageCard/AdminPageCard";
import Breadcrumb from "../../../components/common/Breadcrumb";
import VendorExecutiveServices from "../../../services/API/VendorExecutive";
import VendorManagerServices from "../../../services/API/VendorManager";
import FieldExecutiveServices from "../../../services/API/FieldExecutive";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import Multiselect from "../../../components/common/MultiSelect";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import { validateEmail } from "../../../utils/utils";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../context/authContext";

const AddFE = ({ FEDetails, breadcrumbs }) => {
  const [FEData, setFEData] = useState({
    executives: [],
    name: "",
    email: "",
    contact: "",
    is_active: true,
    address: "",
    regions: [],
    states: [],
    cities: [],
    municipals: [],
    industryTypes: [],
    licenseTypes: [],
  });

  const [editMode, setEditmode] = useState(false);
  const user = useContext(AuthContext);
  const navigate = useNavigate();
  const [loader, setLoader] = useState("");
  const [regionActive, setRegionActive] = useState(false);

  const vendorBreadcrumbs = [
    {
      title: "Home",
      link: "vendor/dashboard",
      isActive: true,
    },
    {
      title: "Field Executives Lists",
      link: "/vendor/field-executives",
      isActive: true,
    },
    {
      title: editMode ? "Update Field Executive" : "Add New Field Executive",
      // link: "/vendor/field-executives",
      isActive: false,
    },
  ];

  const queryKeys = [
    "/api/get_zone",
    "/api/get_states",
    "/api/city_filter",
    "/api/get_municipal_details",
    "/api/license/license-industry",
    "/api/license/license-type",
  ];

  const { data: executiveList } = useQuery(
    ["/api/executive"],
    VendorExecutiveServices.getExecutives,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const { data: regionList } = useQuery(
    [queryKeys[0], { active: 1 }],
    VendorManagerServices.getRegions,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: allStateList } = useQuery(
    [queryKeys[1], { active: 1 }],
    VendorManagerServices.getAllStates,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: statesList } = useQuery(
    [
      queryKeys[2],
      { zone_id: FEData?.regions?.map((item) => item?.id), active: 1 },
    ],

    VendorManagerServices.filterState,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: cityList } = useQuery(
    [
      queryKeys[3],
      { state_id: FEData?.states?.map((item) => item?.id), active: 1 },
    ],
    VendorManagerServices.filterCity,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: municipalList } = useQuery(
    [
      queryKeys[4],
      { city_id: FEData?.cities?.map((item) => item?.id), active: 1 },
    ],
    VendorManagerServices.getAllMunicipal,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: industryList } = useQuery(
    [queryKeys[5], { active: 1 }],
    VendorManagerServices.getAllIndustry,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: licenseTypeList } = useQuery(
    [queryKeys[6], { active: 1 }],
    VendorManagerServices.getLicenseClassification,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getStates = () => {
    const stateList = [];
    FEData.states?.forEach((state) => {
      let status = FEData.regions.some((item) => {
        if (item.id !== "all") {
          if (item.id === state.zone_id || item.name === state.zone_name) {
            return true;
          } else {
            return false;
          }
        }
      });

      if (status) {
        stateList.push(state);
      }
    });

    if (stateList.length > 0) {
      setFEData({ ...FEData, states: stateList });
    } else {
      setFEData({ ...FEData, states: [] });
    }
  };

  useEffect(() => {
    if (regionActive) {
      getStates();
    }
  }, [FEData.regions]);

  useEffect(() => {
    const citiesList = [];
    FEData?.cities?.forEach((city) => {
      let status = FEData?.states?.some((item) => {
        if (item.id === city.state_id || item.name === city.state_name) {
          return true;
        } else {
          return false;
        }
      });

      if (status) {
        citiesList.push(city);
      }
    });
    if (citiesList?.length > 0) {
      setFEData({ ...FEData, cities: citiesList });
    } else {
      setFEData({ ...FEData, cities: [] });
    }
  }, [FEData.states]);

  useEffect(() => {
    const municipalsList = [];
    FEData.municipals.forEach((municipl) => {
      let status = FEData.cities.some((item) => {
        if (item.id !== "all") {
          if (
            item.id === municipl.city_id ||
            item.name === municipl.city_name
          ) {
            return true;
          } else {
            return false;
          }
        }
      });
      if (status) {
        municipalsList.push(municipl);
      }
    });

    if (municipalsList.length > 0) {
      setFEData({ ...FEData, municipals: municipalsList });
    } else {
      setFEData({ ...FEData, municipals: [] });
    }
  }, [FEData.cities]);

  useEffect(() => {
    if (FEDetails) {
      setFEData({
        executives: FEDetails?.executives,
        name: FEDetails?.name,
        contact: FEDetails?.mobile_no,
        email: FEDetails?.email,
        isActive: !!FEDetails?.is_enabled,
        address: FEDetails?.address,
        regions: FEDetails?.zones,
        states: FEDetails?.states,
        cities: FEDetails?.cities,
        municipals: FEDetails?.municipals,
        industryTypes: FEDetails?.industry_types,
        licenseTypes: FEDetails?.license_types,
      });
      setEditmode(true);
    }
  }, []);

  const validate = () => {
    if (!Object.keys(FEData?.executives).length > 0)
      return "Please select an executive";
    if (!FEData?.name) return "Please enter field executive name";
    if (FEData?.name?.length < 5 || FEData?.name?.length > 500)
      return "Please enter valid input with 5-500 characters";
    if (!FEData?.email) return "Please enter field executive e-mail ID";
    if (!validateEmail(FEData?.email)) return "Please enter valid email ID";
    if (!FEData?.contact) return "Please enter mobile number";
    if (parseInt(FEData.contact) < 999999999)
      return "Please enter valid 10 digit mobile number";
    if (String(FEData?.contact)?.length !== 10)
      return "Please enter valid 10 digit mobile number";
    if (!FEData?.address) return "Please enter address";
    if (FEData?.address?.length < 5 || FEData?.address?.length > 500)
      return "Please enter valid address with 5-500 characters";
    // if (!FEData?.regions?.length) return "Please select at least one region";
    if (!FEData?.states?.length) return "Please select at least one state";
    if (!FEData?.cities?.length) return "Please select at least one city";
    if (!FEData?.municipals?.length)
      return "Please select at least one municipal corporation";
    if (!FEData?.industryTypes?.length)
      return "Please select at least one  Industry Type";
    if (!FEData?.licenseTypes?.length)
      return "Please select at least one  license Type";
  };

  const addFE = async () => {
    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }
    let payload = {
      executives: FEData?.executives?.id,
      name: FEData?.name,
      mobile_no: FEData?.contact,
      address: FEData?.address,
      is_enabled: FEData?.is_active,
      regions: FEData?.regions?.map((item) => item?.id),
      states: FEData?.states?.map((item) => item?.id),
      cities: FEData?.cities?.map((item) => item?.id),
      municipals: FEData?.municipals?.map((item) => item?.id),
      licenseTypes: FEData?.licenseTypes?.map((item) => item?.id),
      industryTypes: FEData?.industryTypes?.map((item) => item?.id),
    };
    if (editMode && FEData?.email !== FEDetails?.email) {
      payload.email = FEData?.email;
    } else {
      if (!editMode) payload.email = FEData?.email;
    }
    if (!editMode) {
      payload.created_by = user?.userId;
    }
    if (editMode) {
      setLoader("edit");
      const resp = await FieldExecutiveServices.UpdateFE(
        FEDetails?.id,
        payload
      );
      setLoader("");
      if (resp) {
        toast.success("Field executive updated successfully");
        navigate(-1);
      }
    } else {
      setLoader("add");
      const resp = await FieldExecutiveServices.addFE(payload);
      setLoader("");
      if (resp) {
        toast.success("Field executive added successfully");
        navigate(-1);
      }
    }
  };
  return (
    <div id="main" role="main">
      <div id="content">
        <Breadcrumb items={breadcrumbs || vendorBreadcrumbs} />
        <AdminPageCard>
          <AdminPageHeader
            title={editMode ? "Update Field Executive" : "Add Field Executive"}
          />
          <form className="smart-form">
            <fieldset>
              <div className="row">
                <section className="col col-5">
                  <label className="label">
                    Executives<span style={{ color: "red" }}> *</span>
                  </label>
                  <Multiselect
                    options={executiveList?.data?.data?.filter(
                      (item) => item.is_enabled === 1
                    )}
                    value={FEData?.executives}
                    onChange={(selectedOption) =>
                      setFEData((prevState) => {
                        return {
                          ...prevState,
                          executives: selectedOption,
                        };
                      })
                    }
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    placeholder="Select Executive"
                    isOptionDisabled={(option) => !option.is_enabled}
                    isMulti={false}
                  />
                </section>
              </div>
              <div className="row">
                <section className="col col-5">
                  <label className="label">
                    Name<span style={{ color: "red" }}> *</span>
                  </label>
                  <label className="input">
                    <input
                      type="text"
                      className="input-sm"
                      placeholder="Enter Field Executive Name"
                      value={FEData?.name}
                      onChange={(e) =>
                        setFEData((prevState) => {
                          return {
                            ...prevState,
                            name: e.target.value,
                          };
                        })
                      }
                    />
                  </label>
                </section>
                <section className="col col-4">
                  <label className="label">
                    Email ID<span style={{ color: "red" }}> *</span>
                  </label>
                  <label className="input">
                    <input
                      type="text"
                      className="input-sm"
                      placeholder="Enter Email"
                      value={FEData?.email}
                      onChange={(e) =>
                        setFEData((prevState) => {
                          return {
                            ...prevState,
                            email: e.target.value,
                          };
                        })
                      }
                    />
                  </label>
                </section>
                <section className="col col-3">
                  <label className="label">
                    Mobile Number<span style={{ color: "red" }}> *</span>
                  </label>
                  <label className="input">
                    <input
                      type="text"
                      className="input-sm"
                      placeholder="Enter Mobile Number"
                      value={FEData?.contact}
                      maxLength={10}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        setFEData((prevState) => {
                          return {
                            ...prevState,
                            contact: value,
                          };
                        });
                      }}
                    />
                  </label>
                </section>
              </div>
              <div className="row">
                <section className="col col-9">
                  <label className="label">
                    Address<span style={{ color: "red" }}> *</span>
                  </label>
                  <label className="input">
                    <input
                      type="text"
                      className="input-sm"
                      placeholder="Enter Address"
                      value={FEData?.address}
                      onChange={(e) =>
                        setFEData((prevState) => {
                          return {
                            ...prevState,
                            address: e.target.value,
                          };
                        })
                      }
                    />
                  </label>
                </section>
                <section className="col col-3 pb-5">
                  <label className="label">Is Active?</label>
                  <label className="toggle">
                    <input
                      type="checkbox"
                      name="checkbox-toggle"
                      checked={FEData?.is_active}
                      onChange={(e) =>
                        setFEData((prevState) => {
                          return {
                            ...prevState,
                            is_active: !FEData?.is_active,
                          };
                        })
                      }
                    />
                    <i
                      data-swchon-text="YES"
                      data-swchoff-text="NO"
                      style={{ right: "unset" }}
                    ></i>
                  </label>
                </section>
              </div>
            </fieldset>
            <fieldset>
              <h3>Regions Operated</h3>
              <br />
              <div className="row">
                <section className="col col-xxl-3 col-md-6 col-sm-12">
                  <label className="label">Regions</label>
                  <label className="select">
                    <Multiselect
                      options={regionList?.data}
                      value={FEData?.regions}
                      onChange={(selectedOption) => {
                        setRegionActive(true);
                        setFEData((prevState) => {
                          return {
                            ...prevState,
                            regions: selectedOption,
                          };
                        });
                      }}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      placeholder="Select Regions"
                    />
                  </label>
                </section>
                <section className="col col-xxl-3 col-md-6 col-sm-12">
                  <label className="label">
                    States<span style={{ color: "red" }}> *</span>
                  </label>
                  <label className="select">
                    <Multiselect
                      options={
                        FEData.regions?.length > 0
                          ? statesList?.data
                          : allStateList?.data
                      }
                      value={FEData?.states}
                      onChange={(selectedOption) =>
                        setFEData((prevState) => {
                          return {
                            ...prevState,
                            states: selectedOption,
                          };
                        })
                      }
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      placeholder="Select States"
                    />
                  </label>
                </section>
                <section className="col col-xxl-3 col-md-6 col-sm-12">
                  <label className="label">
                    Cities<span style={{ color: "red" }}> *</span>
                  </label>
                  <label className="select">
                    <Multiselect
                      options={cityList?.data}
                      value={FEData?.cities}
                      onChange={(selectedOption) =>
                        setFEData((prevState) => {
                          return {
                            ...prevState,
                            cities: selectedOption,
                          };
                        })
                      }
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      placeholder="Select Cities"
                    />
                  </label>
                </section>

                <section className="col col-xxl-3 col-md-6 col-sm-12">
                  <label className="label">
                    Municipal Corporations
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <label className="select">
                    <Multiselect
                      options={municipalList?.data}
                      value={FEData?.municipals}
                      onChange={(selectedOption) =>
                        setFEData((prevState) => {
                          return {
                            ...prevState,
                            municipals: selectedOption,
                          };
                        })
                      }
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      placeholder="Select Municipal Corporations"
                    />
                  </label>
                </section>
              </div>
            </fieldset>

            <fieldset>
              <h3>Area of Expertise</h3>
              <br />
              <div className="row">
                <section className="col col-4">
                  <label className="label">
                    Industry Types<span style={{ color: "red" }}> *</span>
                  </label>
                  <Multiselect
                    options={industryList?.data}
                    value={FEData?.industryTypes}
                    onChange={(selectedOption) =>
                      setFEData((prevState) => {
                        return {
                          ...prevState,
                          industryTypes: selectedOption,
                        };
                      })
                    }
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    placeholder="Select Industry Types"
                  />
                </section>

                <section className="col col-4">
                  <label className="label">
                    License Types<span style={{ color: "red" }}> *</span>
                  </label>
                  <Multiselect
                    options={licenseTypeList?.data}
                    value={FEData?.licenseTypes}
                    onChange={(selectedOption) =>
                      setFEData((prevState) => {
                        return {
                          ...prevState,
                          licenseTypes: selectedOption,
                        };
                      })
                    }
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    placeholder="Select License Types"
                  />
                </section>
              </div>
            </fieldset>
            <footer>
              <button
                type="button"
                className="btn button_new button_new_success"
                onClick={() => addFE()}
              >
                {loader ? <Loader /> : editMode ? "Update" : "Save"}
              </button>
            </footer>
          </form>
        </AdminPageCard>
      </div>
    </div>
  );
};

export default AddFE;

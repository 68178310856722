import React from "react";
import VendorLayout from "../../../../components/layouts/VendorLayout";
import AdminPageContainer from "../../../../components/AdminPageContainer";
import AddFE from "../../../../Containers/Vendor/AddFE/AddFE";

const AddFEPage = () => {
  return (
    <VendorLayout>
      <AdminPageContainer>
        <AddFE />
      </AdminPageContainer>
    </VendorLayout>
  );
};

export default AddFEPage;

import React from "react";
import axiosInstance from "../axiosConfig";

class EndUserDashboardServices extends React.Component {
  getsrnListByUserId = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    return await axiosInstance.post(`/api/srn_byId`, data);
  };
}

const instance = new EndUserDashboardServices();

export default instance;

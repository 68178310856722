import React from "react";
import VendorLayout from "../../../../components/layouts/VendorLayout";
import FieldExecutive from "../../../../Containers/Vendor/FieldExecutive";

const FieldExecutivePage = () => {
  return (
    <VendorLayout>
      <FieldExecutive />
    </VendorLayout>
  );
};

export default FieldExecutivePage;

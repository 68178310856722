import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import axiosInstance, { apiBaseUrl, getAccessToken } from "../axiosConfig";

class UploadServices extends React.Component {
  getSRN = async (data = {}) => {
    return await axiosInstance.post(`/api/store_service_details`, data);
  };

  getDocumentList = async (data) => {
    return await axiosInstance.get(`/api/user/documents`, {
      params: data,
    });
  };

  // uploadDocs = async (data) => {
  //   axiosInstance.defaults.headers.put["Content-Type"] = "multipart/form-data";
  //   try{
  //     return await axiosInstance.post(`/api/user/documents/upload`, data);
  //   }catch(error){
  //     console.log(error)
  //   }
  // };

  uploadDocs = async (data) => {
    // axiosInstance.defaults.headers.put["Content-Type"] = "multipart/form-data";
    let token = getAccessToken();
    let res = null;
    try {
      res = await axios.post(`${apiBaseUrl}/api/user/documents/upload`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      });
    } catch (error) {
      if (
        error?.response?.data?.errors["files.1"][0] ||
        error?.response?.data?.errors["files.2"][0]
      ) {
        toast.error(
          error?.response?.data?.errors["files.1"][0] ||
            error?.response?.data?.errors["files.2"][0]
        );
      }
    }

    return res;
  };
}

const instance = new UploadServices();

export default instance;

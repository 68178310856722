import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { LocationSettingsContext } from "..";
import LocationSettingsServices from "../../../../services/API/LocationSettings";
import { toast } from "react-toastify";
import { validateCity } from "../locationTabs/Cities";
export default function AddCity({ refetch, editData, setEdit, clearFilter,searchkey,handleSearch,applyFilter,filtersAvailable }) {
  const { show, setShow } = useContext(LocationSettingsContext);
  const [name, setName] = useState(null);
  const [states, setStates] = useState([]);
  const [zones, setZones] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [zoneId, setZoneId] = useState("");
  useEffect(() => {
    const getStates = async () => {
      const { data } = await LocationSettingsServices.getActiveState();
      setStates(data);
    };
    const getZones = async () => {
      const { data } = await LocationSettingsServices.getActiveZone();
      setZones(data);
    };
    getStates();
    getZones();
  }, []);

  useEffect(() => {
    if (editData) {
      setName(editData?.name);
      setStateId(editData?.state_id);
      // setZoneId(editData?.zone_id);
    }
  }, [editData]);
  const addCity = async () => {
    // const payload = { city_name: name, zone_id: zoneId, state_id: stateId };
    const payload = { city_name: name, state_id: stateId };
    const error = validateCity(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LocationSettingsServices.storeCity(payload);
      if (response) {
        toast.success("City created successfully");
        clearFilter();
        refetch();
        handleHide();
      }
    } catch (e) {
      toast.error(e);
    }
  };
  const updateCity = async () => {
    // const payload = { city_name: name, zone_id: zoneId, state_id: stateId };
    const payload = { city_name: name, state_id: stateId };
    const error = validateCity(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LocationSettingsServices.updateCity(
        editData?.id,
        payload
      );
      if (response) {
        toast.success("City updated successfully");
        // clearFilter();
        handleHide();
        if(filtersAvailable){
          applyFilter(1)
        }else if(searchkey){
          handleSearch({target:{value:searchkey}},1)
        }else{
          refetch();
        }
        setEdit(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const submitForm = () => {
    if (editData) {
      updateCity();
    } else {
      addCity();
    }
  };

  const handleHide = () => {
    setShow(null);
    setName(null);
    setStateId(null);
    // setZoneId("");
    setEdit(null);
  };
  return (
    <Modal show={show === "city"} animation={false} onHide={handleHide}>
      <Modal.Header closeButton>
        <h3 className="modal-title">{editData ? "Edit City" : "Add City"}</h3>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="form-group ">
          <label htmlFor="tags">Select Zone</label>
          <select
            value={zoneId}
            onChange={(e) => setZoneId(e.target.value)}
            className="form-control full-width"
          >
            <option value="">Choose Zone</option>
            {zones?.map((zd) => (
              <option key={zd?.id} value={zd?.id}>
                {zd.zone_name}
              </option>
            ))}
          </select>{" "}
          <i></i>{" "}
        </div> */}
        <div className="form-group padding-top-10">
          <label htmlFor="tags">
            State<span style={{ color: "red" }}> *</span>
          </label>
          <select
            value={stateId}
            onChange={(e) => setStateId(e.target.value)}
            className="form-control full-width"
          >
            <option value="">Select State</option>
            {states?.map((st) => (
              <option key={st?.id} value={st?.id}>
                {st.state_name}
              </option>
            ))}
          </select>{" "}
          <i></i>{" "}
        </div>

        <div className="form-group padding-top-10">
          <label htmlFor="tags">
            City<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter City Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={submitForm}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

import React from "react";
import ExecutiveLayout from "../../../../components/layouts/ExecutiveLayout";
import AdminPageContainer from "../../../../components/AdminPageContainer";
import AddFE from "../../../../Containers/Executive/AddFE/AddFE";

const AddFEPage = () => {
  return (
    <ExecutiveLayout>
      <AdminPageContainer>
        <AddFE />
      </AdminPageContainer>
    </ExecutiveLayout>
  );
};

export default AddFEPage;

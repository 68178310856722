import React, { useEffect, useState } from "react";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import CreateLicense from "../../../components/CreateLicense/CreateLicense";
import { useQuery } from "react-query";
import LicenseMasterServices from "../../../services/API/LicenseMaster";
import { useParams } from "react-router";
const EditLicensePage = () => {
  const queryKey = "/api/license-mapping";
  const { id } = useParams();
  const {
    data: licenseData,
    isLoading: loading,
    isSuccess: success,
    refetch,
  } = useQuery([queryKey, { id }], LicenseMasterServices.getLicenseById, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });



  return (
    <AdminLayout>
      <AdminPageContainer>
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : (
          licenseData?.data[0] && (
            <CreateLicense licenseEditData={licenseData?.data[0]} />
          )
        )}
      </AdminPageContainer>
    </AdminLayout>
  );
};

export default EditLicensePage;

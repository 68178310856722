export const ROLES = {
  systemAdmin: 1,
  allianceManager: 5,
  endUser: 6,
  vendor: 4,
  executive: 2,
  fieldExecutive: 3,
};
export const ROLE_NAME = {
  1: "System Admin",
  5: "Alliance Manager",
  6: "User",
  4: "Vendor",
  2: "Executive",
  3: "Field Executive",
};

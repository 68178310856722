import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { LicenseConfigureContext } from "..";
import LicenseConfigurationServices from "../../../../services/API/LicenseConfiguration";
import { toast } from "react-toastify";
import { validate } from "../licenceTabs/LicenceTypes";
export default function AddLicenceType({
  refetch,
  editData,
  setEdit,
  clearFilter,
  filtersAvailable,
  applyFilter,
  searchkey,
  handleSearch
}) {
  const { show, setShow } = useContext(LicenseConfigureContext);
  const [name, setName] = useState(null);
  const [desc, setDesc] = useState(null);
  useEffect(() => {
    if (editData) {
      setName(editData?.name);
      setDesc(editData?.description);
    }
  }, [editData]);

  const updateLicence = async () => {
    const payload = { name, description: desc };
    const error = validate(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const response = await LicenseConfigurationServices.updateLicenceType(
        editData?.id,
        payload
      );

      if (response) {
        toast.success("Licence Type updated successfully");
        // clearFilter();
        if(filtersAvailable){
          applyFilter(1)
        }else if(searchkey){
          handleSearch({target:{value:searchkey}},1)
        }else{
          refetch();
        }
        handleHide();
        setEdit(null);
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const addLicense = async () => {
    const payload = { name, description: desc };
    const error = validate(payload);
    if (error) {
      toast.error(error);
      return;
    }
    try {
      const resp = await LicenseConfigurationServices.addLicenseType(payload);
      if (resp) {
        toast.success("Licence Type created successfully");
        clearFilter();
        refetch();
        handleHide();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const submitForm = () => {
    if (editData) {
      updateLicence();
    } else {
      addLicense();
    }
  };
  const handleHide = () => {
    setShow(null);
    setName(null);
    setDesc(null);
    setEdit(null);
  };

  return (
    <Modal show={show === "licenseType"} animation={false} onHide={handleHide}>
      <Modal.Header closeButton>
        <h3 className="modal-title">
          {editData ? "Edit License Type" : "Add License Type"}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            License Type Name<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Enter Licesnse Type Name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tags">
            {" "}
            Description<span style={{ color: "red" }}> *</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Description"
            required
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn button_new_success"
          onClick={submitForm}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

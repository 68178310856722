import React from 'react'
import AdminLayout from '../../../components/layouts/adminLayout'
import AdminPageContainer from '../../../components/AdminPageContainer'
import PaymentManager from "../../../Containers/PaymentManagement";

const PaymentTransactions = () => {
  return (
    <AdminLayout>
        <AdminPageContainer>
        <PaymentManager />
        </AdminPageContainer>
    </AdminLayout>
  )
}

export default PaymentTransactions
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AdminLayout from "../../components/layouts/adminLayout";
import AuthServices from "../../services/authServices";
import { useNavigate, useParams } from "react-router";
import { checkPassword } from "../../utils/utils";
import { useQuery } from "react-query";
import FullScreenLoader from "../../components/FullScreenLoader";
import { Button, ButtonGroup } from "react-bootstrap";
import AdminPageHeader from "../../components/AdminPageHeader";
import { Link } from "react-router-dom";

export default function Reset() {
  const [resetData, setResetData] = useState({
    confirm: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [errors, setErrors] = useState({});
  const params = useParams();

  const { isLoading, isError, error, data, isSuccess } = useQuery(
    [`api/reset-password-link-verify`, { token: params.accessToken }],
    AuthServices.checkResetPassword,
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const handleChange = (event) => {
    setResetData((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };
  const navigate = useNavigate();

  const validate = () => {
    const error = {};

    if (!resetData?.password) {
      error.password = "Please enter your new password";
      // return error;
    } else if (
      resetData?.password.length < 8 ||
      resetData?.password.length > 16
    ) {
      error.password =
        "Please enter valid password with atleast 8 characters with one lower case, one upper case, one number and one special character";
      // return error;
    } else if (!checkPassword(resetData?.password)) {
      error.password =
        "Please enter valid password with atleast 8 characters with one lower case, one upper case, one number and one special character";
      // return error;
    }
    if (!resetData?.confirm) {
      error.confirm = "Please re-enter your new password";
      // return error;
    } else if (resetData?.password !== resetData?.confirm) {
      error.confirm = "Entered passwords do not match";
      // return error;
    }

    return error;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    setLoading(true);
    const response = await AuthServices.reset({
      password: resetData.password,
      password_confirmation: resetData?.confirm,
      token: params.accessToken,
    });
    if (response) {
      toast.success("Reset password successfully");
      navigate("/admin/login");
    }
    setLoading(false);
  };

  return (
    <AdminLayout sideMenu={false}>
    
        <div
          className="d-flex  justify-content-center h-100 align-items-center"
          style={{
            background: "url(/img/screen-background.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          {isLoading ? (
            <FullScreenLoader show={isLoading} title="Verifying Link" />
          ) : isError ? (
            <div className="col-lg-4">
              <div className="card card-lg">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-sm-12 mb-4 mb-sm-0 d-flex flex-column">
                        <AdminPageHeader
                          title=" This link is either not valid or expired. Please
                        click below to request a new link."
                        />
                        <ButtonGroup>
                          <Button>
                            <Link to="/forgot" className="text-decoration-none">
                              Request a new link
                            </Link>
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-lg-4">
              <div className="card card-lg">
                <div className="card-body">
                  <h2 className="mb-4">Reset Password</h2>
                  <form>
                    <div className="row">
                      <div className="col-sm-12 mb-4 mb-sm-0">
                        <div className=" mb-5">
                          <label
                            className="form-label font-md"
                            htmlFor="contactsFormWorkEmail"
                          >
                            New Password
                            <span style={{ color: "red" }}> *</span>
                          </label>
                          <div className="position-relative">
                            <input
                              type={
                                isPasswordShow == "password"
                                  ? "text"
                                  : "password"
                              }
                              className="form-control eyeOne"
                              name="password"
                              placeholder="Enter new password"
                              id="contactsFormWorkEmail"
                              aria-label="email@site.com"
                              onChange={handleChange}
                            />
                            <i
                              className={`position-absolute hover:cursor-pointer fa ${
                                isPasswordShow == "password"
                                  ? "fa-eye"
                                  : "fa-eye-slash"
                              }`}
                              style={{
                                right: "10px",
                                top: "30%",
                              }}
                              // onClick={() => setIsPasswordShow(!isPasswordShow)}
                              onMouseOver={() => setIsPasswordShow("password")}
                              onMouseOut={() => setIsPasswordShow(false)}
                            ></i>
                          </div>
                          {errors?.password && (
                            <div className="text-danger mt-1 mb-2">
                              {errors?.password}
                            </div>
                          )}
                        </div>

                        {/* <div className="position-relative mb-5"> */}
                        <label
                          className="form-label font-md"
                          htmlFor="contactsFormCompany"
                        >
                          Confirm Password
                          <span style={{ color: "red" }}> *</span>
                        </label>
                        <div className="position-relative">
                          <i
                            className={` position-absolute hover:cursor-pointer fa ${
                              isPasswordShow == "confirm"
                                ? "fa-eye"
                                : "fa-eye-slash"
                            }`}
                            style={{
                              right: "10px",
                              top: "30%",
                            }}
                            // onClick={() => setIsPasswordShow(!isPasswordShow)}
                            onMouseOver={() => setIsPasswordShow("confirm")}
                            onMouseOut={() => setIsPasswordShow(false)}
                          ></i>
                          <input
                            type={
                              isPasswordShow == "confirm" ? "text" : "password"
                            }
                            className="form-control eyeOne"
                            name="confirm"
                            id="confirm"
                            placeholder="Enter confirm password"
                            onChange={handleChange}
                          />
                        </div>
                        {errors?.confirm && (
                          <div className="text-danger mb-3 mt-1">
                            {errors?.confirm}
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                    </div>

                    <div className="d-grid gap-4 ">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg mt-5"
                        onClick={!loading ? submitForm : () => {}}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      
    </AdminLayout>
  );
}

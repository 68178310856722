import React from "react";
import ExecutiveLayout from "../../../../components/layouts/ExecutiveLayout";
import AdminPageContainer from "../../../../components/AdminPageContainer";
import FEListing from "../../../../Containers/Executive/FEListing";

const FEListingPage = () => {
  return (
    <ExecutiveLayout>
      <AdminPageContainer>
        <FEListing />
      </AdminPageContainer>
    </ExecutiveLayout>
  );
};

export default FEListingPage;

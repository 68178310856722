import React from "react";
import VendorLayout from "../../../../components/layouts/VendorLayout";
import AdminPageContainer from "../../../../components/AdminPageContainer";
import AddFE from "../../../../Containers/Vendor/AddFE/AddFE";
import FieldExecutiveServices from "../../../../services/API/FieldExecutive";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import Loader from "../../../../components/Loader";

const EditFEPage = ({ breadcrumbToshow }) => {
  const { id } = useParams();
  const {
    data: FEData,
    isFetching,
    isLoading,
  } = useQuery(
    [`/api/executive/executive_byId/${id}`, id],
    FieldExecutiveServices.FEDetails,
    {
      refetchOnWindowFocus: false,
    }
  );

  const alliancebreadcrumb = [
    {
      title: "Home",
      link: "/alliance-manager/dashboard",
      isActive: true,
    },
    {
      title: "Vendors",
      link: "/alliance-manager/vendors",
      isActive: true,
    },
    {
      title: "Update Field Executive",
      // link: "/vendor/field-executives",
      isActive: false,
    },
  ];
  return (
    <VendorLayout>
      <AdminPageContainer>
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <AddFE
            FEDetails={FEData?.data[0]}
            breadcrumbs={
              breadcrumbToshow === "alliance-manager"
                ? alliancebreadcrumb
                : null
            }
          />
        )}
      </AdminPageContainer>
    </VendorLayout>
  );
};

export default EditFEPage;

import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import LicenseConfigurationServices from "../../../../services/API/LicenseConfiguration";
import { toast } from "react-toastify";
const ADLIcenseDetail = ({ show, onHide, selectedITem, refetch }) => {
  const [loader, setLoader] = useState("");

  const updateDetailsStatus = async () => {
    let payload = { is_enabled: selectedITem?.is_enabled == 0 ? "1" : "0" };
    setLoader("adItem");
    const resp = await LicenseConfigurationServices?.updateLicenseDetailStatus(
      selectedITem?.id,
      payload
    );
    setLoader("");
    if (resp) {
      toast.success(
        `License Details ${
          selectedITem?.is_enabled == "0" ? "activated" : "deactivated"
        } successfully`
      );
      refetch();
      onHide();
    }
  };
  return (
    <Modal show={show} onHide={onHide} animation={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Are you Sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Do you want to{" "}
          {selectedITem?.is_enabled == 0 ? "activate " : "deactivate "}
          this License Details?
        </p>
        <div className="d-flex gap-3 justify-content-end">
          <Button
            variant="danger"
            className="px-3 py-2"
            onClick={onHide}
            disabled={loader === "adItem"}
          >
            No
          </Button>
          <Button
            variant="success"
            className="px-3 py-2"
            onClick={() => updateDetailsStatus()}
            disabled={loader === "adItem"}
          >
            {loader === "adItem" ? "loading" : "Yes"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ADLIcenseDetail;

import React from "react";
import CustomModal from "../../components/common/CustomModal";
import { toast } from "react-toastify";
import { useState } from "react";
import VendorManagerServices from "../../services/API/VendorManager";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader";

const RejectVendor = ({ show, onClose, id }) => {
  const [remarks, setRemarks] = useState("");
  const [loader, setLoader] = useState("");
  const navigate = useNavigate();

  const rejectRequest = async () => {
    if (!remarks?.trim()) {
      toast.error("Please enter Remarks");
      return;
    }
    setLoader("rejecting");
    const resp = await VendorManagerServices.updateVendorRequest(id, 3, remarks);
    setLoader("");
    if (resp) {
      toast.success("Request Rejected");
      navigate("/alliance-manager/vendors");
    }
  };
  return (
    <CustomModal
      show={show}
      modalSize="md"
      onClose={!!loader ? () => {} : onClose}
      headerTitle={
        <h3 class="modal-title">
          Vendor Request : <span class="txt-fail">Rejected</span>
        </h3>
      }
      footer={
        <div className="d-flex justify-content-end px-3 py-5 border">
          <button
            type="button"
            class="btn button_new_success"
            onClick={rejectRequest}
            disabled={!!loader}
          >
            {loader === "rejecting" ? (
              <div>
                <Loader />
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      }
    >
      <div>
        <div className="text-lg pb-3">Enter Remarks For Rejection</div>
        <textarea
          rows={5}
          className="w-100"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        ></textarea>
      </div>
    </CustomModal>
  );
};

export default RejectVendor;

import React, { useEffect } from "react";
import { HeadProvider, Link } from "react-head";
import AdminHeader from "../../Headers/AdminHeader";
import AllianceManagerMenu from "../../SideMenus/AllianceManagerMenu";
import AdminLayout from "../adminLayout";

const AliianceManagerLayout = ({
  children,
  sideMenu = true,
  vendor,
  test,
  paymentType,
  account,
}) => {
  return <AdminLayout sideMenu={sideMenu}>{children}</AdminLayout>;
};

export default AliianceManagerLayout;

import React from "react";
import { Modal } from "react-bootstrap";
const CustomModal = ({
  children,
  headerTitle = null,
  show,
  onClose,
  onSubmit,
  modalSize,
  onCloseText = "Cancel",
  onSubmitText = "Submit",
  footer = null,
}) => {
  return (
    <Modal
      show={show}
      animation={false}
      onHide={() => onClose()}
      size={modalSize}
      centered
    >
      {headerTitle && (
        <Modal.Header closeButton>
          <h3 className="modal-title">{headerTitle}</h3>
        </Modal.Header>
      )}
      <Modal.Body style={{ height: "auto", overflow: "auto" }}>
        {children}
      </Modal.Body>
      {footer || (
        <Modal.Footer>
          <button
            type="button"
            style={{background:"green",color:"white"}}
            onClick={onClose}
            className="btn mt-0 button_new"
          >
            {onCloseText}
          </button>
          <button
            style={{background:"red",color:"white"}}
            onClick={onSubmit}
            type="button"
            className="btn mt-0 button_new"
          >
            {onSubmitText}
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;

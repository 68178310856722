import React, { createContext, useState } from "react";
import LicenseTabs from "./licenceTabs";
import AddCategories from "./Modals/AddCategories";
import AddCompanyType from "./Modals/AddCompanyType";
import AddIndustryType from "./Modals/AddIndustryType";
import AddLicenceType from "./Modals/AddLicenceType";
import AddLicenses from "./Modals/AddLicenses";
import AddStatus from "./Modals/AddStatus";
import AddSubStatus from "./Modals/AddSubStatus";
import AdminLayout from "../../../components/layouts/adminLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import "./licenceConfigureStyles.css";
import { useNavigate } from "react-router";
export const LicenseConfigureContext = createContext(null);
export const spl_format = /^[^0-9]+$/;
export default function LicenceConfigure() {
  const [show, setShow] = useState(null);
  const navigate = useNavigate();
  return (
    <AdminLayout test3="active">
      <AdminPageContainer>
        <LicenseConfigureContext.Provider value={{ show, setShow }}>
          <div id="main" role="main">
            <div id="content">
              <div>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a
                      style={{
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/system-admin/license-master")}
                    >
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active">
                    License Configuration Settings
                  </li>
                </ol>
              </div>
              <LicenseTabs />
            </div>
          </div>
        </LicenseConfigureContext.Provider>
      </AdminPageContainer>
    </AdminLayout>
  );
}

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OOOchart from "./OOOchart";
import "../../styles/vendor/graphmodal.css";
import moment from "moment";
import VendorExecutiveServices from "../../services/API/VendorExecutive";
import { useState } from "react";
import { useEffect } from "react";
export default function OOOGraph({
  show,
  handleClose,
  data,
  flag,
  changeYear,
}) {
  const [graphData, setGraphData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [today, setToday] = useState(new Date());

  const navigate = useNavigate();
  const handleGraph = async (year) => {
    const payload = { id: data, date: year };
    const response = await VendorExecutiveServices.graphOOO(payload);
    if (response) {
      setGraphData(response?.data);
    }
    return response?.data;
  };

  const monthNames = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];
  const ChangeData = async (day) => {
    let f_year;
    if (moment(day).year() === moment(today).year()) {
      if (moment(today).format("MM") <= 3) {
        f_year = moment(today).subtract(1, "years").format("YYYY");
      } else {
        f_year = moment(day).format("YYYY");
      }
    } else {
      f_year = moment(day).format("YYYY");
    }
    const filtered_data = await handleGraph(f_year);
    const newData = monthNames.map((month, index) => {
      const existingMonth = filtered_data?.find(
        (item) => item?.month === month
      );
      if (existingMonth) {
        return existingMonth;
      } else {
        return {
          leave_count: 0,
          month: month,
          numeric_month: index + 1,
        };
      }
    });
    setCurrentData(newData);
  };

  useEffect(() => {
    if (data) {
      ChangeData(today);
    }
  }, [data]);

  const scrollerGraph = () => {
    const day = moment(today).subtract(1, "years");
    ChangeData(day);
    setToday(day);
  };
  const scrollerGraph1 = () => {
    const day = moment(today).add(1, "years");
    ChangeData(day);
    setToday(day);
  };
  return (
    <>
      <Modal
        className="m-body"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Out of Office Trend</Modal.Title>
        </Modal.Header>
        <h4 className="d-flex justify-content-center">
          FY-{moment(today).year()}-{moment(today).add(1, "years").format("YY")}
        </h4>
        <Modal.Body>
          <OOOchart
            data={currentData}
            height={400}
            flag={flag}
            scrollerGraph={scrollerGraph}
            scrollerGraph1={scrollerGraph1}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={async () => {
              await localStorage.setItem("graphData", JSON.stringify(data));
              if (flag == "executive") {
                navigate("/executive/enlarged-graphs");
              } else {
                navigate("/vendor/enlarged-graphs");
              }
            }}
          >
            Click to Enlarge
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

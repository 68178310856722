import React from "react";
import axiosInstance from "../axiosConfig";

class VendorExecutive extends React.Component {
  getExecutives = async (query) => {
    // const [_key, queryParams] = queryKey;
    return await axiosInstance.get(`/api/executive`, { params: query });
  };
  searchExecutive = async (data) => {
    return await axiosInstance.get(`/api/executive/search_executive`, {
      params: data,
    });
  };
  // {
  //   name: data?.name,
  //   email: data?.email,
  //   mobile_no: data.mobile_no,
  // }

  exportExecutives = async () => {
    return await axiosInstance.get(`/api/executive/export_executive`);
  };

  getExecutiveById = async (id) => {
    return await axiosInstance.get(`/api/executive/edit/${id}`);
  };

  deactivateExecutive = async (id, payload) => {
    return await axiosInstance.post(
      `/api/executive/update/status/${id}`,
      payload
    );
  };

  createExecutive = async (payload) => {
    return await axiosInstance.post(`/api/executive/add`, payload);
  };
  updateExecutive = async (id, payload) => {
    return await axiosInstance.post(`/api/executive/update/${id}`, payload);
  };
  filterExecutive = async (data) => {
    return await axiosInstance.get(`/api/executive`, {
      params: data,
    });
  };

  getOOOExecutives = async (body) => {
    return await axiosInstance.post(`/api/executive/office-settings`, body);
  };

  getExecutiveList = async (params) => {
    return await axiosInstance.get(`/api/executive-list`, { params });
  };
  createOOO = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/office-settings/save`,
      payload
    );
  };
  graphOOO = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/office-settings/total-leaves-per-month`,
      payload
    );
  };

  exportOOO = async (payload) => {
    return await axiosInstance.post(
      "/api/executive/office-settings/csv-download",
      payload
    );
  };
  getMyLeaveHistory = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/office-settings/my-leave-history`,
      payload
    );
  };

  totalLeavesPerMonth = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/office-settings/total-leaves-per-month`,
      payload
    );
  };

  isOutOfOffice = async (payload) => {
    return await axiosInstance.post(
      `/api/executive/office-settings/out_of_office`,
      payload
    );
  };
}

const instance = new VendorExecutive();

export default instance;

import React, { useState, memo, useEffect } from "react";
import { Button, Modal, Offcanvas } from "react-bootstrap";
//-------------css import ----------------
import "../../../styles/alliance-manager/licenseRequestsFilter.css";
//--------------------api handler------------------
import AllianceManagerServices from "../../../services/API/AllianceManager";
import LicenseMasterServices from "../../../services/API/LicenseMaster";

//-------------third party library-------
import format from "date-fns/format";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRangeComponent from "../LicenseRequestDetails/DateRangeComponent";
import { toast } from "react-toastify";
import { multiSelectStyle } from "../../CreateExecutive/MultiSelectStyle";
const LicenseRequestsFilter = ({
  openFilterBox,
  setOpenFilterBox,
  toBeFiltered,
  setToBeFiltered,
  handleApplyFilter,
  loading,
  initialStateToBeFiltered,
  vendorList,
  statusList,
  subStatusList,
  licenseTypeList,
  stateList,
  cityList,
  licenseNameList,
  requestedByList,
  categoryList,
  companyTypeList,
  industryTypeList,
  showDuedateRange,
  setShowDuedateRange,
  showReqDateRange,
  setShowReqDateRange,
  isLoading,
}) => {
  const {
    fastTrackRequests,
    requestType,
    vendor,
    status,
    subStatus,
    assignedTo,
    state,
    cities,
    category,
    companyTypes,
    industryTypes,
    dueDateRange,
    requestedDateRange,
    dateSubmit,
  } = toBeFiltered;

  const [openDueDateCalendar, setOpenDueDateCalendar] = useState(false);
  const [openRequestDateCalendar, setOpenRequestDateCalendar] = useState(false);

  function decode(str) {
    if (str.length > 23) return str.slice(0, 23) + "...";
    else return str;
  }

  return (
    <Offcanvas
      show={openFilterBox}
      onHide={() => {
        // setToBeFiltered(initialStateToBeFiltered);
        setOpenFilterBox(false);
      }}
      placement="end"
      // style={{height:'100vh'}}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h3>Filter </h3>
        </Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className="modall_body scrollBody">
        {loading && (
          <div
            style={{
              paddingLeft: "0px",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              zIndex: "999",
            }}
          >
            <div className="text-center" style={{ position: "fixed" }}>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        )}
        <div className="row filter-box pt-3  filterScroll">
          <div className="form-check form-switch track-switch mb-3 ">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              onClick={() =>
                setToBeFiltered({
                  ...toBeFiltered,
                  fastTrackStatus: true,
                  ["fastTrackRequests"]: !fastTrackRequests,
                })
              }
              checked={fastTrackRequests ? true : false}
            />
            <span>Show Fast Track Requests</span>
          </div>
          <div className="container-fluid col-sm-12   ">
            <div className="py-2">
              <span>Type Of Request </span>

              <Multiselect
                options={licenseTypeList}
                isObject={false}
                // displayValue="name"
                placeholder={
                  toBeFiltered.requestType?.length > 0
                    ? ""
                    : "Select type of request "
                }
                onSelect={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["requestType"]: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["requestType"]: selectedList,
                  });
                }}
                selectedValues={toBeFiltered.requestType}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>

            <div className="py-2">
              <span>State</span>

              <Multiselect
                options={stateList}
                isObject={false}
                placeholder={
                  toBeFiltered.state.length > 0 ? "" : "Select state"
                }
                onSelect={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["state"]: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["state"]: selectedList,
                  });
                }}
                selectedValues={toBeFiltered.state}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>

            <div className="py-2">
              <span>City</span>

              <Multiselect
                options={cityList}
                isObject={false}
                // displayValue="name"
                disable={!cityList?.length > 0}
                placeholder={
                  toBeFiltered.cities.length > 0 ? "" : "Select city"
                }
                onSelect={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["cities"]: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["cities"]: selectedList,
                  });
                }}
                selectedValues={toBeFiltered.cities}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>

            <div className="py-2">
              <span>License</span>

              <Multiselect
                options={licenseNameList}
                isObject={false}
                placeholder={
                  toBeFiltered?.license?.length > 0 ? "" : "Select license"
                }
                onSelect={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["license"]: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["license"]: selectedList,
                  });
                }}
                selectedValues={toBeFiltered.license}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>

            <div className="py-2">
              <span>Assigned To</span>

              <Multiselect
                isObject={false}
                placeholder="Select assigned to"
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>
            {/* -----------------Due Date Range----------------- */}

            <div className="py-2">
              <span>Due Date Range</span>
              <input
                value={
                  showDuedateRange
                    ? `${format(
                        dueDateRange[0].startDate,
                        "dd-MM-yy"
                      )} to ${format(dueDateRange[0].endDate, "dd-MM-yy")}`
                    : ""
                }
                placeholder="Select Due Date Range"
                readOnly
                className="inputBox"
                onClick={() => setOpenDueDateCalendar((open) => !open)}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  outline: "none",
                  padding: "5px",
                }}
              />
              {openDueDateCalendar && (
                <DateRangeComponent
                  setOpenCalendar={setOpenDueDateCalendar}
                  openCalendar={openDueDateCalendar}
                  //  onChange={(date) =>{
                  setShowReqDateRange={setShowReqDateRange}
                  setShowDuedateRange={setShowDuedateRange}
                  isDueDateComponent={true}
                  toBeFiltered={toBeFiltered}
                  setToBeFiltered={setToBeFiltered}
                  //  }
                />
              )}
            </div>

            {/* -------------------------Requested Date --------------------------- */}
            <div className="py-2">
              <span>Requested Date</span>

              <input
                value={
                  showReqDateRange
                    ? `${format(
                        requestedDateRange[0]?.startDate,
                        "dd-MM-yy"
                      )} to ${format(
                        requestedDateRange[0]?.endDate,
                        "dd-MM-yy"
                      )}`
                    : ""
                }
                placeholder="Select Requested Date Range"
                readOnly
                className="inputBox"
                onClick={() => setOpenRequestDateCalendar((open) => !open)}
                style={{
                  width: "100%",
                  height: "33px",
                  border: "1px solid #ccc",
                  outline: "none",
                  borderRadius: "4px",
                  padding: "5px",
                }}
              />
              {openRequestDateCalendar && (
                <DateRangeComponent
                  setOpenCalendar={setOpenRequestDateCalendar}
                  openCalendar={openRequestDateCalendar}
                  //  onChange={(date) =>{
                  setShowReqDateRange={setShowReqDateRange}
                  setShowDuedateRange={setShowDuedateRange}
                  isDueDateComponent={false}
                  toBeFiltered={toBeFiltered}
                  setToBeFiltered={setToBeFiltered}
                  //  }
                />
              )}
            </div>
            {/* -----------------Requested By----------------- */}
            <div className="py-2">
              <span>Requested By</span>

              <Multiselect
                options={requestedByList}
                isObject={false}
                placeholder={
                  toBeFiltered.requestedBy.length > 0
                    ? ""
                    : "Select Requested By"
                }
                onSelect={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["requestedBy"]: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["requestedBy"]: selectedList,
                  });
                }}
                selectedValues={toBeFiltered.requestedBy}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>
            {/* -----------------Status----------------- */}

            <div className="py-2">
              <span>Status</span>

              <Multiselect
                options={statusList}
                isObject={false}
                placeholder={
                  toBeFiltered.status.length > 0 ? "" : "Select status"
                }
                onSelect={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["status"]: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["status"]: selectedList,
                  });
                }}
                selectedValues={toBeFiltered.status}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>
            {/* -----------------Sub Status----------------- */}

            <div className="py-2">
              <span>Sub Status</span>

              <Multiselect
                options={subStatusList}
                isObject={false}
                disable={!subStatusList?.length > 0}
                placeholder={
                  toBeFiltered.subStatus.length > 0 ? "" : "Select sub status"
                }
                onSelect={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["subStatus"]: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["subStatus"]: selectedList,
                  });
                }}
                selectedValues={toBeFiltered.subStatus}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>

            {/* -----------------Vendor----------------- */}

            <div className="py-2">
              <span>Vendor</span>

              <Multiselect
                options={vendorList}
                isObject={false}
                placeholder={
                  toBeFiltered.vendor.length > 0 ? "" : "Select vendor"
                }
                onSelect={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["vendor"]: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["vendor"]: selectedList,
                  });
                }}
                selectedValues={toBeFiltered.vendor}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>

            {/* -----------------Industry Type----------------- */}
            <div className="py-2">
              <span>Industry Types</span>

              <Multiselect
                options={industryTypeList}
                isObject={false}
                placeholder={
                  toBeFiltered.industryTypes.length > 0
                    ? ""
                    : "Select industry types"
                }
                onSelect={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["industryTypes"]: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["industryTypes"]: selectedList,
                  });
                }}
                selectedValues={toBeFiltered.industryTypes}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>
            {/* ----------------Company Type----------------- */}

            <div className="py-2">
              <span>Company Types</span>

              <Multiselect
                options={companyTypeList}
                isObject={false}
                placeholder={
                  toBeFiltered.companyTypes.length > 0
                    ? ""
                    : "Select company types"
                }
                onSelect={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["companyTypes"]: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["companyTypes"]: selectedList,
                  });
                }}
                selectedValues={toBeFiltered.companyTypes}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>
            {/* ----------------Menu----------------- */}

            <div className="py-2">
              <span>Menu</span>

              <Multiselect
                options={categoryList}
                isObject={false}
                placeholder={
                  toBeFiltered.category.length > 0 ? "" : "Select menu"
                }
                onSelect={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["category"]: selectedList,
                  });
                }}
                onRemove={(selectedList, selectedItem) => {
                  setToBeFiltered({
                    ...toBeFiltered,
                    ["category"]: selectedList,
                  });
                }}
                selectedValues={toBeFiltered.category}
                className="dropdown"
                style={multiSelectStyle}
                selectedValueDecorator={(str) => decode(str)}
              />
            </div>
          </div>
        </div>
        <section
          className="pull-right m-4"
          onClick={() => {
            handleApplyFilter(true);
          }}
        >
          <a className="btn button_new_success p-3">Apply</a>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default memo(LicenseRequestsFilter);

import React, { useContext, useState, useEffect } from "react";
import { LicenseConfigureContext, spl_format } from "..";
import { useQuery } from "react-query";
import LicenseConfigureServices from "../../../../services/API/LicenseConfiguration";
import LocationSettingsServices from "../../../../services/API/LocationSettings";
import { toast } from "react-toastify";
import { Modal, Button, Offcanvas } from "react-bootstrap";
import Pagination from "react-js-pagination";
import AddLicenceType from "../Modals/AddLicenceType";
import { CSVLink } from "react-csv";
export const validate = (data) => {
  if (!data.name) return "Please enter license type";
  if (!data.description) return "Please enter description";
  if (data.name.length < 1 || data.name.length > 500) {
    return "Please enter valid name with 1-500 characters";
  }
  if (data.description.length < 1 || data.description.length >= 500)
    return "Please enter valid description with 1-500 characters";
  if (!spl_format.test(data.name))
    return "Please enter only alphabets in the name field";
  if (!spl_format.test(data.description))
    return "Please enter only alphabets in the description field";
};
export default function LicenceTypes() {
  const value = useContext(LicenseConfigureContext);
  const queryKey = "api/license/license-type";
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(null);
  const [id, setId] = useState(null);
  const [description, setDescription] = useState(null);
  const [page, setPage] = useState(1);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const [myData, setMyData] = useState([]);
  const [exported, setExported] = useState("");
  const postsPerPage = 20;
  const [del, setDel] = useState(false);
  const [filter, setFilter] = useState(false);
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const {
    data: licenseTypeList,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery([queryKey], LicenseConfigureServices.getLicenseTypeList, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : licenseTypeList?.data?.slice(indexOfFirstPost, indexOfLastPost);
  useEffect(() => {
    if (licenseTypeList?.data) {
      setMyData(licenseTypeList?.data);
    }
  }, [licenseTypeList]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  const handleDelete = async (license) => {
    const status = license.status === 1 ? 0 : 1;
    try {
      const response = await LicenseConfigureServices.deactivateLicenseType(
        license?.id,
        {
          status,
        }
      );
      if (response) {
        const message =
          license?.status === 1
            ? "License Type Deactivated Successfully"
            : "License Type Activated Successfully";
        toast.success(message);
        setDel(null);
        if( name || description){
          applyFilter(page)
        }else if(searchkey){
          handleSearch({target:{value:searchkey}},page)
        }else{
          refetch();
        }
      }
    } catch (er) {
      toast.error(er);
    }
  };

  const handleEdit = async (license) => {
    try {
      const licenseData = await LicenseConfigureServices.editLicenseType(
        license?.id
      );
      value.setShow("licenseType");
      setId(license?.id);
      setEdit(licenseData?.data);
    } catch (er) {
      toast.error(er);
    }
  };

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setMyData(licenseTypeList?.data);
    setName(null);
    setDescription(null);
    setPage(1)
  };
  const handleSearch = async (e,page) => {
    const value = e.target.value;
    setSearchkey(value);

    const payload = { name: value };
    const { data } = await LicenseConfigureServices.searchLicenseType(payload);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if(!page){
      setPage(1);
    }
    setMyData(data);
  };

  const handleHide = () => {
    setFilter(false);
  };
  const applyFilter = async (page) => {
    const payload = { name, description };
    const error = Object.values(payload).filter((dt) => dt !== null);
    if (!error.length) {
      toast.error("Please fill out the fields");
      return;
    }
    try {
      const response = await LicenseConfigureServices.filterLicenseType(
        payload
      );

      if (response?.data) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        setMyData(response?.data);
        if(!page){
          setPage(1);
        }
        setFilter(false);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const getExported = async () => {
    let response = await LicenseConfigureServices.exportLicenseType();
    response?.forEach((element, index) => {
      response[index]["S. No"] = index + 1;
    });
    setExported(response ? response : []);
  };

  useEffect(() => {
    getExported();
  }, [licenseTypeList]);

  return (
    <div style={{ borderTop: "0px", paddingTop: "30px" }}>
      <div
        style={{
          paddingTop: "30px",
        }}
        className="d-flex justify-content-end align-items-center gap-2 p-2"
      >
        {licenseTypeList?.data.length !== myData?.length && (
          <button
            type="button"
            className="button_new pull-right"
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        )}
        {displaySearchField ? (
          <input
            type="text"
            className="button_new pull-right "
            value={searchkey}
            onChange={handleSearch}
            // disabled={workflow === "expert"}
            placeholder="Search"
          />
        ) : (
          <a
            className=" button_new pull-right"
            onClick={() => {
              setDisplaySearchField(true);
            }}
          >
            <i
              class="fa fa-search requests-icons"
              aria-hidden="true"
              title="Search"
            ></i>
          </a>
        )}
        <button
          className=" button_new pull-right"
          title="Show Filters"
          onClick={() => setFilter(true)}
        >
          <i className="fa fa-filter fa-fw requests-icons"></i>
        </button>
        <CSVLink
          className=" button_new pull-right"
          title="Download"
          filename={"License-Types.csv"}
          data={exported}
          onClick={() => {
            if (exported)
              toast.success("License Types Downloaded Successfully");
          }}
        >
          <i className="fa fa-download fa-fw requests-icons"></i>
        </CSVLink>

        <a
          className=" button_new pull-right"
          onClick={() => value.setShow("licenseType")}
        >
          <i
            className="fa fa-plus fa-fw requests-icons"
            title="Add License Type"
          ></i>
        </a>
      </div>
      <div className="table-responsive">
        <table
          className="table  table-bordered table-hover dataTable no-footer"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr role="row" style={{ fontWeight: 700 }}>
              <th className="text-center" width="34px">
                S. No.
              </th>
              <th className="text-center">Name</th>
              <th className="text-center">Description</th>
              <th className="text-center" width="50px">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentLists?.length !== 0 ? (
              currentLists?.map((license, index) => {
                return (
                  <tr key={license?.id}>
                    <td className="text-start align-middle">
                      {postsPerPage * (page - 1) + index + 1}
                    </td>
                    <td className="text-start align-middle">{license?.name}</td>
                    <td className="text-start align-middle">
                      {license?.description}
                    </td>
                    <td className="text-start align-middle">
                      <a title="Edit" onClick={() => handleEdit(license)}>
                        <i className="fa fa-edit fa-fw"></i>
                      </a>

                      {license.status === 1 ? (
                        <i
                          className="fa fa-circle fa-fw txt-success pe-auto"
                          style={{
                            cursor: "pointer",
                          }}
                          title="Deactivate"
                          onClick={() => setDel(license)}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-circle fa-fw txt-fail"
                          title="Activate"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => setDel(license)}
                        ></i>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4} className="p-2 text-center">
                  {loading || isFetching ? "Loading..." : "No records found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {myData.length > postsPerPage && (
          <Pagination
            activePage={page}
            itemsCountPerPage={postsPerPage}
            totalItemsCount={myData.length}
            pageRangeDisplayed={postsPerPage}
            onChange={handlePageChange}
          />
        )}
      </div>

      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to {del?.status === 1 ? "deactivate " : "activate "}
            this License Type?
          </p>
          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Edit Modal */}
      <Offcanvas show={filter} onHide={handleHide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>Filter </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modall_body">
          <div className="container-fluid filter-box pt-3  filterScroll">
            <div className="form-group">
              <label htmlFor="tags"> License Type Name</label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Enter License Type Name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="tags"> Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter description"
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>

          <section className="pull-right m-4" onClick={()=>applyFilter()}>
            <a className="btn button_new_success p-3">Apply</a>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <AddLicenceType
        refetch={refetch}
        editData={edit}
        setEdit={setEdit}
        clearFilter={clearFilter}
        filtersAvailable={name || description}
        applyFilter={applyFilter}
        searchkey={searchkey}
        handleSearch={handleSearch}
      />
    </div>
  );
}

import React from "react";
import Select, { components } from "react-select";
import "./index.css";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "../../MyAccount/tooltip.css";
const Multiselect = ({
  options,
  value,
  placeholder,
  isMulti = true,
  ...rest
}) => {
  const MultiValueLabel = (props) => {
    return (
      <components.MultiValueLabel {...props}>
        <span title={props.data.name}>{props?.children}</span>
      </components.MultiValueLabel>
    );
  };
  return (
    <Select
      components={{
        MultiValueLabel,
        IndicatorSeparator: () => null,
      }}
      className="mySelect"
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          padding: "0 0 0 2px !important",
        }),
        input: (baseStyles, state) => ({
          ...baseStyles,
          // padding: "8px !important"
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          padding: "10px !important",
        }),
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: "#0096fb",
            color: "white",
            padding: "5px !important",
            margin: "3px !important",
            borderRadius: "15px",
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: "white",
          margin: "0px 2px 0px 0px !important",
          overflowX: "hidden !important",
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: data.color,
          padding: "2px",
          ":hover": {
            backgroundColor: "#0096fb",
            color: "white",
          },
          border: "1px solid white",
          borderRadius: "100%",
        }),
        menuList: (provided, state) => ({
          ...provided,
          overflowX: "hidden",
        }),
      }}
      isMulti={isMulti}
      options={options}
      value={value}
      placeholder={placeholder || ""}
      {...rest}
    />
  );
};

export default Multiselect;

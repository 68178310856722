import React from "react";
import axiosInstance from "../axiosConfig";

class ViewTransactionDetailsService extends React.Component {
  getTransactions = async (id) => {
    return await axiosInstance.get(`/api/user/transaction-detail/${id}`);
  };

  getDocuments = async (id) => {
    return await axiosInstance.get(`/api/user/documents/files`, {
      params: { service: id },
    });
  };

  getActivityLogs = async (payload) => {
    return await axiosInstance.post(`/api/activity_logs`, payload);
  };

  saveQuery = async (payload) => {
    return await axiosInstance.post(`/api/user/save-query`, payload);
  };
}

const instance = new ViewTransactionDetailsService();

export default instance;

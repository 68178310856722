import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import LicenseMasterServices from "../../../services/API/LicenseMaster";
import { Offcanvas } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { multiSelectStyle } from "../../../components/CreateExecutive/MultiSelectStyle";
export default function FilterModal({
  show,
  onClose,
  onYesClick,
  onNoClick,
  standardRate,
  standardTAT,
  fastrackRate,
  fastrackTAT,
  licenseName,
  classfication,
  visibilty,
  validity,
  setLicenseName,
  setClassification,
  setVisibilty,
  setValidity,
  setStandardRate,
  setStandardTAT,
  setFastrackRate,
  setFastrackTAT,
  setPage,
  clearFilterData,
}) {
  const [fstandardRate, setFStandardRate] = useState(standardRate);
  const [fstandardTAT, setFStandardTAT] = useState(standardTAT);
  const [ffastrackRate, seFtFastrackRate] = useState(fastrackRate);
  const [ffastrackTAT, setFFastrackTAT] = useState(fastrackTAT);
  const [flicenseName, setFLicenseName] = useState(licenseName);
  const [fclassfication, setFClassification] = useState(classfication);
  const [fvisibilty, setFVisibility] = useState(visibilty);
  const [fvalidity, setFValidity] = useState(validity);

  const queryKey = "/api/license";
  const {
    data: classifications,
    isLoading: loading,
    isSuccess: success,
    refetch,
  } = useQuery([queryKey], LicenseMasterServices.getLicenseClassification, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const queryKey1 = "/api/license-mapping";
  const { data: license } = useQuery(
    [queryKey1],
    LicenseMasterServices.getMappingList,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  let filteredSList = license?.data?.map((data) => data?.standard_TAT);
  let filteredFList = license?.data?.map((data) => data?.fasttrack_TAT);
  filteredSList = [...new Set(filteredSList)];
  filteredFList = [...new Set(filteredFList)];
  filteredSList = filteredSList.sort((a, b) => a - b);
  filteredFList = filteredFList.sort((a, b) => a - b);

  const visibilityArr = [
    { name: "Online", id: 1 },
    { name: "Offline", id: 2 },
    { name: "Hybrid", id: 3 },
  ];
  const validityArr = [
    { name: "Renewable", id: 1 },
    { name: "Lifetime", id: 2 },
  ];

  const applyFilter = () => {
    setLicenseName(flicenseName);
    setClassification(fclassfication);
    setVisibilty(fvisibilty);
    setValidity(fvalidity);
    setStandardRate(fstandardRate);
    setStandardTAT(fstandardTAT);
    setFastrackRate(ffastrackRate);
    setFastrackTAT(ffastrackTAT);
    setPage(1);
    onClose();
  };
  function decode(str) {
    if (str.length > 23) return str.slice(0, 23) + "...";
    else return str;
  }

  return (
    <Offcanvas show={show} placement="end" onHide={() => onClose()} size="lg">
      <Offcanvas.Header closeButton>
        <h3 className="modal-title">Filter</h3>
      </Offcanvas.Header>
      <Offcanvas.Body className="modall_body">
        <div className="container-fluid filter-box pt-3  filterScroll">
          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              {" "}
              License Name
            </label>
            <input
              value={flicenseName}
              onChange={(e) => setFLicenseName(e.target.value)}
              type="text"
              placeholder="Enter license name"
              className="form-control"
            />
          </div>
          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              {" "}
              Classification
            </label>
            <Multiselect
              options={classifications?.data}
              selectedValues={fclassfication}
              placeholder={
                fclassfication.length > 0 ? "" : "Select classification"
              }
              onSelect={(selectedList, selectedItem) => {
                setFClassification(selectedList);
              }}
              onRemove={(selectedList, selectedItem) => {
                setFClassification(selectedList);
              }}
              displayValue="name"
              className="dropdown"
              style={multiSelectStyle}
              selectedValueDecorator={(str) => decode(str)}
            />
            {/* <select
              className="form-control"
              value={fclassfication?.id}
              onChange={(e) =>
                setFClassification(
                  classifications?.data?.find(
                    (item) => item?.id == e.target.value
                  )
                )
              }
            >
              <option value="">Select Classification</option>
              {classifications?.data?.map((item, index) => (
                <option value={item?.id}>{item?.name}</option>
              ))}
            </select> */}
          </div>

          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              {" "}
              Visibilty
            </label>
            <Multiselect
              options={visibilityArr}
              selectedValues={fvisibilty}
              placeholder={fvisibilty.length > 0 ? "" : "Select visibility"}
              onSelect={(selectedList, selectedItem) => {
                setFVisibility(selectedList);
              }}
              onRemove={(selectedList, selectedItem) => {
                setFVisibility(selectedList);
              }}
              displayValue="name"
              className="dropdown"
              style={multiSelectStyle}
            />

            {/* <select
              className="form-control"
              value={fvisibilty}
              onChange={(e) => setFVisibility(e.target.value)}
            >
              <option value="">Select Visibility</option>
              <option value="online">Online</option>
              <option value="offline">Offline</option>
              <option value="hybrid">Hybrid</option>
            </select> */}
          </div>

          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Validity
            </label>

            <Multiselect
              options={validityArr}
              selectedValues={fvalidity}
              placeholder={fvalidity.length > 0 ? "" : "Select validity"}
              onSelect={(selectedList, selectedItem) => {
                setFValidity(selectedList);
              }}
              onRemove={(selectedList, selectedItem) => {
                setFValidity(selectedList);
              }}
              displayValue="name"
              className="dropdown"
              style={multiSelectStyle}
            />

            {/* <select
              className="form-control"
              value={fvalidity}
              onChange={(e) => setFValidity(e.target.value)}
            >
              <option value="">Select Validity</option>
              <option value="annual">Annual</option>
              <option value="Lifetime">Lifetime</option>
            </select> */}
          </div>

          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Standard Rate
            </label>
            <input
              value={fstandardRate}
              onChange={(e) => setFStandardRate(e.target.value)}
              placeholder="Enter standard rate"
              type="number"
              className="form-control"
            />
          </div>
          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              {" "}
              Standard TAT
            </label>
            <select
              className="form-control"
              value={fstandardTAT}
              onChange={(e) => setFStandardTAT(e.target.value)}
            >
              <option value="">Select standard TAT</option>
              {filteredSList.map((item) => (
                <option value={item}>{item} </option>
              ))}
            </select>
          </div>
          {/* <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Standard TAT
            </label>
            <input
              value={fstandardTAT}
              onChange={(e) => setFStandardTAT(e.target.value)}
              type="number"
              placeholder="Enter standard TAT"
              className="form-control"
            />
          </div> */}

          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Fast Track Rate
            </label>
            <input
              value={ffastrackRate}
              onChange={(e) => seFtFastrackRate(e.target.value)}
              type="number"
              placeholder="Enter fast track rate"
              className="form-control"
            />
          </div>

          <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              {" "}
              Fast Track TAT
            </label>
            <select
              className="form-control"
              value={ffastrackTAT}
              onChange={(e) => setFFastrackTAT(e.target.value)}
            >
              <option value="">Select fast track TAT</option>
              {filteredFList.map((item) => (
                <option value={item}>{item} </option>
              ))}
            </select>
          </div>
          {/* <div className="form-group my-2">
            <label htmlFor="tags" className="mb-2">
              Fast Track TAT
            </label>
            <input
              value={ffastrackTAT}
              onChange={(e) => setFFastrackTAT(e.target.value)}
              type="number"
              placeholder="Enter fast track TAT"
              className="form-control"
            />
          </div> */}
        </div>

        <section className="pull-right m-4" onClick={applyFilter}>
          <a className="btn button_new_success p-3">Apply</a>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

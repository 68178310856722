import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import VendorLayout from "../../../components/layouts/VendorLayout";
import Pagination from "react-js-pagination";
import VendorExecutiveServices from "../../../services/API/VendorExecutive";
import FilterExecutiveOOO from "./FilterExecutiveOOO";
import useAuth from "../../../hooks/useAuth";
import OOOGraph from "../../../components/GraphModal/OOOModal";
import moment from "moment";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
export default function OOOList() {
  const [page, setPage] = useState(1);
  const [executiveList, setExecutiveList] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchKey] = useState(null);
  const [showGraph, setShowGraph] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [exported, setExported] = useState([]);
  const auth = useAuth();
  const [filteredData, setfilteredData] = useState({
    vendor_id: auth?.userId,
    name: "",
    start_date: "",
    end_date: "",
  });
  const [graphData, setGraphData] = useState(null);
  const limit = 10;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setfilteredData((filteredData) => ({ ...filteredData, [name]: value }));
  };

  const fetchExecutive = async () => {
    setLoading(true);
    const response = await VendorExecutiveServices.getOOOExecutives({
      vendor_id: auth?.userId,
      page,
      limit,
    });
    if (response) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setExecutiveList(response?.data);
      setDataToShow(response?.data?.data);
    }
    setLoading(false);
  };

  const fetchSearchExecutive = async (value = searchkey) => {
    let payload = {
      created_for: value,
      vendor_id: auth?.userId,
      page,
      limit,
    };
    setLoading(true);
    setFilterStatus(true);
    const response = await VendorExecutiveServices.getOOOExecutives(payload);
    if (response) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setExecutiveList(response?.data);
      setDataToShow(response?.data?.data);
    }
    setLoading(false);
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchKey(value);
    fetchSearchExecutive(value);
    setPage(1);
  };

  const handleGraph = async (id) => {
    setGraphData(id);
    setShowGraph(true);
  };

  const clearFilter = async () => {
    fetchExecutive();
    setSearchKey(null);
    setDisplaySearchField(false);
    setfilteredData({
      ...filteredData,
      name: "",
      start_date: "",
      end_date: "",
    });
    setPage(1);
  };

  const handleClose = () => {
    setShowGraph(false);
    setGraphData(null);
  };

  const handleFilter = async () => {
    const { name, start_date, end_date, vendor_id } = filteredData;
    const payload = {
      vendor_id,
      created_for: name,
      start_date: start_date
        ? moment(start_date).format("YYYY-MM-DD")
        : start_date,
      end_date: end_date ? moment(end_date).format("YYYY-MM-DD") : end_date,
      page,
      limit,
    };
    setLoading(true);
    setFilterStatus(true);
    const response = await VendorExecutiveServices.getOOOExecutives(payload);
    if (response) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setExecutiveList(response?.data);
      setDataToShow(response?.data?.data);
    }
    setLoading(false);

    // setfilteredData({
    //   ...filteredData,
    //   name: "",
    //   start_date:"",
    //   end_date :""
    // })
  };

  const fetchFilterExecutive = async () => {
    setPage(1);
    handleFilter();
  };

  useEffect(() => {
    if (searchkey) {
      fetchSearchExecutive();
    } else if (!filterStatus && !searchkey) {
      fetchExecutive();
    } else {
      handleFilter();
    }
  }, [page]);

  const getExported = async () => {
    const payload = { vendor_id: auth?.userId };
    const response = await VendorExecutiveServices.exportOOO(payload);
    setExported(response ? response : []);
  };
  useEffect(() => {
    getExported();
  }, [executiveList]);
  const headers = [
    { label: "S. No.", key: "id" },
    { label: "Name", key: "Name" },
    { label: "Out-of Office From", key: "Out-of Office From" },
    { label: "Out-Of-Office Till", key: "Out-of Office To" },
    { label: "Activities Pending", key: "Activities Pending" },
  ];

  return (
    <VendorLayout>
      <div id="main" role="main">
        <div id="content">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item ">
                <Link to={"#"}>Home</Link>
              </li>
              <li className="breadcrumb-item active">
                {" "}
                <Link to="/vendor/executive">Executives Lists</Link>
              </li>
              <li className="breadcrumb-item active">
                Out-Of-Office Executives Lists
              </li>
            </ol>
          </div>
          <div>
            <div className="row">
              <div className="">
                <div className="card card-sm card-shadow mb-3">
                  <div className="card-body">
                    <div className=" d-flex justify-content-between align-items-center">
                      <h2>Out-Of-Office Executives </h2>
                      <div className="d-flex justify-content-end align-items-center gap-2 pt-4">
                        {/* {searchkey && (
                          <button
                            type="button"
                            className="button_new pull-right cursor-pointer"
                            onClick={clearFilter}
                          >
                            Clear Filters
                          </button>
                        )} */}
                        {(filteredData?.name ||
                          filteredData?.start_date ||
                          filteredData?.end_date ||
                          searchkey) && (
                          <button
                            type="button"
                            className="button_new pull-right cursor-pointer"
                            onClick={clearFilter}
                          >
                            Clear Filters
                          </button>
                        )}
                        {displaySearchField ? (
                          <input
                            type="text"
                            className="button_new pull-right"
                            value={searchkey}
                            onChange={handleSearch}
                            // disabled={workflow === "expert"}
                            placeholder="Search"
                          />
                        ) : (
                          <a
                            className=" button_new pull-right cursor-pointer"
                            onClick={() => setDisplaySearchField(true)}
                            title="Search"
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </a>
                        )}
                        <a
                          className=" button_new pull-right cursor-pointer"
                          onClick={() => {
                            setFilterStatus("filter");
                          }}
                          // style="margin-right:45px"
                        >
                          <i
                            className="fa fa-filter fa-fw"
                            title="Show Filter"
                          ></i>
                        </a>
                        <CSVLink
                          className=" button_new pull-right"
                          title="Download"
                          headers={headers}
                          filename={"Out-Of-Office-Executives.csv"}
                          data={exported}
                          onClick={() => {
                            if (exported)
                              toast.success(
                                "Out-of-Office Executives List Downloaded Successfully"
                              );
                          }}
                        >
                          <i className="fa fa-download fa-fw"></i>
                        </CSVLink>
                        <Link
                          to="/vendor/out-of-office/create"
                          className=" button_new pull-right"
                        >
                          <i
                            className="fa fa-plus fa-fw"
                            title="Add OOO Executive"
                          ></i>
                        </Link>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr role="row" style={{ fontWeight: 700 }}>
                            <th width="7%" className="text-center align-middle">
                              S. No.
                            </th>
                            <th className="text-center align-middle">Name</th>
                            <th className="text-center align-middle">
                              Out-Of-Office From
                            </th>
                            <th className="text-center align-middle">
                              Out-Of-Office Till
                            </th>
                            <th className="text-center align-middle">
                              Activities Pending
                            </th>
                            <th width="7%" className="text-center align-middle">
                              OOO Trend
                            </th>
                          </tr>
                        </thead>
                        {dataToShow.length !== 0 ? (
                          <tbody>
                            {dataToShow?.map((exec, index) => (
                              <tr>
                                <td className="text-start align-middle">
                                  {limit * (page - 1) + index + 1}
                                </td>
                                <td className="text-start align-middle">
                                  <Link
                                    to={`/vendor/executive/${exec?.executive?.id}`}
                                  >
                                    {exec?.executive?.name}
                                  </Link>
                                </td>
                                <td className="text-start align-middle">
                                  {moment(exec.start_date).format("DD-MM-YY")}
                                </td>
                                <td className="text-start align-middle">
                                  {moment(exec.end_date).format("DD-MM-YY")}
                                </td>
                                <td className="text-start align-middle">
                                  <a>--</a>
                                </td>
                                <td className="text-start align-middle">
                                  <a
                                    className=" button_new cursor-pointer"
                                    onClick={() =>
                                      handleGraph(exec?.executive?.id)
                                    }
                                  >
                                    <i
                                      className="fa fa-calendar fa-fw"
                                      title="Out-Of-Office Trend"
                                    ></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tr>
                            <td
                              colSpan={5}
                              className="p-2 text-center align-middle"
                            >
                              {loading ? "Loading..." : "No records found"}
                            </td>
                          </tr>
                        )}
                      </table>
                      <div className="d-flex justify-content-end mr-2">
                        {executiveList?.total > limit && (
                          <Pagination
                            activePage={executiveList?.current_page}
                            itemsCountPerPage={executiveList?.per_page}
                            totalItemsCount={executiveList?.total}
                            pageRangeDisplayed={5}
                            onChange={(number) => setPage(number)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FilterExecutiveOOO
        filterData={filteredData}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        setFilterData={setfilteredData}
        handleChange={handleChange}
        handleFilter={fetchFilterExecutive}
      />
      <OOOGraph show={showGraph} data={graphData} handleClose={handleClose} />
    </VendorLayout>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { Form, Col } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Navigate, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "../../styles/sysAdmin/createLicesnse.css";
// import {
//   classifications,
//   licenses,
//   documentsList,
//   zones,
//   statesList,
//   cityList,
//   municipalList,
//   industryTypeList,
//   licenseCompanyList,
// } from "./dummyData";
import LicenseMasterServices from "../../services/API/LicenseMaster";
import axiosInstance from "../../services/axiosConfig";
import Multiselect from "multiselect-react-dropdown";
import Pagination from "react-js-pagination";
import LoaderContainer from "../LoaderContainer";
import Loader from "../Loader";
import FullScreenLoader from "../FullScreenLoader";

const CreateLicense = ({ licenseEditData }) => {
  const [license, setLicense] = useState("");
  const [licenseClassification, setLicenseClassification] = useState("");
  const [visibility, setVisibility] = useState("");
  const [isValidLifeTime, setIsValidLifeTime] = useState(false);
  const [validForAllIndia, setValidForAllIndia] = useState(false);
  const [regions, setRegions] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [municipals, setMunicipals] = useState([]);
  const [industyType, setIndustryType] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [workflow, setWorkflow] = useState("");
  const [standardRate, setStandardRate] = useState("");
  const [standardTAT, setStandardTAT] = useState("");
  const [fastrackRate, setFastrackRate] = useState("");
  const [fastrackTAT, setFastrackTAT] = useState("");
  const [loader, setLoader] = useState("");
  const [editMode, setEditMode] = useState(false);
  const dataFetchedRef = useRef(false);
  // const [error, setError] = useState("");
  const navigate = useNavigate();
  // listing state

  const [licenses, setLicenses] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [municipalList, setMunicipalList] = useState([]);
  const [industryTypeList, setIndustryTypeList] = useState([]);
  const [licenseCompanyList, setLicenseCompanyList] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [licenseDetailsList, setLicenseDetailsList] = useState([]);

  const [documents, setDocuments] = useState([]);
  const [licenseDetails, setLicenseDetails] = useState([]);

  const [documentPage, setDocumentPage] = useState(1);
  const [licensePage, setLicensePage] = useState(1);

  const [regionActive, setRegionActive] = useState(false);
  const limit = 10;

  const all = {
    id: "all",
    name: "All",
  };

  const validate = () => {
    if (!license) return "Please select license";
    if (!licenseClassification) return "Please select license classification";
    if (!visibility) return "Please select mode of application";
    if (!visibility) return "Please select visibility";
    // if (!validForAllIndia && regions?.length < 1)
    //   return "Please Select at least one Region";
    if (!validForAllIndia && states?.length < 1)
      return "Please select at least one state";
    if (!validForAllIndia && cities?.length < 1)
      return "Please select at least one city";
    if (!validForAllIndia && municipals?.length < 1)
      return "Please select atleast one municipal corporation";
    if (industyType?.length < 1)
      return "Please select at least one industry type";
    if (companyTypes?.length < 1)
      return "Please select at least one company type";

    if (documents?.length < 2)
      return "Please include more than one document for valid license mapping";

    let oneRequired = false;
    for (let i = 0; i < documents?.length; i++) {
      if (documents[i]?.mandate) oneRequired = true;
    }
    if (!oneRequired) return "Please mark atleast one document as mandatory";
    if (workflow === "") return "Please select the Workflow";
    // let licenseRequired = false
    // for (let i = 0; i < licenseList?.length; i++) {
    //   if (licenseList[i]?.mandate) oneRequired = true;
    // }
    // if (!licenseRequired) return "At least one license needs to be mandatory";

    if (standardRate.length > 10)
      return "Standard rate value must be below 10 digit";
    if (!Number(standardRate)) return "Please provide valid standard rate";

    if (standardTAT.length > 3 || standardTAT.length < 0)
      return "Standard tat should not be greater than three digit value.";
    if (!standardTAT || String(standardTAT)[0] === "0")
      return "Please provide valid standard TAT";

    if (fastrackRate.length > 10)
      return "Fasttrack rate value must be below 10 digit";
    if (!Number(fastrackRate)) return "Please provide valid fasttrack rate";
    if (!fastrackTAT || String(fastrackTAT)[0] === "0")
      return "Please provide valid fastrack TAT";
    if (Number(standardRate) >= Number(fastrackRate))
      return "Fasttrack price should be greater than Standard price";
    if (parseInt(fastrackTAT) >= parseInt(standardTAT))
      return "Fasttrack TAT should be lesser than Standard TAT";
  };

  const AddLicense = async () => {
    const error = validate();
    if (error) {
      toast.error(error);
      return;
    }

    let documentsPayload = {};
    for (let i = 0; i < documents?.length; i++) {
      documentsPayload[documents[i]?.id] = documents[i]?.mandate ? 1 : 0;
    }

    // let licenseFields = [];
    // for (let i = 0; i < licenseDetails?.length; i++) {
    //   licenseFields.push(licenseDetails[i]?.id);
    // }

    let payload = {
      license: license?.id, //license Id
      classification: licenseClassification?.id, //License Type Id
      visibility: visibility,
      validity: isValidLifeTime ? "lifetime" : "renewable",
      regions: validForAllIndia ? [] : regions?.map((item) => item?.id), //Regions(Zones) ids
      states: validForAllIndia ? [] : states?.map((item) => item?.id), //State Ids
      cities: validForAllIndia ? [] : cities?.map((item) => item?.id), //City Ids
      municipals: validForAllIndia ? [] : municipals?.map((item) => item?.id), //Municipal iDS
      documents: documentsPayload,
      license_fields: licenseDetails?.map((item) => item?.id),
      industryTypes: industyType?.map((item) => item?.id), //Industry type iDS
      companyTypes: companyTypes?.map((item) => item?.id), //Company Type iDS
      workflow: workflow, //0=> Talk to an expert | 1=> Payment workflow
      is_selected: validForAllIndia ? 1 : 0,
      standardRate: standardRate, // Standard rate in inr
      standardTAT: standardTAT, //Tat in no of days
      fasttrackRate: fastrackRate, //Fasttrack rate in inr
      fasttrackTAT: fastrackTAT, // Tat in no of days
    };
    // else {
    //   payload.standardRate = ""; // Standard rate in inr
    //   payload.standardTAT = ""; //Tat in no of days
    //   payload.fasttrackRate = ""; //Fasttrack rate in inr
    //   payload.fasttrackTAT = ""; // Tat in no of days
    // }
    if (!editMode) {
      payload.is_enabled = 1;
    }
    if (editMode) {
      setLoader("updateLicense");
      const resp = await LicenseMasterServices.updateLicense(
        licenseEditData?.id,
        payload
      );
      setLoader("");
      if (resp) {
        toast.success("License updated successfully");
        navigate("/system-admin/license-master");
      }
      // else {
      //   toast.error("Cannot aupdate  license at this time. Please try later");
      // }
    } else {
      setLoader("addLicense");
      const resp = await LicenseMasterServices.addLicense(payload);
      setLoader("");

      if (resp) {
        toast.success("License added successfully");
        navigate("/system-admin/license-master");
      }
      // else {
      //   toast.error("Cannot add license add this time. Please try later");
      // }
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    let queryParams = {};
    if (!licenseEditData) {
      queryParams.active = 1;
    }
    Promise.all([
      LicenseMasterServices.getAllLicense(queryParams),
      LicenseMasterServices.getLicenseClassification(queryParams),
      LicenseMasterServices.getRegions(queryParams),
      LicenseMasterServices.getAllIndustry(queryParams),
      LicenseMasterServices.getAllCompanyType(queryParams),
      LicenseMasterServices.getAllDocuments(),
      LicenseMasterServices.getLicenseDetailsList(),
    ]).then((results) => {
      if (results[0]?.data?.length) {
        let sortedLicenses = results[0]?.data.sort(
          (first, second) => second.status - first.status
        );
        setLicenses(sortedLicenses);
      }

      if (results[1]?.data?.length) {
        let sortedLicenses = results[1]?.data.sort(
          (first, second) => second.status - first.status
        );
        setClassifications(sortedLicenses);
      }

      const region = [];
      results[2]?.data?.forEach((item) => {
        if (item.status !== "Inactive" && item.status !== 0) {
          region.push(item);
        }
      });

      if (results[2]?.data?.length) setRegionsList(region || []);

      const industryType = [];
      results[3]?.data?.forEach((item) => {
        if (item.status !== "Inactive" && item.status !== 0) {
          industryType.push(item);
        }
      });
      if (results[3]?.data?.length) setIndustryTypeList(industryType || []);

      const companyList = [];
      results[4]?.data?.forEach((item) => {
        if (item.status !== "Inactive" && item.status !== 0) {
          companyList.push(item);
        }
      });
      if (results[4]?.data?.length) setLicenseCompanyList(companyList || []);
      if (results[5]?.data?.length) {
        if (licenseEditData?.documents !== undefined) {
          const data = [];
          results[5]?.data.forEach((doc) => {
            let status = licenseEditData?.documents?.some(
              (item) => item.id === doc.id
            );
            if (status && doc.status === 0) {
              data.push(doc);
            }
            if (doc.status !== 0) {
              data.push(doc);
            }
          });
          setDocumentsList(data);
        } else {
          const data = results[5]?.data.filter((item) => item.status !== 0);

          setDocumentsList(data);
        }
      }

      if (results[6]?.data?.length) {
        if (licenseEditData?.license_fieldsdetails !== undefined) {
          const data = [];
          results[6]?.data.forEach((field) => {
            let status = licenseEditData?.license_fieldsdetails?.some(
              (item) => item.id === field.id
            );
            if (status && field.is_enabled === 0) {
              data.push(field);
            }
            if (field.is_enabled !== 0) {
              data.push(field);
            }
          });

          setLicenseDetailsList(data);
        } else {
          const data = results[6]?.data.filter((item) => item.is_enabled !== 0);
          setLicenseDetailsList(data);
        }
      }

      dataFetchedRef.current = true;
    });
  }, []);

  useEffect(() => {
    if (licenseEditData && dataFetchedRef.current) {
      setLicense(
        licenses?.find((item) => item?.id == licenseEditData?.license_id)
      );
      setLicenseClassification(
        classifications?.find(
          (item) => item?.id == licenseEditData?.license_type_id
        )
      );
      setVisibility(licenseEditData?.visibilty);
      setIsValidLifeTime(licenseEditData?.validity === "lifetime");
      setWorkflow(licenseEditData?.workflow);
      if (licenseEditData?.is_selected == 1) {
        setRegions([all]);
        setStates([all]);
        setCities([all]);
        setMunicipals([all]);
        // setIndustryType([all]);
        // setCompanyTypes([all]);
        setValidForAllIndia(true);
      } else {
        setRegions(licenseEditData?.zones);
        setStates(licenseEditData?.states);
        setCities(licenseEditData?.cities);
        setMunicipals(licenseEditData?.municipals);
        // setIndustryType(licenseEditData?.industry_types);
        // setCompanyTypes(licenseEditData?.company_types);
        setValidForAllIndia(false);
      }
      setIndustryType(licenseEditData?.industry_types);
      setCompanyTypes(licenseEditData?.company_types);
      setDocuments(
        licenseEditData?.documents?.map((item) => {
          return {
            id: item?.id,
            mandate: item?.pivot?.is_mandate,
          };
        })
      );

      setLicenseDetails(
        licenseEditData?.license_fieldsdetails?.map((item) => {
          return {
            id: item?.id,
            //  mandate: item?.pivot?.is_mandate,
          };
        })
      );

      setStandardRate(licenseEditData?.standard_rate);
      setStandardTAT(licenseEditData?.standard_TAT);
      setFastrackRate(licenseEditData?.fasttrack_rate);
      setFastrackTAT(licenseEditData?.fasttrack_TAT);
      setEditMode(true);
    }
  }, [licenseEditData, dataFetchedRef.current]);

  // useEffect(() => {
  //   if (workflow === "expert") {
  //     setStandardRate("");
  //     setFastrackRate("");
  //     setStandardTAT("");
  //     setFastrackTAT("");
  //   } else {
  //     setStandardRate(licenseEditData?.standard_rate);
  //     setStandardTAT(licenseEditData?.standard_TAT);
  //     setFastrackRate(licenseEditData?.fasttrack_rate);
  //     setFastrackTAT(licenseEditData?.fasttrack_TAT);
  //   }
  // }, [workflow]);

  const getStates = async () => {
    if (regionActive) {
      const stateList = [];
      states?.forEach((state) => {
        let status = regions.some((item) => {
          if (item.id !== "all") {
            if (item.id === state.zone_id || item.name === state.zone_name) {
              return true;
            } else {
              return false;
            }
          }
        });

        if (status) {
          stateList.push(state);
        }
      });

      if (stateList.length > 0) {
        setStates(stateList);
      } else {
        setStates([]);
      }
    }
    if (regions.length > 0) {
      let queryParams = {
        zone_id: regions?.map((zone) => zone.id),
      };

      if (!licenseEditData) {
        queryParams.active = 1;
      }

      try {
        const resp = await LicenseMasterServices.filterState(queryParams);
        if (resp) {
          const states = [];
          resp?.data?.forEach((item) => {
            if (item.status !== "Inactive" && item.status !== 0) {
              states.push(item);
            }
          });
          setStatesList(states);
        }
      } catch (e) {
      }
    } else {
      let queryParams = {};
      if (!licenseEditData) {
        queryParams.active = 1;
      }
      try {
        const resp = await LicenseMasterServices.getAllStates(queryParams);
        if (resp) {
          const states = [];
          resp?.data?.forEach((item) => {
            if (item.status !== "Inactive" && item.status !== 0) {
              states.push(item);
            }
          });
          setStatesList(states);
        }
      } catch (e) {
      }
    }
  };
  const getCities = async () => {
    let queryParams = {
      state_id: states?.map((state) => state.id),
    };
    if (!licenseEditData) {
      queryParams.active = 1;
    }
    const citiesList = [];
    cities.forEach((city) => {
      let status = states.some((item) => {
        if (item.id === city.state_id || item.name === city.state_name) {
          return true;
        } else {
          return false;
        }
      });

      if (status) {
        citiesList.push(city);
      }
    });
    if (citiesList.length > 0) {
      setCities(citiesList);
    } else {
      setCities([]);
    }

    try {
      const resp = await LicenseMasterServices.filterCity(queryParams);

      if (resp) {
        const cityList = [];
        resp?.data?.forEach((item) => {
          if (item.status !== "Inactive" && item.status !== 0) {
            cityList.push(item);
          }
        });

        setCityList(cityList);
      }
    } catch (e) {
    }
  };

  const getMunicipals = async () => {
    let queryParams = {
      city_id: cities?.map((city) => city.id),
    };
    if (!licenseEditData) {
      queryParams.active = 1;
    }

    const municipalsList = [];
    municipals.forEach((municipl) => {
      let status = cities.some((item) => {
        if (item.id === municipl.city_id || item.name === municipl.city_name) {
          return true;
        } else {
          return false;
        }
      });
      if (status) {
        municipalsList.push(municipl);
      }
    });

    if (municipalsList.length > 0) {
      setMunicipals(municipalsList);
    } else {
      setMunicipals([]);
    }

    try {
      const resp = await LicenseMasterServices.filterMC(queryParams);

      if (resp) {
        const municipalList = [];
        resp?.data?.forEach((item) => {
          if (item.status !== "Inactive" && item.status !== 0) {
            municipalList.push(item);
          }
        });
        setMunicipalList(municipalList);
      }
    } catch (e) {
    }
  };

  useEffect(() => {
    // if(regionActive){
    if (regions[0]?.id !== "all") {
      getStates();
    }
    // }
  }, [regions]);
  useEffect(() => {
    if (states[0]?.id !== "all") {
      getCities();
    }
  }, [states]);
  useEffect(() => {
    if (cities[0]?.id !== "all") {
      getMunicipals();
    }
  }, [cities]);

  useEffect(() => {
    if (validForAllIndia) {
      setRegions([all]);
      setStates([all]);
      setCities([all]);
      setMunicipals([all]);
      // setCompanyTypes([all]);
      // setIndustryType([all]);
    } else {
      setRegions([]);
      setStates([]);
      setCities([]);
      setMunicipals([]);
      // setCompanyTypes([]);
      // setIndustryType([]);
    }
  }, [validForAllIndia]);

  const uploadExcel = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    setLoader("Excel upload");
    const resp = await LicenseMasterServices.uploadReport(formData);
    setLoader("");
  };

  const multiSelectStyle = {
    chips: {
      fontSize: "12px",
      // height: "20px",
      width: "auto",
      padding: "5px",
      gap: "5px",
      margin: "5px",
    },
    input: {
      fontSize: "12px",
      paddingLeft: "10px !important",
      height: "30px !important",
    },
    searchBox: {
      padding: "10px",
      minHeight: "25px",
      maxHeight: "auto",
      // maxWidth:'auto'
    },
    optionContainer: {
      // To change css for option container
      overflowX: "hidden",
    },
  };

  function decode(str) {
    if (str.length > 23) return str.slice(0, 23) + "...";
    else return str;
  }

  const Document = ({ document }) => {
    return (
      <tr>
        <td>
          <label className="checkbox" style={{ marginLeft: "22px" }}>
            <input
              type="checkbox"
              checked={documents?.some((dItem) => dItem?.id === document?.id)}
              onChange={(e) => {
                if (documents?.some((dItem) => dItem?.id === document?.id)) {
                  const newList = documents?.filter(
                    (item) => item?.id != document?.id
                  );
                  setDocuments(newList);
                } else {
                  const newList = [
                    ...documents,
                    {
                      ...document,
                      mandate: false,
                    },
                  ];
                  setDocuments(newList);
                }
              }}
              name="checkbox"
            />
            <i></i>
          </label>
        </td>
        <td>{document?.name}</td>
        <td>
          <label className="checkbox" style={{ marginLeft: "30px" }}>
            <input
              type="checkbox"
              checked={
                !!documents?.find((dItem) => dItem?.id === document?.id)
                  ?.mandate
              }
              onChange={(e) => {
                let newList = [...documents];
                newList = newList?.map((dItem, index) => {
                  if (dItem?.id == document?.id) {
                    return {
                      ...dItem,
                      mandate: !dItem?.mandate,
                    };
                  } else return dItem;
                });
                setDocuments(newList);
              }}
              name="checkbox"
            />
            <i></i>
          </label>
        </td>
      </tr>
    );
  };

  const LicenseDetail = ({ license }) => {
    return (
      <tr>
        <td>
          <label className="checkbox" style={{ marginLeft: "22px" }}>
            <input
              type="checkbox"
              checked={licenseDetails?.some(
                (dItem) => dItem?.id === license?.id
              )}
              onChange={(e) => {
                if (
                  licenseDetails?.some((dItem) => dItem?.id === license?.id)
                ) {
                  const newList = licenseDetails?.filter(
                    (item) => item?.id != license?.id
                  );
                  setLicenseDetails(newList);
                } else {
                  const newList = [
                    ...licenseDetails,
                    {
                      ...license,
                      mandate: false,
                    },
                  ];
                  setLicenseDetails(newList);
                }
              }}
              name="checkbox"
            />
            <i></i>
          </label>
        </td>
        <td>{license?.name}</td>
      </tr>
    );
  };

  return (
    <div id="main" role="main">
      <div id="content">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                href="#"
                onClick={() => navigate("/system-admin/license-master")}
              >
                License Master
              </a>
            </li>
            <li className="breadcrumb-item active">View Details</li>
          </ol>
        </div>

        <div>
          {!dataFetchedRef.current ? (
            <div className="text-center">Loading...</div>
          ) : (
            <div className="row">
              <div className="col-md-6 col-lg-12">
                <div className="card card-sm card-shadow mb-3">
                  <div className="card-body">
                    <form className="smart-form">
                      <div className="d-flex align-items-center pl-3 pt-1 gap-2">
                        <i
                          className="fa fa-arrow-left"
                          style={{
                            cursor: "pointer",
                            lineHeight: "3",
                            fontSize: "22px",
                          }}
                          onClick={() =>
                            navigate("/system-admin/license-master")
                          }
                        ></i>

                        <h2>License Mapping </h2>
                      </div>

                      <fieldset>
                        <div className="row">
                          <section className="col col-3">
                            <label className="label">
                              Select License
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Form.Group>
                              <Form.Control
                                as="select"
                                className="px-2"
                                value={license?.id}
                                style={{ fontSize: "12px" }}
                                onChange={(e) => {
                                  setLicense(
                                    licenses?.find(
                                      (item) => item?.id == e.target.value
                                    )
                                  );
                                }}
                              >
                                <option value="">Select License</option>
                                {licenses?.map((item, index) => (
                                  <option
                                    value={item?.id}
                                    disabled={item.status === 0 ? true : false}
                                  >
                                    {item?.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </section>

                          <section className="col col-3">
                            <label className="label">
                              Select License Classification
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Form.Group>
                              <Form.Control
                                as="select"
                                className="px-2"
                                style={{ fontSize: "12px" }}
                                value={licenseClassification?.id}
                                onChange={(e) =>
                                  setLicenseClassification(
                                    classifications?.find(
                                      (item) => item?.id == e.target.value
                                    )
                                  )
                                }
                              >
                                <option value="">Select Classification</option>
                                {classifications?.map((item, index) => (
                                  <option
                                    value={item?.id}
                                    disabled={item.status === 0 ? true : false}
                                  >
                                    {item?.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </section>
                          <section className="col col-3">
                            <label className="label">
                              Is Offline / Online / Hybrid?
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Form.Group>
                              <Form.Control
                                as="select"
                                className="px-2"
                                style={{ fontSize: "12px" }}
                                value={visibility}
                                onChange={(e) => setVisibility(e.target.value)}
                              >
                                <option value="" style={{ fontSize: "12px" }}>
                                  Select Visibility
                                </option>
                                <option
                                  value="online"
                                  style={{ fontSize: "12px" }}
                                >
                                  Online
                                </option>
                                <option
                                  value="offline"
                                  style={{ fontSize: "12px" }}
                                >
                                  Offline
                                </option>
                                <option
                                  value="hybrid"
                                  style={{ fontSize: "12px" }}
                                >
                                  Hybrid
                                </option>
                              </Form.Control>
                            </Form.Group>
                          </section>
                          <section className="col col-2">
                            <label className="label">
                              Is Valid for Lifetime?{" "}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <label className="toggle">
                              <input
                                type="checkbox"
                                name="checkbox-toggle"
                                checked={isValidLifeTime}
                                onChange={() =>
                                  setIsValidLifeTime(!isValidLifeTime)
                                }
                                // value={isValidLifeTime}
                              />
                              <i
                                data-swchon-text="YES"
                                data-swchoff-text="NO"
                                style={{ right: "unset" }}
                              ></i>
                            </label>
                          </section>
                          {/* commenting code as per client request */}
                          {/* <section className="col col-3">
                            <label className="label">Related License</label>
                            <Form.Group>
                              <Form.Control
                                as="select"
                                className="px-2"
                                // value={visibility}
                                // onChange={(e) => setVisibility(e.target.value)}
                              >
                                <option value="">Select Related License</option>
                                {licenses?.map((item, index) => (
                                  <option value={item?.id}>{item?.name}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </section> */}
                        </div>
                      </fieldset>
                      <fieldset>
                        <div className="row">
                          <section className="col col-12">
                            <div className="inline-group">
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  checked={validForAllIndia}
                                  onChange={() => {
                                    if (validForAllIndia) {
                                      setRegions([]);
                                      setStates([]);
                                      setCities([]);
                                      setMunicipals([]);
                                      // setIndustryType([]);
                                      // setCompanyTypes([]);
                                      setValidForAllIndia(false);
                                    } else {
                                      setRegions([all]);
                                      setStates([all]);
                                      setCities([all]);
                                      setMunicipals([all]);
                                      // setIndustryType([all]);
                                      // setCompanyTypes([all]);
                                      setValidForAllIndia(true);
                                    }
                                  }}
                                  name="checkbox-inline"
                                />
                                <i></i>Select All Option
                              </label>
                            </div>
                            <div className="note">
                              <strong>Note: </strong>Check "Select All Option"
                              when the checklist is applicable to PAN India.
                            </div>
                          </section>
                          <section className="col col-4">
                            <label className="label">Select Zone</label>
                            <Multiselect
                              options={regionsList}
                              selectedValues={regions}
                              placeholder={
                                regions.length > 0 ? "" : "Select Zone"
                              }
                              onSelect={(selectedList, selectedItem) => {
                                setRegionActive(true);
                                setRegions(selectedList);
                              }}
                              onRemove={(selectedList, selectedItem) => {
                                setRegionActive(true);
                                setRegions(selectedList);
                              }}
                              displayValue="name"
                              className="dropdown"
                              style={multiSelectStyle}
                              disable={validForAllIndia}
                              selectedValueDecorator={(str) => decode(str)}
                              customCloseIcon={validForAllIndia ? <></> : null}
                            />
                          </section>

                          <section className="col col-4">
                            <label className="label">
                              Select State
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Multiselect
                              options={statesList}
                              selectedValues={states}
                              placeholder={
                                states.length > 0 ? "" : "Select State"
                              }
                              onSelect={(selectedList, selectedItem) => {
                                setStates(selectedList);
                              }}
                              onRemove={(selectedList, selectedItem) => {
                                setStates(selectedList);
                              }}
                              displayValue="name"
                              className="dropdown"
                              style={multiSelectStyle}
                              disable={validForAllIndia}
                              selectedValueDecorator={(str) => decode(str)}
                              customCloseIcon={validForAllIndia ? <></> : null}
                            />
                          </section>
                          <section className="col col-4">
                            <label className="label">
                              Select City
                              <span style={{ color: "red" }}> *</span>
                            </label>

                            <Multiselect
                              options={cityList}
                              selectedValues={cities}
                              placeholder={
                                cities.length > 0 ? "" : "Select City"
                              }
                              onSelect={(selectedList, selectedItem) => {
                                setCities(selectedList);
                              }}
                              onRemove={(selectedList, selectedItem) => {
                                setCities(selectedList);
                              }}
                              displayValue="name"
                              className="dropdown"
                              style={multiSelectStyle}
                              disable={validForAllIndia}
                              selectedValueDecorator={(str) => decode(str)}
                              customCloseIcon={validForAllIndia ? <></> : null}
                            />
                          </section>
                        </div>
                        <div className="row">
                          <section className="col col-4">
                            <label className="label">
                              Select Municipal Corporation
                              <span style={{ color: "red" }}> *</span>
                            </label>

                            <Multiselect
                              options={municipalList}
                              selectedValues={municipals}
                              placeholder={
                                municipals.length > 0
                                  ? ""
                                  : "Select Municipal Corp."
                              }
                              onSelect={(selectedList, selectedItem) => {
                                setMunicipals(selectedList);
                              }}
                              onRemove={(selectedList, selectedItem) => {
                                setMunicipals(selectedList);
                              }}
                              displayValue="name"
                              className="dropdown"
                              style={multiSelectStyle}
                              disable={validForAllIndia}
                              selectedValueDecorator={(str) => decode(str)}
                              customCloseIcon={validForAllIndia ? <></> : null}
                            />
                          </section>
                          <section className="col col-4">
                            <label className="label">
                              Select Industry Type
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Multiselect
                              options={industryTypeList}
                              selectedValues={industyType}
                              placeholder={
                                industyType.length > 0
                                  ? ""
                                  : "Select Industry Type"
                              }
                              onSelect={(selectedList, selectedItem) => {
                                setIndustryType(selectedList);
                              }}
                              onRemove={(selectedList, selectedItem) => {
                                setIndustryType(selectedList);
                              }}
                              displayValue="name"
                              className="dropdown"
                              style={multiSelectStyle}
                              // disable={validForAllIndia}
                              selectedValueDecorator={(str) => decode(str)}
                              // customCloseIcon={validForAllIndia ? <></> : null}
                            />
                          </section>

                          <section className="col col-4">
                            <label className="label">
                              Select Company Type
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Multiselect
                              options={licenseCompanyList}
                              selectedValues={companyTypes}
                              placeholder={
                                companyTypes.length > 0
                                  ? ""
                                  : "Select Company Type"
                              }
                              onSelect={(selectedList, selectedItem) => {
                                setCompanyTypes(selectedList);
                              }}
                              onRemove={(selectedList, selectedItem) => {
                                setCompanyTypes(selectedList);
                              }}
                              displayValue="name"
                              className="dropdown"
                              style={multiSelectStyle}
                              // disable={validForAllIndia}
                              selectedValueDecorator={(str) => decode(str)}
                              // customCloseIcon={validForAllIndia ? <></> : null}
                            />
                          </section>
                        </div>

                        <section className="col-lg-9">
                          <label className="label">
                            Select Document Types
                            <span style={{ color: "red" }}> *</span>
                          </label>

                          <table className="table table-bordered mb-3">
                            <thead>
                              <tr>
                                <td
                                  width="7%"
                                  style={{ textAlign: "center" }}
                                ></td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <strong
                                    style={{
                                      lineHeight: "19px",
                                      fontWeight: "400",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Document Name
                                  </strong>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    width: "13%",
                                  }}
                                >
                                  <strong
                                    style={{
                                      lineHeight: "19px",
                                      fontWeight: "400",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Is Mandatory ?
                                  </strong>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {documentsList
                                ?.sort((doc) => {
                                  if (
                                    editMode &&
                                    licenseEditData?.documents?.some(
                                      (item) => item?.id === doc?.id
                                    )
                                  )
                                    return -1;
                                  else return 1;
                                })
                                ?.slice(
                                  documentPage * limit - limit,
                                  documentPage * limit
                                )
                                ?.map((document, index) => {
                                  return <Document document={document} />;
                                })}
                            </tbody>
                          </table>

                          <Pagination
                            activePage={documentPage}
                            itemsCountPerPage={limit}
                            totalItemsCount={documentsList.length - 1}
                            pageRangeDisplayed={3}
                            onChange={(number) => setDocumentPage(number)}
                          />
                        </section>
                      </fieldset>
                      <fieldset>
                        <section className="col-lg-9">
                          <label className="label">License details list</label>

                          <table className="table table-bordered mb-3">
                            <thead>
                              <tr>
                                <td width="7%"></td>
                                <td>
                                  <strong
                                    style={{
                                      lineHeight: "19px",
                                      fontWeight: "400",
                                      fontSize: "13px",
                                    }}
                                  >
                                    License Field Name
                                  </strong>
                                </td>
                              </tr>
                            </thead>

                            <tbody>
                              {licenseDetailsList
                                ?.sort((detail) => {
                                  if (
                                    editMode &&
                                    licenseEditData?.license_fieldsdetails?.some(
                                      (item) => item?.id === detail?.id
                                    )
                                  )
                                    return -1;
                                  else return 1;
                                })
                                ?.slice(
                                  licensePage * limit - limit,
                                  licensePage * limit
                                )
                                .map((license, index) => {
                                  return <LicenseDetail license={license} />;
                                })}
                            </tbody>
                          </table>
                          <Pagination
                            activePage={licensePage}
                            itemsCountPerPage={limit}
                            totalItemsCount={licenseDetailsList.length - 1}
                            pageRangeDisplayed={3}
                            onChange={(number) => setLicensePage(number)}
                          />
                        </section>
                      </fieldset>
                      <fieldset>
                        <div className="row">
                          <section className="col col-3">
                            <label className="label">
                              Select Workflow
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <Form.Group>
                              <Form.Control
                                as="select"
                                className="px-2"
                                value={workflow}
                                style={{ fontSize: "12px" }}
                                onChange={(e) => {
                                  setWorkflow(e.target.value);
                                }}
                              >
                                <option value="">Select Workflow</option>
                                <option value={0}>
                                  Talk to Expert Workflow
                                </option>
                                <option value={1}>Payment Workflow</option>
                              </Form.Control>
                            </Form.Group>
                          </section>
                        </div>
                      </fieldset>
                      <fieldset>
                        <div className="row">
                          <h3
                            style={{
                              paddingLeft: "15px",
                              paddingBottom: "10px",
                            }}
                          >
                            Standard Pricing{" "}
                          </h3>
                          <section className="col col-3">
                            <label className="label">
                              Enter Rate{" "}
                              <small className="note">(In INR)</small>
                              <span style={{ color: "red" }}> *</span>
                            </label>

                            <label className="input">
                              <input
                                type="text"
                                className="input-sm "
                                placeholder
                                value={standardRate}
                                // min="1"
                                // step="1"
                                // onKeyDown={(event) => {
                                //   if (event.key === ".") event.preventDefault();
                                // }}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  setStandardRate(value);
                                }}
                                //disabled={workflow === "expert"}
                              />
                            </label>
                          </section>
                          <section className="col col-3">
                            <label className="label">
                              Applicable TAT{" "}
                              <small className="note">(In Working Days)</small>{" "}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <label className="input">
                              <input
                                type="text"
                                className="input-sm"
                                placeholder
                                maxLength={3}
                                value={standardTAT}
                                // onKeyDown={(event) => {
                                //   if (event.key === ".") event.preventDefault();
                                // }}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  setStandardTAT(value);
                                }}
                                //disabled={workflow === "expert"}
                              />
                            </label>
                          </section>
                        </div>
                      </fieldset>
                      <fieldset>
                        <div className="row">
                          <h3
                            style={{
                              paddingLeft: "15px",
                              paddingBottom: "10px",
                            }}
                          >
                            Fast Track Pricing{" "}
                          </h3>
                          <section className="col col-3">
                            <label className="label">
                              Enter Rate{" "}
                              <small className="note">(In INR)</small>
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <label className="input">
                              <input
                                type="text"
                                className="input-sm"
                                placeholder
                                value={fastrackRate}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  setFastrackRate(value);
                                }}
                                // onKeyDown={(event) => {
                                //   if (event.key === ".") event.preventDefault();
                                // }}
                                // disabled={workflow === "expert"}
                              />
                            </label>
                          </section>
                          <section className="col col-3">
                            <label className="label">
                              Applicable TAT{" "}
                              <small className="note">(In Working Days)</small>{" "}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <label className="input">
                              <input
                                type="text"
                                className="input-sm"
                                placeholder
                                maxLength={3}
                                value={fastrackTAT}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  setFastrackTAT(value);
                                }}
                                // onKeyDown={(event) => {
                                //   if (event.key === ".") event.preventDefault();
                                // }}
                                // disabled={workflow === "expert"}
                              />
                            </label>
                          </section>
                        </div>
                      </fieldset>

                      <footer>
                        <button
                          type="button"
                          className="btn button_new button_new_success"
                          onClick={() => AddLicense()}
                          disabled={!!loader}
                        >
                          Save
                        </button>
                      </footer>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateLicense;

import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "react-bootstrap/Modal";
import "../../../styles/executive/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorExecutive from "../../../services/API/VendorExecutive";
import useAuth from "../../../hooks/useAuth";

const localizer = momentLocalizer(moment);

export default function LeaveCalendar({
  showModal1,
  handleClose,
  myLeaveList1,
}) {
  const [myLeaveList, setMyLeaveList] = useState([]);
  const auth = useAuth();

  const handleMonth = async (date) => {
    const dateTemplate = await moment(date).format("DD-MM-YYYY");
    const payload = {
      id: auth?.userId,
      date: dateTemplate,
    };
    const result = await VendorExecutive.getMyLeaveHistory(payload);
    const res = result.data.leave_history.map((el) => {
      return {
        start: new Date(`${el.start_date}T10:00:00`),
        end: new Date(`${el.end_date}T12:00:00`),
        title: "  ",
      };
    });
    setMyLeaveList(res);
  };
  useEffect(() => {
    if (showModal1) {
      handleMonth(new Date());
    }
  }, [showModal1]);

  return (
    <Modal
      centered
      style={{ opacity: 1 }}
      show={showModal1}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Calendar
          localizer={localizer}
          events={myLeaveList.length ? myLeaveList : []}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 400 }}
          onNavigate={handleMonth}
        />
      </Modal.Body>
    </Modal>
  );
}

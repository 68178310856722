/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import AliianceManagerLayout from "../../../components/layouts/AliianceManagerLayout";
import AdminPageContainer from "../../../components/AdminPageContainer";
import { useNavigate } from "react-router";
import { dummyVendorList } from "./dummyData";
import { useQuery } from "react-query";
import VendorManagerServices from "../../../services/API/VendorManager";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../../components/Loader";
import { addDays, format } from "date-fns";
import Pagination from "react-js-pagination";
import VendorFilter from "../../../components/VendorFilter";
import { Link } from "react-router-dom";

const initial = {
  name: "",
  short_name: "",
  man_power_cnt_field: "",
  man_power_cnt_execuitve: "",
  address: "",
  status: "",
  dueDateRange: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ],
  dateSubmit: false,
  requestedDateRange: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ],
  requestDateSubmit: false,
};
const VendorManagement = () => {
  const vendor = {
    type: "active",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [adItem, setADItem] = useState("");
  const [loader, setLoader] = useState("");
  const [showFilter, setShowFilter] = useState("");
  const [filters, setFilters] = useState(initial);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [clearFilters, setCleafilters] = useState(false);
  const [showDuedateRange, setShowDuedateRange] = useState(false);
  const [showReqDateRange, setShowReqDateRange] = useState(false);
  const [q, setq] = useState("");
  const limit = 20;

  const queryKey = `/api/vendor`;
  const {
    data: vendorList,
    isFetching,
    isLoading: listLoading,
    isSuccess: success,
    isRefetching,
    refetch,
  } = useQuery(
    [
      queryKey,
      {
        ...filters,
        q,
        limit: limit,
        page: page,
      },
    ],
    VendorManagerServices.getAllVendor,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [vendorList, page]);

  const updateVendorStatus = async () => {
    let statusToset = true;
    if (adItem?.is_enabled == 1) {
      statusToset = false;
    }
    setLoader("update");
    const resp = await VendorManagerServices.updateVendorStatus(
      adItem?.id,
      statusToset
    );
    setLoader("");
    setADItem(null);
    if (resp.data.is_enabled == 0) {
      toast.success("Vendor Deactivated Successfully");
      refetch();
    } else {
      toast.success("Vendor Activated Successfully");
      refetch();
    }
  };

  const downloadReport = async () => {
    setLoader("download");
    const resp = await VendorManagerServices.downloadReport();
    setLoader("");
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Vendors-Lists.csv");
      toast.success("Manage Vendors Downloaded Successfully");
      link.click();
    }
  };

  const cellClasses = "d-flex align-items-center h-full td-sr";
  // const cellClasses = ""
  return (
    <AliianceManagerLayout vendor="active">
      <AdminPageContainer>
        <Modal
          show={!!adItem}
          onHide={() => setADItem(null)}
          animation={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Do you want to {adItem?.is_enabled == 1 ? "deactivate " : "activate "}
              this Vendor?
            </p>
            <div className="d-flex gap-3 justify-content-end">
              <Button
                variant="danger"
                className="px-3 py-2"
                // button button_new
                onClick={() => setADItem(null)}
                disabled={loader === "update"}
              >
                No
              </Button>
              <Button
                variant="success"
                className="px-3 py-2"
                // button_new
                onClick={() => updateVendorStatus()}
                disabled={loader === "update"}
              >
                {loader === "update" ? (
                  <div className="d-flex align-items-center gap-2">
                    <Loader /> loading...
                  </div>
                ) : (
                  "Yes"
                )}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {showFilter && (
          <VendorFilter
            show={showFilter}
            onHide={() => setShowFilter(false)}
            setFilters={setFilters}
            filters={filters}
            setPage={setPage}
            showDuedateRange={showDuedateRange}
            setShowDuedateRange={setShowDuedateRange}
            showReqDateRange={showReqDateRange}
            setShowReqDateRange={setShowReqDateRange}
          />
        )}

        <div id="main" role="main">
          <div id="content">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a
                    onClick={() => navigate("/alliance-manager/dashboard")}
                    style={{
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active">Vendors</li>
              </ol>
            </div>
            <div className="card card-sm card-shadow mb-3">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h2>Vendors Lists </h2>
                  <div className="d-flex justify-content-end align-items-center gap-2 pb-3">
                    {(!!filters?.name ||
                      !!filters?.address ||
                      !!filters?.status ||
                      !!filters?.short_name ||
                      filters.dateSubmit ||
                      filters.requestDateSubmit ||
                      filters?.man_power_cnt_field ||
                      filters?.man_power_cnt_execuitve ||
                      !!q) && (
                      <button
                        type="button"
                        className="button_new pull-right mt-1 px-3"
                        onClick={() => {
                          setq("");
                          setDisplaySearchField(false);
                          setFilters(initial);
                          setShowDuedateRange(false);
                          setShowReqDateRange(false);
                          setPage(1)
                        }}
                      >
                        Clear Filters
                      </button>
                    )}
                    {displaySearchField ? (
                      <label className="input m-0 mt-1">
                        <input
                          type="text"
                          className="button_new "
                          value={q}
                          onChange={(e) => {
                            setPage(1);
                            setq(e.target.value);
                          }}
                          // disabled={workflow === "expert"}
                          placeholder="Search"
                          // title="Search"
                        />
                      </label>
                    ) : (
                      <div>
                        <a
                          className=" button_new "
                          onClick={() => {
                            setDisplaySearchField(true);
                          }}
                        >
                          <i
                            class="fa fa-fa fa-search requests-icons"
                            title="Search"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    )}
                    <div>
                      <a
                        // href=""
                        onClick={() => setShowFilter(true)}
                        target="_blank"
                        className="button_new"
                        // style={{ marginRight: "45px" }}
                      >
                        <i
                          className="fa fa-filter fa-fw requests-icons"
                          title="Show Filters"
                        ></i>
                      </a>
                    </div>

                    <div>
                      <a
                        onClick={() => downloadReport()}
                        target="_blank"
                        className="button_new"
                      >
                        <i
                          // style={{ marginRight: "90px" }}
                          className="fa fa-download requests-icons"
                          title="Download"
                        ></i>
                      </a>
                    </div>

                    <div>
                      <a
                        className=" button_new "
                        onClick={() =>
                          navigate("/alliance-manager/vendors/add")
                        }
                      >
                        <i
                          className="fa fa-plus fa-fw requests-icons"
                          title="Add Vendor"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="max-w-100 overflow-auto">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered table-hover dataTable no-footer"
                      style={{ tableLayout: "fixed" }}
                    >
                      <thead>
                        <tr style={{ backgroundColor: "#eee" }}>
                          <th width="34px" className="text-center td-sr">
                            S. No.
                          </th>
                          <th width="220px" className="text-center td-sr">
                            Name
                          </th>
                          <th width="200px" className="text-center td-sr">
                            E-mail
                          </th>
                          <th width="60px" className="text-center td-sr">
                            Short Name
                          </th>
                          {/* <th className="text-center td-sr">Status</th> */}
                          <th
                            colSpan="2"
                            rowSpan={2}
                            width="210px"
                            className="text-center td-sr p-2"
                          >
                            Manpower Count <br />
                            <div className=" pt-2 row td-sr">
                              <div
                                className="text-center col-6"
                                // style={{marginLeft:"15%"}}
                                style={{ whiteSpace: "pre-wrap" }}
                              >
                                Executives
                              </div>
                              <div
                                className="text-center col-6"
                                // style={{marginRight:"13%"}}
                                style={{ whiteSpace: "pre-wrap" }}
                              >
                                Field Executives{" "}
                              </div>
                            </div>
                          </th>

                          <th width="100px" className="text-center td-sr">
                            Created On
                          </th>
                          <th width="100px" className="text-center td-sr">
                            Closed On
                          </th>
                          <th width="50px" className="text-center td-sr">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {vendorList?.data?.data?.length ? (
                          vendorList?.data?.data.map((vendor, index) => {
                            return (
                              <tr key={vendor?.id}>
                                <td>
                                  <div className={cellClasses}>
                                    <div>{limit * (page - 1) + index + 1}</div>
                                  </div>
                                </td>
                                <td>
                                  <Link
                                    to={`/alliance-manager/vendors/details/${vendor?.id}`}
                                  >
                                    {vendor?.name}
                                  </Link>
                                </td>
                                <td>
                                  <div className={cellClasses}>

                                    {vendor?.email}
                                  </div>
                                </td>
                                <td>
                                  <div className={cellClasses}>
                                    {vendor?.short_name}
                                  </div>
                                </td>
                                <td>
                                  <div className={cellClasses}>
                                    {vendor?.executive}
                                  </div>
                                </td>
                                <td>
                                  <div className={cellClasses}>
                                    {vendor?.field_executive}
                                  </div>
                                </td>

                                <td>
                                  <div className={cellClasses}>
                                    {vendor?.created_at
                                      ? format(
                                          new Date(vendor?.created_at),
                                          "dd-MM-yy hh:mm a"
                                        )
                                      : ""}
                                  </div>
                                </td>

                                <td>
                                  <div className={cellClasses}>
                                    {vendor?.status == 0 ||
                                    (vendor?.status == 3 && vendor?.closed_at)
                                      ? format(
                                          new Date(vendor?.closed_at),
                                          "dd-MM-yy hh:mm a"
                                        )
                                      : ""}
                                  </div>
                                </td>
                                <td>
                                  <a
                                    title="Edit"
                                    onClick={() => {
                                      navigate(`edit/${vendor?.id}`);
                                    }}
                                  >
                                    <i className="fa fa-edit fa-fw mx-1"></i>
                                  </a>

                                {vendor?.status != "2" &&
                                  vendor?.status != "3" ? (
                                    <>
                                     {vendor?.is_enabled  == 0 ? (
                                    <i
                                      className="fa fa-circle fa-fw txt-fail"
                                      title="Activate"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setADItem(vendor)}
                                    ></i>
                                  ) : (
                                    ""
                                  )}

                                  {vendor?.is_enabled == 1 ? (
                                    <i
                                      className="fa fa-circle fa-fw txt-success pe-auto"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      title="Deactivate"
                                      onClick={() => setADItem(vendor)}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                    </>
                                  ):("")}
                                 
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={8} className="p-2 text-center">
                              {listLoading || isFetching ? (
                                <Loader />
                              ) : (
                                "No records found"
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-end mr-2">
                  {vendorList?.data?.total > limit && (
                    <Pagination
                      activePage={vendorList?.data?.current_page}
                      itemsCountPerPage={limit}
                      totalItemsCount={vendorList?.data?.total}
                      pageRangeDisplayed={5}
                      onChange={(number) => setPage(number)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminPageContainer>
    </AliianceManagerLayout>
  );
};

export default VendorManagement;

export const dummyVendorList = [
  {
    id: 2,
    name: "Executive",
    email: "executive@aparajitha.com",
    mobile_no: null,
    email_verified_at: "2023-04-03 00:00:00",
    user_role_id: 2,
    status: "1",
    otp_count: 0,
    permission: 0,
    short_name: null,
    created_by: null,
    address: null,
    expiry: null,
    whatsapp_status: "0",
    password: "$2y$10$QQoQmKItimLX5BlechkYCewdXkWxnhfIS.vnEQiC4CC9BYeyoiF6.",
    captcha: null,
    otp: null,
    remember_token: null,
    created_at: "2023-04-03 00:00:00",
    updated_at: "2023-04-03 00:00:00",
    deactivated_at: "",
    executives_count: "",
    f_excecutives_count: ""
  },
  {
    id: 118,
    name: "Animesh Raja",
    email: "animeshtest31@gmail.com",
    mobile_no: "9871234561",
    email_verified_at: null,
    user_role_id: 2,
    status: 2,
    otp_count: 0,
    permission: 0,
    short_name: null,
    created_by: 4,
    address: null,
    expiry: null,
    whatsapp_status: "0",
    password: "$2y$10$A0QZIg4JYu3hE7X7w8VhLO0jMXOSXxHjTuqonlhh7P7bc5CyZr4p.",
    captcha: null,
    otp: null,
    remember_token: null,
    created_at: "2023-04-17 13:25:29",
    updated_at: "2023-04-17 13:30:16",
  },
]

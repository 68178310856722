import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

const RequestLicenseCards = ({
  cnt,
  title,
  total,
  id,
  setToBeFiltered,
  toBeFiltered,
  handleApplyFilter,
  setApplyCardFilter,
  setIsSearchbarActive,
  clearFilters,
  setDataToShow,
  allLicenseRequests,
}) => {
  const [href, setHref] = useState("#");
  const [tileState, setTileState] = useState("Total Requests");
  /*  useEffect(()=>{
		if(cnt > 0 && total>0 && title)
    {
      setHref('vendor/completed-overall-license-request');
    } 
	}, []) */

  const applyFilter = () => {
    if (title === "Requests Completed") {
      setToBeFiltered({
        ...toBeFiltered,
        status: ["Completed"],
      });
      setApplyCardFilter(true);
      return;
    }
    if (title === "Total Requests") {
      setIsSearchbarActive(false);
      setDataToShow(allLicenseRequests.data);
      clearFilters();
      return;
    }
  };
  const getBorderColor = () => {
    if (
      title === "Requests Completed" &&
      toBeFiltered?.status?.includes("Completed")
    )
      return "border-success";
    if (title === "Total Requests" && toBeFiltered?.status?.length === 0)
      return "border-success";
    return "";
  };

  const getTextColor = () => {
    if (title === "Requests Completed") {
      return "txt-success";
    }
  };

  return (
    <div>
      <Card
        className={`card card-sm card-shadow mb-3 text-center ${getBorderColor()} `}
        style={{
          cursor: "pointer",
        }}
        onClick={applyFilter}
      >
        <Card.Body className="  pb-2">
          <h2
            className="status-card-count"
            style={{
              color: title === "Requests Completed" ? "green" : "",
              fontWeight: "bold",
            }}
          >
            {total}
          </h2>
          <span className="status-card-title">{title}</span>
        </Card.Body>
      </Card>
    </div>
  );
};

export default RequestLicenseCards;

/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { StepperNav } from "vertical-stepper-nav";
import EndUserLayout from "../../components/layouts/EndUserLayout";
import Payment from "../../components/Payment";
import Stepper from "../../components/Stepper";
import UploadDocs from "../../components/UploadDocs";
import { AuthContext } from "../../context/authContext";
import ViewTransactionDetail from "../../pages/EndUser/ViewTransactionDetail";
import "./buyservice.css";
import { useSearchParams } from "react-router-dom";

const stepsList = [
  {
    name: "Registration Completed",
    stepNo: 1,
  },
  {
    name: "Upload Documents",
    stepNo: 2,
  },
  {
    name: "Process Payment",
    stepNo: 3,
  },
  {
    name: "Transaction Details",
    stepNo: 4,
  },
  {
    name: "Executive Assigned",
    stepNo: 5,
  },
  {
    name: "Application Submitted To Authority",
    stepNo: 6,
  },
  {
    name: "Application Under Process With Authority",
    stepNo: 7,
  },
  {
    name: "Courier Dispatch Status",
    stepNo: 8,
  },
];
const BuyService = () => {
  const [CompltedSteps, setCompletedSteps] = useState(1);
  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(
    searchParams.get("currentStep")
      ? Number(searchParams.get("currentStep"))
      : 2
  );
  const auth = useContext(AuthContext);
  const [standardRate, setStandardRate] = useState("");
  const [fastrackRate, setFastrckRate] = useState("");
  const [standardTAT, setStandardTAT] = useState("");
  const [fastrackTAT, setFastrackTAT] = useState("");
  const [serviceId, setServiceId] = useState(
    searchParams.get("serviceId") ? Number(searchParams.get("serviceId")) : ""
  );
  const [paymentId, setPaymentId] = useState(
    searchParams.get("paymentId") ? Number(searchParams.get("paymentId")) : ""
  );
  const [srnData, setSrnData] = useState("");

  return (
    <EndUserLayout>
      <div
        className="d-flex bg-img-center"
        style={{
          backgroundImage: "url(/img/portal/screen-background.png)",
        }}
      >
        <div className="container content-space-t-1 content-space-t-lg-2">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="card card-lg card-shadow card-pinned d-none">
                <div className="card-body" style={{ padding: "20px" }}>
                  <div className="mb-3">
                    <p className="fs-3">
                      Welcome! <br />{" "}
                      <strong style={{ color: "#00a5e5" }}>{auth?.name}</strong>
                    </p>
                    <br />
                    {/* <StepperNav steps={steps} /> */}
                    <ProgressBar
                      now={(currentStep / stepsList?.length) * 100}
                      style={{ height: "15px", margin: "0 0 25px" }}
                    />
                    <Stepper stepsList={stepsList} currentStep={currentStep} />
                    {/* <img src="/img/portal/timeline4.png" width="100%" /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {currentStep === 2 ? (
                <UploadDocs
                  setCurrentStep={setCurrentStep}
                  setStandardRate={setStandardRate}
                  setFastrckRate={setFastrckRate}
                  setStandardTAT={setStandardTAT}
                  setFastrackTAT={setFastrackTAT}
                  setServiceId={setServiceId}
                  serviceId={serviceId}
                  setSrnData={setSrnData}
                />
              ) : (
                ""
              )}
              {currentStep === 3 ? (
                <Payment
                  serviceId={serviceId}
                  standardRate={standardRate}
                  fastrackRate={fastrackRate}
                  standardTAT={standardTAT}
                  fastrackTAT={fastrackTAT}
                  setCurrentStep={setCurrentStep}
                  setPaymentId={setPaymentId}
                  srnData={srnData}
                  // serviceId={"16"}
                  // standardRate={"2000"}
                  // fastrackRate={"3000"}
                  // standardTAT={"10"}
                  // fastrackTAT={"8"}
                />
              ) : (
                ""
              )}
              {currentStep === 4 ? (
                <ViewTransactionDetail
                  serviceId={serviceId}
                  setCurrentStep={setCurrentStep}
                  paymentId={paymentId}

                  // serviceId={"16"}
                  // standardRate={"2000"}
                  // fastrackRate={"3000"}
                  // standardTAT={"10"}
                  // fastrackTAT={"8"}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container content-space-t-1 content-space-t-lg-2"></div>
      </div>
    </EndUserLayout>
  );
};

export default BuyService;

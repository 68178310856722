import React, { useContext, useState, useEffect } from "react";
import { LicenseConfigureContext, spl_format } from "..";
import { useQuery } from "react-query";
import LicenseConfigureServices from "../../../../services/API/LicenseConfiguration";
import { Modal, Button, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import Pagination from "react-js-pagination";
import AddStatus from "../Modals/AddStatus";
export const validateStatus = (data) => {
  if (!data.name) return "Please enter Status";
  if (data.name.length < 1 || data.name.length > 500) {
    return "Please enter valid input with 1-500 characters";
  }
  if (!spl_format.test(data.name))
    return "Please enter only alphabets in this field";
};
export default function Statuses() {
  const value = useContext(LicenseConfigureContext);
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(null);
  const [id, setId] = useState(null);
  const [page, setPage] = useState(1);
  const [del, setDel] = useState(false);
  const [exported, setExported] = useState("");
  const [myData, setMyData] = useState([]);
  const [displaySearchField, setDisplaySearchField] = useState(false);
  const [searchkey, setSearchkey] = useState(null);
  const [filter, setFilter] = useState(false);

  const postsPerPage = 20;
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const queryKey = "api/license/license-status";
  const {
    data: statusList,
    isLoading: loading,
    isSuccess: success,
    isFetching,
    refetch,
  } = useQuery([queryKey], LicenseConfigureServices.getStatusList, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLists = Array.isArray(myData)
    ? myData?.slice(indexOfFirstPost, indexOfLastPost)
    : statusList?.data?.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    if (statusList?.data) {
      setMyData(statusList?.data);
    }
  }, [statusList]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  const handleExport = async () => {
    const resp = await LicenseConfigureServices.exportStatus();
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Statuses.csv");
      toast.success("Statuses Downloaded Successfully");
      link.click();
    }
  };

  const handleDelete = async (status) => {
    const status1 = status.status === 1 ? 0 : 1;
    try {
      const response = await LicenseConfigureServices.deactivateStatus(
        status?.id,
        {
          status: status1,
        }
      );
      if (response) {
        const message =
          status?.status === 1
            ? "Status Deactivated Successfully"
            : "Status Activated Successfully";
        toast.success(message);
        setDel(null);
        if(name){
          applyFilter(page)
      }else if(searchkey){
        handleSearch({target:{value:searchkey}},page)
      }else{
        refetch();
      }
      }
    } catch (er) {
      toast.error(er);
    }
  };
  const handleEdit = async (status) => {
    try {
      const statusData = await LicenseConfigureServices.editStatus(status?.id);
      value.setShow("status");
      setId(status?.id);
      setEdit(statusData?.data);
    } catch (er) {
      toast.error(er);
    }
  };

  const applyFilter = async (page) => {
    const payload = { name, license_status_id: id };
    // const error = validate(payload);
    // if (error) {
    //   toast.error(error);
    //   return;
    // }
    const error = Object.values(payload).filter((dt) => dt !== null);
    if (!error.length) {
      toast.error("Please fill out the fields");
      return;
    }
    try {
      const response = await LicenseConfigureServices.filterStatus(payload);
      if (response?.data) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setFilter(false);
        if(!page){
          setPage(1);
        }
        setMyData(response?.data);
        // setDisplaySearchField(true);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const clearFilter = () => {
    setDisplaySearchField(false);
    setSearchkey(null);
    setMyData(statusList?.data);
    setName(null);
    setPage(1);
  
  };
  const handleSearch = async (e,page) => {
    const value = e.target.value;
    setSearchkey(value);
    const payload = { name: value };
    const { data } = await LicenseConfigureServices.searchStatus(payload);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if(!page){
      setPage(1);
    }
    setMyData(data);
  };
  const handleHide = () => {
    setFilter(false);
  };

  return (
    <div style={{ borderTop: "0px", paddingTop: "30px" }}>
      <div
        style={{
          paddingTop: "30px",
        }}
        className="d-flex justify-content-end align-items-center gap-2 p-2"
      >
        {statusList?.data.length !== myData?.length && (
          <button
            type="button"
            className="button_new pull-right"
            onClick={clearFilter}
          >
            Clear Filters
          </button>
        )}{" "}
        {displaySearchField ? (
          <input
            type="text"
            className="button_new pull-right "
            value={searchkey}
            onChange={handleSearch}
            // disabled={workflow === "expert"}
            placeholder="Search"
          />
        ) : (
          <a
            className=" button_new pull-right"
            onClick={() => {
              setDisplaySearchField(true);
            }}
          >
            <i
              class="fa fa-search requests-icons"
              aria-hidden="true"
              title="Search"
            ></i>
          </a>
        )}
        <button
          className=" button_new pull-right"
          title="Show Filters"
          onClick={() => setFilter(true)}
        >
          <i className="fa fa-filter fa-fw requests-icons"></i>
        </button>
        <button
          className=" button_new pull-right"
          title="Download"
          onClick={handleExport}
        >
          <i className="fa fa-download fa-fw requests-icons"></i>
        </button>
        <a
          className=" button_new pull-right"
          onClick={() => value.setShow("status")}
          title="Add Status"
        >
          <i className="fa fa-plus fa-fw requests-icons"></i>
        </a>
      </div>
      <div className="table-responsive">
        <table
          className="table  table-bordered table-hover dataTable no-footer"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr role="row" style={{ fontWeight: 700 }}>
              <th className="text-center" width="34px">
                S. No.
              </th>
              <th className="text-center">Name</th>
              {/* <th className="text-center">Status</th> */}
              <th className="text-center" width="50px">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentLists?.length !== 0 ? (
              currentLists.map((status, index) => {
                return (
                  <tr key={status?.id}>
                    <td className="text-start align-middle">
                      {postsPerPage * (page - 1) + index + 1}
                    </td>
                    <td className="text-start align-middle">{status?.name}</td>
                    {/* {status.status === 1 ? (
                      <td className="text-start align-middle">
                        <i className="fa fa-circle fa-fw txt-success"></i>Active
                      </td>
                    ) : (
                      <td className="text-start align-middle">
                        <i className="fa fa-circle fa-fw txt-fail"></i>Inactive
                      </td>
                    )} */}
                    <td className="text-start align-middle">
                      <a title="Edit" onClick={() => handleEdit(status)}>
                        <i className="fa fa-edit fa-fw"></i>
                      </a>

                      {status.status === 1 ? (
                        <i
                          className="fa fa-circle fa-fw txt-success pe-auto"
                          style={{
                            cursor: "pointer",
                          }}
                          title="Deactivate"
                          onClick={() => setDel(status)}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-circle fa-fw txt-fail"
                          title="Activate"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => setDel(status)}
                        ></i>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={3} className="p-2 text-center">
                  {loading || isFetching ? "Loading..." : "No records found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {myData.length > postsPerPage && (
          <Pagination
            activePage={page}
            itemsCountPerPage={postsPerPage}
            totalItemsCount={myData.length}
            pageRangeDisplayed={postsPerPage}
            onChange={handlePageChange}
          />
        )}
      </div>
      <Modal
        show={!!del}
        onHide={() => setDel(null)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to {del?.status === 1 ? "deactivate " : "activate "}
            this status?
          </p>
          <div className="d-flex gap-3 justify-content-end">
            <Button
              variant="danger"
              className="px-3 py-2"
              onClick={() => setDel(null)}
            >
              No
            </Button>
            <Button
              variant="success"
              className="px-3 py-2"
              onClick={() => handleDelete(del)}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Offcanvas show={filter} placement="end" onHide={handleHide}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>Filter </h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modall_body">
          <div className="container-fluid filter-box pt-3  filterScroll">
            <div className="form-group">
              <label htmlFor="tags"> Status Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Status name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <section className="pull-right m-4" onClick={()=>applyFilter()}>
            <a className="btn button_new_success p-3">Apply</a>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
      <AddStatus
        refetch={refetch}
        editData={edit}
        setEdit={setEdit}
        clearFilter={clearFilter}
        applyFilter={applyFilter}
        filtersAvailable={name}
        searchkey={searchkey}
        handleSearch={handleSearch}
      />
    </div>
  );
}

import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";

import format from "date-fns/format";
import { addDays } from "date-fns";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const DateRangeComponent = ({
  openCalendar,
  setOpenCalendar,
  onChange,
  isDueDateComponent,
  toBeFiltered,
  setToBeFiltered,
  setShowDuedateRange,
  setShowReqDateRange,
}) => {
  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpenCalendar(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e) => {
   
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpenCalendar(false);
    }
  };

  const handleOnChange = (item) => {
    // setRange([item.selection])
    if (isDueDateComponent) {
      setToBeFiltered({
        ...toBeFiltered,
        ["dueDateRange"]: [item.selection],
        ["dateSubmit"]: true,
      });
      setShowDuedateRange(true);
    }
    if (!isDueDateComponent) {
      setToBeFiltered({
        ...toBeFiltered,
        ["requestedDateRange"]: [item.selection],
        ["requestDateSubmit"]: true,
      });
      setShowReqDateRange(true);
    }
    // { ...toBeFiltered, ["dueDateRange"]: date }
  };

  return (
    <div ref={refOne}>
      {openCalendar && (
        <DateRange
          onChange={handleOnChange}
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
          ranges={
            isDueDateComponent
              ? toBeFiltered.dueDateRange
              : toBeFiltered.requestedDateRange
          }
          months={1}
          direction="horizontal"
          className="calendarElement"
        />
      )}
    </div>
  );
};

export default DateRangeComponent;

import React from "react";
import axiosInstance, { getAccessToken } from "../axiosConfig";
import { toast } from "react-toastify";
import fileSaver from "file-saver";
import axios from "axios";

class LicenseConfigurationServices extends React.Component {
  getLicenseTypeList = async ({ queryKey }) => {
    const [_key] = queryKey;

    return await axiosInstance.get(`/api/license/license-type`);
  };

  getIndustryTypeList = async ({ queryKey }) => {
    const [_key] = queryKey;

    return await axiosInstance.get(`/api/license/license-industry`);
  };

  getCompanyTypeList = async ({ queryKey }) => {
    const [_key] = queryKey;

    return await axiosInstance.get(`/api/license/license-company`);
  };

  getCategoryList = async () => {
    return await axiosInstance.get(`/api/license/license-category`);
  };

  getActiveCategoryList = async () => {
    return await axiosInstance.get(`/api/license/license-category`, {
      params: { active: 1 },
    });
  };

  getLicenseList = async ({ queryKey }) => {
    const [_key] = queryKey;

    return await axiosInstance.get(`/api/license`);
  };

  getStatusList = async () => {
    return await axiosInstance.get(`/api/license/license-status`);
  };

  getActiveStatusList = async () => {
    return await axiosInstance.get(`/api/license/license-status`, {
      params: { active: 1 },
    });
  };

  getSubStatusList = async () => {
    return await axiosInstance.get(`/api/license/sub-status-with-status`);
  };

  addLicenseType = async (licenseType) => {
    return await axiosInstance.post(
      `/api/license/save-license-type`,
      licenseType
    );
  };

  deactivateLicenseType = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-status-license-type/${id}`,
      payload
    );
  };

  editLicenseType = async (id) => {
    return await axiosInstance.post(`/api/license/edit-license-type/${id}`);
  };

  updateLicenceType = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-license-type/${id}`,
      payload
    );
  };

  addIndustryType = async (industryType) => {
    return await axiosInstance.post(
      `/api/license/save-industry-type`,
      industryType
    );
  };

  editIndustryType = async (id) => {
    return await axiosInstance.post(`/api/license/edit-license-industry/${id}`);
  };

  updateIndustryType = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-license-industry/${id}`,
      payload
    );
  };
  deactivateIndustryType = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-status-industry-type/${id}`,
      payload
    );
  };

  addCompanyType = async (companyType) => {
    return await axiosInstance.post(
      `/api/license/save-license-company-type`,
      companyType
    );
  };

  editCompanyType = async (id) => {
    return await axiosInstance.post(`/api/license/edit-license-company/${id}`);
  };

  updateCompanyType = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-license-company/${id}`,
      payload
    );
  };
  deactivateCompanyType = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-status-company/${id}`,
      payload
    );
  };

  addCategory = async (category) => {
    return await axiosInstance.post(
      `/api/license/save-license-category`,
      category
    );
  };

  editCategory = async (id) => {
    return await axiosInstance.post(`/api/license/edit-license-category/${id}`);
  };

  updateCategory = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-license-category/${id}`,
      payload
    );
  };
  deactivateCategory = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-status-license-category/${id}`,
      payload
    );
  };

  addLicense = async (license) => {
    return await axiosInstance.post(`/api/license/save-license`, license);
  };

  editLicense = async (id) => {
    return await axiosInstance.post(`/api/license/edit-license/${id}`);
  };

  updateLicense = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-license/${id}`,
      payload
    );
  };
  deactivateLicense = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-status-license/${id}`,
      payload
    );
  };

  addStatus = async (status) => {
    return await axiosInstance.post(`/api/license/save-license-status`, status);
  };

  editStatus = async (id) => {
    return await axiosInstance.post(`/api/license/edit-license-status/${id}`);
  };

  updateStatus = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-license-status/${id}`,
      payload
    );
  };
  deactivateStatus = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-status-license-status/${id}`,
      payload
    );
  };
  addSubStatus = async (status) => {
    return await axiosInstance.post(
      `/api/license/save-license-sub-status`,
      status
    );
  };

  editSubStatus = async (id) => {
    return await axiosInstance.get(
      `/api/license/edit-license-sub-status/${id}`
    );
  };

  updateSubStatus = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-license-sub-status/${id}`,
      payload
    );
  };
  deactivateSubStatus = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license/update-status-license-sub-status/${id}`,
      payload
    );
  };

  searchGlobal = async (payload) => {
    return await axiosInstance.post(`/api/global-search`, payload);
  };

  exportLicense = async () => {
    return await axiosInstance.get(`/api/license-export`);
  };

  exportStatus = async () => {
    return await axiosInstance.get(`/api/license-status-export`);
  };

  exportSubStatus = async () => {
    return await axiosInstance.get(`/api/license-sub-status-export`);
  };

  licenceBulkUpload = async (document) => {
    const headers = {
      "Content-Type": "multipart/form-data",

      Authorization: getAccessToken(),
    };
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios.post(
        `${apiUrl}/api/license/import`,
        document,
        {
          headers,
          responseType: "arraybuffer",
        }
      );

      const contentType = response.headers["content-type"];
      if (contentType.includes("application/json")) {
        const arrayBufferConverted = JSON.parse(
          String.fromCharCode.apply(null, new Uint8Array(response.data))
        );
        toast.success(arrayBufferConverted?.message);
      } else if (
        contentType.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        toast.info(
          "File imported with errors. Please check downloaded file for failures"
        );
        const blob = new Blob([response.data], {
          type: contentType,
        });
        fileSaver.saveAs(blob, "failures.xlsx");
      } else {
        toast.error("Unexpected response format.");
      }
    } catch (error) {
      const responseError = JSON.parse(
        String.fromCharCode.apply(null, new Uint8Array(error?.response?.data))
      );
      if (responseError.status_code == 403) {
        toast.info(responseError?.message);
      } else {
        toast.error("An error occurred while uploading the report.");
      }
    }
  };

  filterLicenseType = async (payload) => {
    return await axiosInstance.get(`/api/license/license-type`, {
      params: { name: payload?.name, description: payload?.description },
    });
  };
  searchLicenseType = async (payload) => {
    return await axiosInstance.get(`/api/license/license-type/search`, {
      params: { keyword: payload?.name },
    });
  };

  searchIndustryType = async (payload) => {
    return await axiosInstance.get(`/api/license/license-industry/search`, {
      params: { keyword: payload?.name },
    });
  };
  searchLicense = async (payload) => {
    return await axiosInstance.get(`/api/license/search`, {
      params: { keyword: payload?.name },
    });
  };
  searchCompanyType = async (payload) => {
    return await axiosInstance.get(`/api/license/license-company/search`, {
      params: { keyword: payload?.name },
    });
  };
  searchCategory = async (payload) => {
    return await axiosInstance.get(`/api/license/license-category/search`, {
      params: { keyword: payload?.name },
    });
  };
  searchStatus = async (payload) => {
    return await axiosInstance.get(`/api/license/license-status/search`, {
      params: { keyword: payload?.name },
    });
  };

  filterLicense = async (payload) => {
    return await axiosInstance.get(`/api/license`, {
      params: {
        name: payload?.name,
        short_name: payload?.short_name,
        license_category_id: payload?.license_category_id,
        shortlist: payload?.shortlist,
      },
    });
  };

  filterIndustryype = async (payload) => {
    return await axiosInstance.get(`/api/license/license-industry`, {
      params: { name: payload?.name, short_name: payload?.short_name },
    });
  };

  filterCompanyType = async (payload) => {
    return await axiosInstance.get(`/api/license/license-company`, {
      params: { name: payload?.name, short_name: payload?.short_name },
    });
  };
  filterCategory = async (payload) => {
    return await axiosInstance.get(`/api/license/license-category`, {
      params: { name: payload?.name, short_name: payload?.short_name,shortlist:payload?.shortlist,menu_order:payload?.menu_order },
    });
  };

  filterSubStatus = async (payload) => {
    return await axiosInstance.get(`/api/license/sub-status-with-status`, {
      params: {
        name: payload?.name,
        license_status_id: payload?.license_status_id,
      },
    });
  };
  filterStatus = async (payload) => {
    return await axiosInstance.get(`/api/license/license-status`, {
      params: { name: payload?.name },
    });
  };

  createShortName = async (payload) => {
    return await axiosInstance.post(`/api/license/create-shortname`, payload);
  };

  LicenseDetailsList = async ({ queryKey }) => {
    // const [_key, { page, limit }] = queryKey;
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key]) {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`/api/license_details`, {
      params: newData,
    });
  };

  searchLicenseDetails = async ({ queryKey }) => {
    const [_key, data] = queryKey;
    let newData = {};
    const keys = Object.keys(data);
    keys.forEach((key, index) => {
      if (data[key] && data[key] !== 0 && data[key] !== "0") {
        newData[key] = data[key];
      }
    });
    return await axiosInstance.get(`/api/license_details`, {
      params: newData,
    });
  };

  downloadLicenseDetailsReport = async () => {
    // const [_key] = queryKey;
    axiosInstance.defaults.headers.put["responseType"] = "blob";
    return await axiosInstance.post(
      `/api/license_details/export_licenseDetails`
    );
  };

  createLicenseDetail = async (payload) => {
    return await axiosInstance.post(`/api/license_details/add`, payload);
  };
  updateLicenseDetail = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license_details/update/${id}`,
      payload
    );
  };

  exportLicenseType = async () => {
    return await axiosInstance.post(`/api/license/export-license-type`);
  };

  exportCompanyType = async () => {
    return await axiosInstance.post(`/api/license/export-license-company`);
  };

  exportLicenseCategoryType = async () => {
    return await axiosInstance.post(`/api/license/export-license-category`);
  };

  exportLicenseIndustryType = async () => {
    return await axiosInstance.post(`/api/license/export-license-industry`);
  };

  updateLicenseDetailStatus = async (id, payload) => {
    return await axiosInstance.post(
      `/api/license_details/update/status/${id}`,
      payload
    );
  };
}

const instance = new LicenseConfigurationServices();

export default instance;

import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import VendorManagerServices from "../../services/API/VendorManager";
import Loader from "../../components/Loader";
import AddVendor from "../AddVendor";
const EditVendor = () => {
  const { id } = useParams();

  const queryKey = `/api/vendor/edit/${id}`;
  const {
    data: venderDetails,
    isLoading,
    isFetching,
    isSuccess,
  } = useQuery([queryKey, { id }], VendorManagerServices.getVendorDetails, {
    refetchOnWindowFocus: false,
    refetchOnMount: "always",
  });

  return (
    <div>
      {isLoading || isFetching ? (
        <div id="main" className="main">
          <div id="content" className="content">
            <Loader />
          </div>
        </div>
      ) : venderDetails?.data[0] ? (
        <AddVendor vendorData={venderDetails?.data[0]} />
      ) : (
        ""
      )}
    </div>
  );
};

export default EditVendor;

import React from "react";
import axiosInstance from "../axiosConfig";

class DocumentRepositoryServices extends React.Component {
  getRepository = async (user_id) => {
    return await axiosInstance.get(
      `/api/user/documents/repository?user=${user_id}`
    );
  };

  uploadRepository = async (payload) => {
    return await axiosInstance.post(`/api/user/documents/re-upload`, payload, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };
  downloadRepository = async (id) => {
    return await axiosInstance.get(
      `/api/user/documents/repository/download/${id}`
    );
  };
}

const instance = new DocumentRepositoryServices();

export default instance;

import React from "react";
import axiosInstance, { getAccessToken } from "../axiosConfig";
import axios from "axios";
import { toast } from "react-toastify";
import FileSaver from "file-saver";

class DocumentMasterServices extends React.Component {
  getDocumentList = async ({ queryKey }) => {
    const [_key, { name, format, search }] = queryKey;
    const response = await axiosInstance.get(`/api/document-list`, {
      params: {
        name,
        accepted_format: format?.join(),
        search,
      },
    });
    return response;
  };

  addDocument = async (document) => {
    return await axiosInstance.post(`/api/create-document`, document, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  editDocument = async (id) => {
    return await axiosInstance.get(`/api/edit-document/${id}`);
  };

  updateDocument = async (id, document) => {
    return await axiosInstance.post(`/api/update-document/${id}`, document, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  deactivateDocument = async (id, document) => {
    return await axiosInstance.post(
      `/api/update-document-status/${id}`,
      document
    );
  };
  bulkUpload = async (document) => {
    // return await axiosInstance.post(`/api/upload-bulk-document`, document, {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //   },
    // });
    const headers = {
      "Content-Type": "multipart/form-data",

      Authorization: getAccessToken(),
    };
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await axios.post(
        `${apiUrl}/api/upload-bulk-document`,
        document,
        {
          headers,
          responseType: "arraybuffer",
        }
      );

      const contentType = response.headers["content-type"];
      if (contentType.includes("application/json")) {
        const arrayBufferConverted = JSON.parse(
          String.fromCharCode.apply(null, new Uint8Array(response.data))
        );
        toast.success(arrayBufferConverted?.message);
      } else if (
        contentType.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        toast.info(
          "File imported with errors. Please check downloaded file for failures"
        );
        const blob = new Blob([response.data], {
          type: contentType,
        });
        FileSaver.saveAs(blob, "failures.xlsx");
      } else {
        toast.error("Unexpected response format.");
      }
    } catch (error) {
      const responseError = JSON.parse(
        String.fromCharCode.apply(null, new Uint8Array(error?.response?.data))
      );
      if (responseError.status_code == 403) {
        toast.info(responseError?.message);
      } else {
        toast.error("An error occurred while uploading the report.");
      }
    }
  };

  exportDocument = async () => {
    return await axiosInstance.get(`/api/document-master-export`);
  };

  downloadDocument = async (doc_name) => {
    return await axiosInstance.get(`api/document-master-download/${doc_name}`);
  };
  filterDocument = async (payload) => {
    return await axiosInstance.get(
      `api/document-list?name=${payload?.name}&accepted_format=${payload?.accepted_format}`
    );
  };
}

const instance = new DocumentMasterServices();

export default instance;

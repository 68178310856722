import { useNavigate } from "react-router-dom";
import AdminLayout from "../layouts/adminLayout";
import AdminPageContainer from "../AdminPageContainer";
import { Button } from "react-bootstrap";

const PageNoteFound = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <AdminLayout>
      <AdminPageContainer>
        <div className="main" id="main">
          <div className="content" id="content">
            <section className="m-auto d-flex flex-column align-items-center justify-content-center my-5">
              <h1>404 Page Not Found</h1>
              <br />
              <div className="flexGrow">
                <Button onClick={goBack}>Go Back</Button>
              </div>
            </section>
          </div>
        </div>
      </AdminPageContainer>
    </AdminLayout>
  );
};

export default PageNoteFound;

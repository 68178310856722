import React from "react";
import { HeadProvider, Link } from "react-head";
import AdminHeader from "../../Headers/AdminHeader";
import FieldExecutiveSideMenu from "../../SideMenus/FieldExecutiveSideMenu";
import AdminLayout from "../adminLayout";

const FieldExecutiveLayout = ({ children, sideMenu = true }) => {
  return <AdminLayout sideMenu={sideMenu}>{children}</AdminLayout>;
};

export default FieldExecutiveLayout;

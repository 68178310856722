import React from "react";
import ExecutiveLayout from "../../../components/layouts/ExecutiveLayout";
import Enlarged from "../../../components/GraphModal/Enlarged";
export default function ElargedChartExec() {
  return (
    <ExecutiveLayout>
      <Enlarged navigation="/executive/out-of-office" />
    </ExecutiveLayout>
  );
}

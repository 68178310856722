import React from "react";
import VendorLayout from "../../../../components/layouts/VendorLayout";
import AdminPageContainer from "../../../../components/AdminPageContainer";
import FEDetails from "../../../../Containers/Vendor/FEDetails/FEDetails";

const FEDetailsPage = () => {
  return (
    <VendorLayout>
      <AdminPageContainer>
        <FEDetails />
      </AdminPageContainer>
    </VendorLayout>
  );
};

export default FEDetailsPage;

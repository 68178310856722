import React from "react";
import axiosInstance from "../axiosConfig";

class ServiceRegistrationService extends React.Component {
  userRegistration = async (payload) => {
    return await axiosInstance.post(`/api/user_end_registration`, payload);
  };

  userVerfiy = async (payload) => {
    return await axiosInstance.post(`/api/user_verify_otp`, payload);
  };

  resendOTP = async (payload) => {
    return await axiosInstance.post(`/api/user_resend_otp`, payload);
  };

  getMinimumPrice = async (licenseId) => {
    return await axiosInstance.post(`/api/get-price`, {
      license_id: licenseId,
    });
  };
}

const instance = new ServiceRegistrationService();

export default instance;
